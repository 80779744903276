
import {STORE_ALL_CHECKINS_V1, SET_OP_OWNED_CHECKIN_V1} from "../constants/CheckinsV1Constants";
import {initializeLayersObject} from "../../gl_map/glSetups/newCommon/mapStateConstructors";
import {processCheckinsV1ToDeck} from "../oldLogic/CheckInsV1List";
import {unixTimeNow} from "../oldLogic/TimeConverters";

const initialState = {rawData: [], deckData: initializeLayersObject(), selectedCheckin: null, operatorCheckin:null, storeTimestamp: 0};

const checkinsV1Reducer = (previousState = initialState, { type, payload }) => {

    switch (type) {
        case STORE_ALL_CHECKINS_V1:
            //console.log('----- checkinsReducer reducer STORE_ALL_CHECKINS_V1:', type, payload);

            if (payload.rawData) {
                payload.deckData = processCheckinsV1ToDeck(payload);

            }
            console.log('----- checkinsReducer reducer STORE_ALL_CHECKINS_V1:', type, payload);

            return {...previousState, ...payload, storeTimestamp:unixTimeNow()};

        default:
            return previousState;
    }
};

export default checkinsV1Reducer;
