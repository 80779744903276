import {ListItem} from "@material-ui/core-v3";
import Card from "@material-ui/core-v3/Card/Card";
import CardActionArea from "@material-ui/core-v3/CardActionArea/CardActionArea";

import {timeFormatter, timeFormatterShort} from "../../../dr_ra2/utils/momentHelpers";
import React from "react";
import withStyles from "@material-ui/core-v3/styles/withStyles";
import compose from 'recompose/compose';
import {RowListItemTable} from "./RowListItemTable";
import {shortenMissionType} from "../utils/missionTypeShortener";
import {has, get} from 'lodash'
import pure from 'recompose/pure';
import classnames from 'classnames'
import {functionalColors} from "../../../gl_map/glSetups/oldCommon/commonInits";
import {OperatorPrivileges} from "../../../dr_ra2/utils/operatorHelpers";
import {isAcmTouchedOnlyMission} from "./BarsController";
import {AppConfig} from "../../AppRtMap";

const styles = {
    root: {
        minWidth: "630px",
        border: "1px",
        borderStyle: "solid",
        borderRadius: "4px",
        marginBottom: "1px"
        // "&:not(:first-child)": {
        //     top: "-1px"
        // }
    },
    checkInBar: {
        width: "100%",

    },
    barPlanned: {
        backgroundColor: "rgb(220, 220, 220)"
    },
    gutters: {
        padding: "0 0",
    },
    selected: {
        outlineStyle: "solid",
        outlineOffset: "-5px",
        outlineWidth: "3px",
        outlineColor: `rgba(${functionalColors.selected})`
    }
};

// //TODO sort by zone type weight plus RPA color
// export function mission2displayWarnings(mission) {
//     let warningsNames = [];
//     let displayWarnings = null;
//
//     mission.warnings.forEach((warning) => {
//         const name = (warning.__slimV1) ? warning.name : warning.primitive.inspectData.name;
//         warningsNames.push(name);
//
//         displayWarnings = warningsNames[0]; //hmm
//
//         if (mission.warnings.length > 1) {
//             displayWarnings += `... (+${warningsNames.length - 1})`
//         }
//
//     });
//
//     return displayWarnings;
//
// }


const MissionRowRenderer = (props) => {

    const {classes, mission, selectMissionAction, selectedMissionUid, callsign} = props;

    const {
        id,
        uid,
        start,
        stop,
        operator,
        displayMaxHeight,
        mcmt,
        typ, //FIXME!
        //not sure is
        status,
        etc,
        govMsgs
    } = mission.missionRecord;

    const rmk = null; //wtf is remark?

    const isSelected = selectedMissionUid === uid;

    // TODO inconsequence mission acm recognized by email, checkins by username passed interchangeably
    // TODO push to some function in bars controller
    const isAcmTouched = govMsgs && govMsgs.some(msg=>msg.issuerEmail === 'acm@pansa.pl');
    const isAcmTouchedOnly = isAcmTouchedOnlyMission(mission);
    //let displayWarnings;
    let warnings;
    if (mission.warnings) {

        //console.log(el.warnings);
        //displayWarnings = mission2displayWarnings(mission);
        warnings = mission.warnings;

    }

    let isSuavo = false;
    let is112 = false;
    // turn on when this info is not provided inside callsign
    if(!AppConfig.callsignEngineStatic) {
        const missionOwnerPrivileges = new OperatorPrivileges(operator.priv);
        if(missionOwnerPrivileges.isSuavo()) isSuavo = true;
        if(get(etc, 'is112')) is112 = true;
    }


    //console.log('warningsNames', el.missionRecord);
    // console.log("bars mission row renderer", selectedMissionUid, uid);
    return (
        <ListItem key={id}
                  className={
                      classnames(
                          classes.root,
                          {
                              [classes.selected]: isSelected
                          }
                      )
                  }
                  classes={{gutters: classes.gutters}}>
            <Card className={[classes.checkInBar, classes.barPlanned].join(' ')}>
                <CardActionArea onClick={() => selectMissionAction(mission)}>
                    <RowListItemTable
                        {...{
                            approvalNo: mission.missionRecord.approval,
                            callsign,
                            missionType: shortenMissionType(typ),
                            //displayWarnings,
                            warnings,
                            displayMaxHeight,
                            rmk: `RMK: ${has(etc, 'shortDescription') ? etc.shortDescription : 'N/A'}`,
                            status: `${status}`.toUpperCase(),
                            mcmt: mcmt ? `MCMT: ${mcmt}min.` : 'N/A',
                            phone: operator.phone,
                            missionStart: start ? timeFormatterShort(start, {useUtc:true}) : "N/A",
                            missionStop: stop ? timeFormatterShort(stop, {useUtc:true}) : "N/A",
                            isAcmTouched: isAcmTouched,
                            isAcmTouchedOnly: isAcmTouchedOnly,
                            is112,
                            isSuavo
                        }}
                    />
                </CardActionArea>
            </Card>
        </ListItem>
    )
};


const mapStateToProps = state => {
    //console.log('ColoredCheckInsListView mapStateToProps', state);
    return {
        //myAuth : state.myAuth,
        //mySelf : state.mySelf,
        //myAppExtras : state.myAppExtras,
        missionsV2: state.missionsV2
    }
};





const enhance = compose(
    //translate,
    withStyles(styles),
    pure
    // connect(
    //     null, //mapStateToProps,
    //     //mapDispatchToProps
    // )
);

export default enhance(MissionRowRenderer);
