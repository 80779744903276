//rewriting old service from rt-map v1.0 (angular)
//just for show.
//to cleanup later

import {APP_MODES, urls} from '../../Configs'
import PubSub from 'pubsub-js'
import StoreProvider from "../../dr_ra2/MyStoreProvider";
import {fetchAllRtMapZonesRq} from "../actions/RtMapZonesV1Actions";
import {hexToRgb} from "./ColorConverters";
import {InspectTypes} from "../../modules2lib/common/";
import {turfCentroid} from "../../gl_map/glSetups/oldCommon/commonTurf";

export function processZonesV1ToDeck({rawData = []}) {

    let icos = [], points = [], paths = [], polys = [], texts = [];


    console.log('processZonesV1', rawData);


    //by default should use zonerules! like ->
    //let hex = ZoneRules[deserialized.zoneRecord.type].color_active;
    //let color = hexToRgb(hex);
    //console.log(color);
    //deserialized.primitive.poly.fillColor = [color.r, color.g, color.b, 96];


    //buu format!, should be moved to preparse
    for (let i = 0; i<rawData.length; i++) {
        let zone = rawData[i];
        //console.log(zone);
        let zoneData = {name:zone.name, color:(zone.color_active)?zone.color_active:zone.color};
        let color = hexToRgb(zoneData.color);
        //for (let j = 0; j<2; j++) {
        for (let j = 0; j<zone.data.length; j++) {
            //console.log('zonedatas', zone.name, j);

            //old aggregation format.. to fix both in rt-map

            let whyDataArray = zone.data[j];
            for (let k = 0; k<whyDataArray.features.length; k++) {
                let feature = whyDataArray.features[k];
                //console.log('feature', zone.name, feature.properties.name, feature);
                //console.log('feature', zone.name, feature.properties.name, feature);
                //console.log('feature', zone.name, feature.properties.name, feature.properties.custom_color);
                let zonePoly = {};
                zonePoly.elevation = 1000;
                //console.log(zoneData);


                if (feature.properties.custom_color) {
                    let custom_color = hexToRgb(feature.properties.custom_color);
                    zonePoly.fillColor = [custom_color.r, custom_color.g, custom_color.b, 96];
                    zonePoly.lineColor = [custom_color.r, custom_color.g, custom_color.b, 96];
                } else {

                    zonePoly.fillColor = [color.r, color.g, color.b, 96];
                    zonePoly.lineColor = [color.r, color.g, color.b, 96];
                }

                zonePoly.inspectData = {feature,
                    zoneData:"not used",
                    inspectType:InspectTypes.ZONEV1_FEATURE,
                    name: feature.properties.name
                };
                zonePoly.polygon = feature.geometry.coordinates;
                zonePoly.centroid = turfCentroid(zonePoly.polygon[0]);
                zonePoly.draft = true;
                //huh
                if (feature.geometry.type === 'Polygon') {
                    polys.push(zonePoly);
                }

                let text = {};
                text.centroid = zonePoly.centroid;
                text.displayName = feature.properties.name;

                texts.push(text);

            }
        }
    }
    return {icos, points, paths, polys, texts};
}


//deprecated! for maps only
export function getRtMapZonesV1() {
    const store = StoreProvider.getStore();
    const state = store.getState();
    console.log('getRtMapZonesV1', state);
    return state.rtMapZonesV1;
}


function _isLatLonWithinFeature(feature, latitude, longitude) {
    //console.log(feature, latitude, longitude)
    // if (feature.geometry
    //     && feature.geometry.coordinates
    //     && (feature.geometry.type === 'Polygon')  ) {
    //     //console.log('processign')
    //     let point = {"type":"Point","coordinates":[longitude, latitude ]};
    //
    //     if (feature.geometry.coordinates.length !== 1) {
    //         //console.log('ops, skipping')
    //         return false;
    //     }
    //     //let poly =  {type : 'Polygon', coordinates :  feature.geometry.coordinates[0]}; //hmm
    //     //let ret =  gju.pointInPolygon(point, {coordinates: feature.geometry.coordinates[0]});
    //     let ret =  gju.pointInPolygon(point, feature.geometry);
    //
    //     //console.log(ret);
    //     return ret;
    // }
    // else {
    //     if (!feature.geometry) {
    //         console.error('no geometry');
    //     } else if (!feature.geometry.coordinates) {
    //         console.error('no coordinates');
    //     }
    //     else if (feature.geometry.type !== 'Polygon'){
    //         //console.error('no Polygon');
    //     }
    //
    //     return false;
    // }

}


function _listAtLocation(latitude, longitude) {

    // let zones = global.zones;
    // let ret = [];
    //
    // for (let i = 0; i < global.zones.length; i++) {
    //     let features = global.zones[i].data[0].features;
    //     for (let j = 0; j < features.length; j++) {
    //         let feature = features[j];
    //         //console.log('feature',j ,  feature, global.zones[i].data[0]);
    //         //console.log(latitude, longitude);
    //         if (_isLatLonWithinFeature(feature, latitude, longitude)) {
    //             ret.push(feature);
    //         }
    //     }
    // }
    // //console.log('list', ret)
    // return ret;
}

//huh, old logic has multiple hacks for custom zones setup, keeping it for now..
//see zone2promise.
export function requestRtMapZones() {

    console.warn('OLD requestRtMapZones here!');

    const store = StoreProvider.getStore();
    store.dispatch(fetchAllRtMapZonesRq());

}



