import React from 'react';
import { Resource } from 'react-admin';
import jsonServerProvider from 'ra-data-json-server';

import { adminSaga } from 'react-admin';
import storage from 'redux-persist/lib/storage'; //fixme fixed! good storage

//import restClientRouter from 'aor-rest-client-router';

import jsonRestClient from 'aor-json-rest-client';

import RtMapAdmin from './RtMapAdmin'
//import {Admin} from 'react-admin'
import MyNewLoginPage from '../dr_ra2/components/auth/MyNewLoginPage';
import MyNewLogoutButton from '../dr_ra2/components/auth/MyNewLogoutButton';

import i18nProvider from "../dr_ra2/i18n/Myi18nProvider";
import authProvider from '../dr_ra2/MyAuthProvider';
import customRoutes from './logic/CustomRoutes';
import customReducers from './logic/CustomReducers';

import {
    AlertsV2Sagas,
    CheckinsV2Sagas,

    MissionsV2RtMapSagas, //fixme! after unification
    MissionsV2Sagas,
    AppStateSagas,

    TickSagas,
    ZonesV2Sagas,
    ZoneTypesV2Sagas,
    MapUxConfigSagas,
    LiveAdsbExchangeSagas,
    RtdmExchangeTicksSagas,
    RtdmCreotechTicksSagas,
    RtdmAerobitsTicksSagas,
    RtdmExchangeAircraftsSagas,
    RtdmSoapLprUnitsTicksSagas,

} from '../LogicV1Redux/sagas'


import menu from '../dr_ra2/components/MyMenu';
import MyDataProvider from "../dr_ra2/MyDataProvider";
import MyRestClient from "../dr_ra2/MyRestClient";
import data from "../dr_ra2/data";

import MyExtraAuthSaga from '../LogicV2/auth/MyExtraAuthSaga';

//import {govUrls, superAdminTweaks, canUpdate, canRead, canCreate, canDelete} from './MyRestConfig';

//import data from './data.json';
import Configs from "../Configs";
import MainView from './components/Main'
import {govUrls, superAdminTweaks} from "../dr_ra2/logic/MyRestGovUrls";
import {setAppExtrasInitialState} from "../LogicV1Redux/reducers/MyAppExtrasReducer";
import Admin from "../dr_ra2/components/MyAdmin";
import SimpleLoginPage from './components/SimpleLoginPage';

//todo correct droneadmin flow using loopback endpoints

const noAccessPage = '/login'; //?? from code

const myUrls = {
    apiBase: Configs.urls.apiBaseV2,
    noAccessPage,
    superAdminTweaks,
    defaultREST:govUrls,
};
//dang. have to be const, not object created in lamba on each state update.. at least now (static config)
//fixme -> move to initialState (supported now probably)
export const AppConfig = {
    appMode:Configs.Apps.RT_MAP.type, //fixme. passing all app anyway
    app: Configs.Apps.RT_MAP,
    builtOn:Configs.builtOn,
    devBuild:Configs.devBuild,
    callsignEngineStatic: Configs.Apps.RT_MAP.callsignEngineStatic
};

export const PersistConfig =  {
    //key: `root2-${Configs.Apps.RT_MAP.name}`,
    key: `root3-rtMap`,
    storage: storage, //default persist storage
    //stateReconciler: autoMergeLevel2 // see "Merge Process" section for details.
    //whitelist: ['locale', 'myAuth']
    whitelist: ['mapUxConfig'] //we don;t want to store anything else yet without logout functionality (see handleRefreshClick notes)
};

//console.log(myUrls);

const dataProvider = MyDataProvider.init({
    rules: [
        ['!AUTH!','loopBackSrv'],
        //['missionsV2RtMap','loopBackSrv'],  switching to axios in saga.. finally
        ['zonesAll', 'loopBackSrv'],
        ['*','jsonSrv']
    ],
    services: {
        //jsonServerProvider: jsonServerProvider('http://jsonplaceholder.typicode.com'),
        //loopBackSrv: MyRestClient(myUrls),
        jsonSrv: jsonRestClient(data),
        loopBackSrv: MyRestClient(myUrls),

    }
});

const testPermissions = (permissions) => {
    console.log('testPermissions',permissions);
    return null;
};

const customSagas = [
    AlertsV2Sagas,
    CheckinsV2Sagas,
    ZonesV2Sagas,
    ZoneTypesV2Sagas,
    AppStateSagas,

    MissionsV2RtMapSagas,
    adminSaga(dataProvider, authProvider, i18nProvider),
    MyExtraAuthSaga(dataProvider, authProvider),
    TickSagas,
    MapUxConfigSagas,
    LiveAdsbExchangeSagas,
    RtdmCreotechTicksSagas,
    RtdmExchangeTicksSagas,
    RtdmExchangeAircraftsSagas,
    RtdmSoapLprUnitsTicksSagas,
    RtdmAerobitsTicksSagas
];

//permissions -> hardcoded support for operator, hmmm...

const fakeAuthProvider = (type, params) => {
    // type can be any of AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, and AUTH_CHECK
    // ...
    return Promise.resolve();
};

//current (13-11-2018) setup requires SAME user/pass
// in old atc_users db for rt-map (aerodromes?)
// and gov in loopback..
//console.warn('FIXME REALLY MESSED UP NOW -> 2 DBs, SINGLE AUTH CHECK (LB)');

//
const initialState = {
    mapUxConfig:{
        heightFilter: {
            bottomCutoff: 0,
            topCutoff: 3000,
        },
        //looks like should be indepedent for pandora
        //pandoraTrafficEnabled:true,
        trafficHeightFilter: {
            bottomCutoff: 0,
            topCutoff: 9500,
        },
        visibilityPrefs:{

        },
        uiVolume: 1.0,
        uiSize: 'small',
    }
};

export const App = () => (

    <RtMapAdmin
        dataProvider={dataProvider}
        authProvider={authProvider}
        dashboard={MainView}
        loginPage={SimpleLoginPage}
        //logoutButton={MyNewLogoutButton}
        //customRoutes={customRoutes}
        customSagas={customSagas}
        customReducers={customReducers}
        i18nProvider={i18nProvider}
        //locale="pl"
        title = 'DroneAdmin2 RtMap Alpha'
        menu = {menu}
        configs = {AppConfig}
        persistConfig = {PersistConfig}
        initialState={initialState}
    >

        {permissions => [
            testPermissions(permissions),

        ]}


    </RtMapAdmin>

);

