import SplitPane from "react-split-pane";
import Typography from "@material-ui/core-v3/Typography/Typography";
import {InFlightListView} from "./InFlightListView";
import {OnBlockListView} from "./OnBlockListView";
import React from "react";
import withStyles from "@material-ui/core-v3/styles/withStyles";
import connect from "react-redux/es/connect/connect";
import compose from 'recompose/compose';
import {OnBlockBarsContext, InFlightBarsContext} from "./BarsController";


const styles = theme => ({
    root: {
        display: "flex",
        flexDirection: "column",
        height: "100%",
    },
    button: {
        width: "100%",
    },
    barArea: {
        margin: theme.spacing.unit,
        width: "100%"
    },
    wrapper: {
        flex: "1",
        display: "flex",
        height: "100%",
        width: "100%",
        border: "1px solid red",
    },
    list: {
        overflowY: "auto"
    },
    resizer: {
        background: '#000',
        opacity: '.2',
        zIndex: '1',
        M: 'padding',
        W: 'padding',
        boxSizing: 'border-box',
        fallbacks: [
            {
                W: 'border-box'
            },
            {
                M: 'border-box'
            }
        ],
        backgroundClip: 'padding-box',
        '&:hover': {
            W: 'all 2s ease',
            transition: 'all 2s ease'
        },
        '&.horizontal': {
            height: 14,
            margin: '-5px 0',
            borderTop: '5px solid rgba(255, 255, 255, 0)',
            borderBottom: '5px solid rgba(255, 255, 255, 0)',
            cursor: 'row-resize',
            width: '100%'
        },
        '&.horizontal:hover': {
            borderTop: '5px solid rgba(0, 0, 0, 0.5)',
            borderBottom: '5px solid rgba(0, 0, 0, 0.5)'
        },
        '&.vertical': {
            width: 11,
            margin: '0 -5px',
            borderLeft: '5px solid rgba(255, 255, 255, 0)',
            borderRight: '5px solid rgba(255, 255, 255, 0)',
            cursor: 'col-resize'
        },
        '&.vertical:hover': {
            borderLeft: '5px solid rgba(0, 0, 0, 0.5)',
            borderRight: '5px solid rgba(0, 0, 0, 0.5)'
        },
        '&.disabled': {
            cursor: 'not-allowed'
        },
        '&.disabled:hover': {
            borderColor: 'transparent'
        }
    }
});

// SplitPane doesn't support everywhere dynamic classes...
const inlineStyles = {
    splitPane: {
        position: "unset",
        //height: "unset"
    },
    pane: {
        overflowY: "auto",
        overflowX: "hidden",
        display: "flex"
    }
};

const BarsPanel = ({classes, bars}) => (
    <div className={classes.wrapper}>
        <SplitPane split="horizontal"
                   style={inlineStyles.splitPane}
                   defaultSize="50%" minSize={0}
                   maxSize={-4}
                   resizerClassName={classes.resizer}
                   paneStyle={inlineStyles.pane}
        >
            <div className={classes.barArea}>
                <Typography variant="button">
                    IN FLIGHT
                </Typography>
                <InFlightBarsContext.Consumer>
                    {
                        plannedBars =>
                            <InFlightListView
                                items={plannedBars}
                            />
                    }
                </InFlightBarsContext.Consumer>

            </div>
            <div className={classes.barArea}>
                <Typography variant="button">
                    ON BLOCK
                </Typography>
                <OnBlockBarsContext.Consumer>
                    {
                        onblockBars=>
                            <OnBlockListView
                                items={onblockBars}
                            />
                    }
                </OnBlockBarsContext.Consumer>
            </div>
        </SplitPane>
    </div>
);

const mapStateToProps = state => {

    return {
        isLoading: state.admin.loading > 0,
        myAuth: state.myAuth,
    }
};



export default compose(
    withStyles(styles),
    connect(
        mapStateToProps,
        null
    )
)(BarsPanel);