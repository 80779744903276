import {Component} from "react";

import Button from "@material-ui/core-v3/Button/Button";
import withStyles from "@material-ui/core-v3/styles/withStyles";
import compose from 'recompose/compose';
import {connect} from "react-redux";

import PubSub from "pubsub-js";
import React from "react";
import MyModal from "./MyModal";
import * as PropTypes from "prop-types";

import {PubSubSignals} from '../../../LogicV1Redux/constants/PubSubSignals'
import {timeFormatter, utcOffset} from "../../../dr_ra2/utils/momentHelpers";
import Typography from "@material-ui/core-v3/Typography/Typography";
import {AlertTypes as AlertTypesV1, cancelAlert as cancelAlertV1} from "../../../LogicV1Redux/oldLogic/AlertsV1Sending";
import {cancelAlertV2Rq} from '../../../LogicV1Redux/actions/AlertsV2Actions'
import {ALERT_TYPES as AlertTypesV2} from '../../../LogicV1Redux/constants/AlertsV2Enums'
import {get} from "lodash";

export const styles = {
    root: {
        marginBottom: "2em"
    },
    emergencyButtonStart: {
        backgroundColor: "rgb(244, 67, 54)",
        color: "rgb(255, 255, 255)",
        width: "100%",
        marginBottom: "2em"
    },
    emergencyButtonStop: {
        backgroundColor: "rgb(128,0,128)",
        color: "rgb(255, 255, 255)",
        width: "100%",
        marginBottom: "2em"
    },
    layoutRow: {
        display: "flex",
        flexDirection: "row",
    },
    hr: {
        alignSelf: "center",
        flex: "1"
    }
};

export const whitelistedUserTypes = ['CTR'];


//OLD SINGLE AREA!
class EmergencyPanel extends Component {

    // state = {
    //     ownedAlert: null,
    // };
    //
    // componentWillMount() {
    //
    //     this.setState({ownedAlert:getOwnedAreaAlert()});
    //
    //     this.pubsubAlerts = PubSub.subscribe(PubSubSignals.ALERTS_DATA, (msg, data) => {
    //         console.log('pubsub alertsData, EmergencyPanel', msg, data);
    //         console.warn('pubsub to remove', msg, data);
    //
    //         this.setState({ownedAlert:getOwnedAreaAlert()});
    //     });
    // }
    //
    // componentWillUnmount() {
    //     PubSub.unsubscribe(this.pubsubAlerts);
    // }


    render() {
        if(this.props.visible === false) return {};

        const {ownedAlert, classes, btnOnly, mySelf} = this.props;

        console.log('emergency panel', this.state);
        console.log('emergency panel', this.props);

        //move logic to better place later, but later
        //const ownedAreaName = getOwnedAreaName(); //always (after init) available
        const ownedAreaName = get(mySelf, 'pandora.area', false);

        const isOwnedAlertActive = !!(ownedAlert);
        console.log('-------ownedAlert', ownedAlert, isOwnedAlertActive);

        const userType = get(mySelf, 'pandora.type', false);

        //whitelist!
        if (whitelistedUserTypes.indexOf(userType) === -1 ) {
            return null;
        }

        const emergencyButtonClass = (isOwnedAlertActive)
            ? classes.emergencyButtonStop
            : classes.emergencyButtonStart;
        const msg = (isOwnedAlertActive)
            ? `CANCEL ${ownedAreaName} EMERGENCY BAN`
            : `${ownedAreaName} EMERGENCY`;
        const activeAlertData = (isOwnedAlertActive)
            ? <div>issued by {ownedAlert.etc.startedBy} on {timeFormatter(ownedAlert.__start * 1000, {useUtc:true})}</div>
            : null;

        const modalDescription = `Checked-in users in area ${ownedAreaName} will be notified.`;

        return (
            <div className={classes.root}>
                { (!btnOnly)
                    ?
                    <span className={classes.layoutRow}>
                    <hr className={classes.hr}/>
                        or issue for ALL
                        <hr className={classes.hr}/>
                    </span>
                    :null
                }
                <Button className={emergencyButtonClass}
                        onClick={() => {
                            if (isOwnedAlertActive) {
                                PubSub.publish(PubSubSignals.CANCEL_OWNED_ALERT_MODAL, {zoneName: ownedAreaName})
                            } else {
                                PubSub.publish(PubSubSignals.CREATE_OWNED_ALERT_DIALOG, {zoneName: ownedAreaName})
                            }
                        }}
                >
                    {msg}
                </Button>
                {(!btnOnly)
                    ?
                    activeAlertData
                    :null
                }
                {/*hmm, modals should be launched with redux actions probably ( like notifications) . fixme later?*/}
                {(!btnOnly) //otherwise duplicate mymodal
                    ?
                    <MyModal
                        title="Cancel area emergency ban now?"
                        description={modalDescription}
                        pubSubMessageListener={PubSubSignals.CANCEL_OWNED_ALERT_MODAL}
                        onAccept={() => {
                            if (process.env.REACT_APP_USE_ALERTS_V1) {
                                //cancelAlertV1({alertData:{type:AlertTypesV1.OWNED_ALERT, ...ownedAlert}})
                                cancelAlertV1({alertData: {type: AlertTypesV1.OWNED_ALERT, ...ownedAlert}})
                            }
                            this.props.cancelAlertV2Rq({alertData: {type: AlertTypesV1.OWNED_ALERT, ...ownedAlert}});
                        }}
                    />
                    :null
                }

            </div>
        )
    };
}

EmergencyPanel.propTypes = {
    visible: PropTypes.bool
};

EmergencyPanel.defaultProps = {
    visible: true
};

const mapStateToProps = state => {
    //console.log('EmergencyPanel mapStateToProps', state);
    return {
        //myAuth : state.myAuth,
        mySelf : state.mySelf,
        //myAppExtras : state.myAppExtras,
        ownedAlert: state.alertsV2.atcOwnedAlert
    }
};

const enhance = compose(
    //translate,
    withStyles(styles),
    connect(mapStateToProps, {
        cancelAlertV2Rq,
    })
);

export default enhance(EmergencyPanel);
