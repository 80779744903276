import React, {createElement} from 'react';
import PropTypes from 'prop-types';
import {createStore, compose, applyMiddleware} from 'redux';
import {Provider} from 'react-redux';
import createHistory from 'history/createHashHistory';
import {Switch, Route} from 'react-router-dom';
import {ConnectedRouter, routerMiddleware} from 'react-router-redux';
import createSagaMiddleware from 'redux-saga';
import {all, fork} from 'redux-saga/effects';
import withContext from 'recompose/withContext';
import { withStyles } from '@material-ui/core/styles';
//import { USER_LOGOUT } from './actions/authActions';
import {USER_LOGOUT} from 'react-admin';

//import createAppReducer from './reducer';
import {createAppReducer} from 'react-admin';

//import { adminSaga } from './sideEffect';
//import { adminSaga } from 'react-admin';

//import { TranslationProvider, defaultI18nProvider } from './i18n';
import {TranslationProvider, defaultI18nProvider} from 'react-admin';

//import CoreAdminRouter from './CoreAdminRouter';
import {AdminRouter as CoreAdminRouter} from 'react-admin';

import {createLogger} from 'redux-logger';
import {persistStore, persistReducer} from 'redux-persist';
import {PersistGate} from 'redux-persist/lib/integration/react';

import storeProvider from '../dr_ra2/MyStoreProvider'
import ErrorBoundary from "../dr_ra2/components/ErrorBoundary";

import {TIMER_TICK_RQ} from "../LogicV1Redux/constants/TickConstants";

//extra Admin defaults
import {
    Layout as DefaultLayout,
    Loading,
    Login,
    Logout,
    Menu,
    NotFound,
} from 'ra-ui-materialui';
import {setAppExtrasInitialState} from "../LogicV1Redux/reducers/MyAppExtrasReducer";
import SimpleLoginPage from './components/SimpleLoginPage'
import {useSimpleReduxLogger, reduxLoggerPredicate} from '../DebugConfig'
import {PersistConfig} from "./AppRtMap";
//based on dr_ra2 MyAdmin,
//modified especially for rt-map 1.1 presentation (late 10.2018)
//originally based on original RA2 CoreAdmin.js


// const MyLoginPage = //() => <Login styles={loginStyle} />;
// withStyles({
//     main: { background: 'white' },
// })(Login);



const CoreAdmin = ({
                       appLayout,
                       authProvider,
                       children,
                       customReducers = {},
                       customSagas = [],
                       customRoutes = [],
                       dashboard,
                       history,
                       menu,
                       catchAll,
                       dataProvider,
                       i18nProvider = defaultI18nProvider,
                       theme,
                       title = 'React Admin',
                       loading,
                       loginPage,
                       //loginPage = MyLoginPage,
                       logoutButton,
                       initialState,
                       configs,
                       persistConfig, //not storable

                       locale = 'en',

                   }) => {

    setAppExtrasInitialState(configs);

    console.log('initialState in myadmin', initialState);


    const messages = i18nProvider(locale);
    const appReducer = createAppReducer(customReducers, locale, messages);

    //console.log(customReducers.myAppExtras());

    console.log('configs', configs);

    const saga = function* rootSaga() {
        yield all(
            [
                //12.06.18, removing default adminSaga
                //adminSaga(dataProvider, authProvider, i18nProvider),
                ...customSagas,
            ].map(fork)
        );
    };
    const sagaMiddleware = createSagaMiddleware();
    const routerHistory = history || createHistory();


    //fixme!!
    //const resettableAppReducer = (state, action) =>
    //     appReducer(action.type !== USER_LOGOUT ? state : undefined, action);
    //const persistedReducer = persistReducer(persistConfig, resettableAppReducer);

    const persistedReducer = persistReducer(persistConfig, appReducer);

    let middlewareChain = [
        sagaMiddleware,
        routerMiddleware(routerHistory),
    ];

    if (useSimpleReduxLogger) {
        const logger = createLogger({
            // ...options
            predicate:reduxLoggerPredicate,
            collapsed: true,
        });

        middlewareChain.push(logger);
    }

    const store = createStore(
        persistedReducer,
        initialState,
        compose(
            applyMiddleware(...middlewareChain),
            typeof window !== 'undefined' && window.devToolsExtension //add dev/prod switch ?
                ? window.devToolsExtension()
                : f => f
        )
    );

    const persistor = persistStore(store);

    //12.06.18, add helper for accessing reduxStore
    storeProvider.init(store);

    sagaMiddleware.run(saga);

    const logout = authProvider ? createElement(logoutButton) : null;

    //const loginPage = MyLoginPage;
    //const loginPage = SimpleLoginPage;

    return (
        <Provider store={store}>
            <PersistGate persistor={persistor}>
                <TranslationProvider>
                    <ConnectedRouter history={routerHistory}>
                        <ErrorBoundary>
                            <Switch>
                                <Route
                                    exact
                                    path="/login"
                                    render={props =>
                                        createElement(loginPage, {
                                            ...props,
                                            title,
                                        })}
                                />
                                {/*huh, so many routes unused for now...*/}
                                {/*<Route*/}
                                    {/*exact*/}
                                    {/*path="/reset-pass-rq"*/}
                                    {/*render={props =>*/}
                                        {/*createElement(loginPage, {*/}
                                            {/*...props,*/}
                                            {/*title,*/}
                                        {/*})}*/}
                                {/*/>*/}
                                {/*<Route*/}
                                    {/*exact*/}
                                    {/*path="/reset-pass-intermediate"*/}
                                    {/*render={props =>*/}
                                        {/*createElement(loginPage, {*/}
                                            {/*...props,*/}
                                            {/*title,*/}
                                        {/*})}*/}
                                {/*/>*/}
                                {/*<Route*/}
                                    {/*exact*/}
                                    {/*path="/reset-pass"*/}
                                    {/*render={props =>*/}
                                        {/*createElement(loginPage, {*/}
                                            {/*...props,*/}
                                            {/*title,*/}
                                        {/*})}*/}
                                {/*/>*/}
                                {/*<Route*/}
                                    {/*exact*/}
                                    {/*path="/reset-pass-final"*/}
                                    {/*render={props =>*/}
                                        {/*createElement(loginPage, {*/}
                                            {/*...props,*/}
                                            {/*title,*/}
                                        {/*})}*/}
                                {/*/>*/}

                                {/*<Route*/}
                                    {/*exact*/}
                                    {/*path="/confirm-register"*/}
                                    {/*render={props =>*/}
                                        {/*createElement(loginPage, {*/}
                                            {/*...props,*/}
                                            {/*title,*/}
                                        {/*})}*/}
                                {/*/>*/}
                                {/*<Route*/}
                                    {/*exact*/}
                                    {/*path="/verified"*/}
                                    {/*render={props =>*/}
                                        {/*createElement(loginPage, {*/}
                                            {/*...props,*/}
                                            {/*title,*/}
                                        {/*})}*/}
                                {/*/>*/}
                                {/*<Route*/}
                                    {/*exact*/}
                                    {/*path="/register"*/}
                                    {/*render={props =>*/}
                                        {/*createElement(loginPage, {*/}
                                            {/*...props,*/}
                                            {/*title,*/}
                                        {/*})}*/}
                                {/*/>*/}
                                {/*<Route*/}
                                    {/*exact*/}
                                    {/*path="/original-admin"*/}
                                    {/*render={props => (*/}
                                        {/*<CoreAdminRouter*/}
                                            {/*appLayout={appLayout}*/}
                                            {/*catchAll={catchAll}*/}
                                            {/*customRoutes={customRoutes}*/}
                                            {/*dashboard={dashboard}*/}
                                            {/*loading={loading}*/}
                                            {/*loginPage={loginPage}*/}
                                            {/*logout={logout}*/}
                                            {/*menu={menu}*/}
                                            {/*theme={theme}*/}
                                            {/*title={title}*/}

                                            {/*{...props}*/}
                                        {/*>*/}
                                            {/*{children}*/}
                                        {/*</CoreAdminRouter>*/}
                                    {/*)}*/}
                                {/*/>*/}
                                <Route
                                    exact
                                    path="/"
                                    render={props =>
                                        createElement(dashboard, {
                                            ...props,
                                            title,
                                        })}
                                />
                            </Switch>
                        </ErrorBoundary>
                    </ConnectedRouter>
                </TranslationProvider>
            </PersistGate>
        </Provider>
    );
};

const componentPropType = PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.string,
]);

CoreAdmin.propTypes = {
    appLayout: componentPropType,
    authProvider: PropTypes.func,
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
    catchAll: componentPropType,
    customSagas: PropTypes.array,
    customReducers: PropTypes.object,
    customRoutes: PropTypes.array,
    dashboard: componentPropType,
    dataProvider: PropTypes.func.isRequired,
    history: PropTypes.object,
    i18nProvider: PropTypes.func,
    initialState: PropTypes.object,
    loading: componentPropType,
    locale: PropTypes.string,
    loginPage: componentPropType,
    logoutButton: componentPropType,
    menu: componentPropType,
    theme: PropTypes.object,
    title: PropTypes.node,
};

const CoreAdminWContext = withContext(
    {
        authProvider: PropTypes.func,
    },
    ({authProvider}) => ({authProvider})
)(CoreAdmin);


const Admin = CoreAdminWContext;

Admin.defaultProps = {
    appLayout: DefaultLayout,
    catchAll: NotFound,
    loading: Loading,
    loginPage: Login,
    logoutButton: Logout,
    menu: Menu,
};

export default Admin;
