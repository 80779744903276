import React from "react"
import connect from "react-redux/es/connect/connect";
import compose from 'recompose/compose';
import ShowWindow from "./ShowWindow";
import {togglePlannedWindow} from "../../logic/window/WindowActions";
import {bindActionCreators} from "redux";
import PlannedListView from "./PlannedListView";
import PortalWindow from "./PortalWindow"
import {PandoraWindowContext} from "../PandoraWindow";


const PlannedWindow = ({open, portalOpen, togglePlannedWindow}) => (
    <>
        <PandoraWindowContext.Consumer>
            {windowContainer =>
                <ShowWindow title={"PLANNED MISSIONS"}
                            content={
                                <>
                                    <PlannedListView/>
                                </>
                            }
                            open={open}
                            onClose={() => togglePlannedWindow({show: false})}
                            rndProps={{
                                onDragStop: (e, d) => {
                                    if (d.x > window.outerWidth) {
                                        // timeout needed for position state update in rnd before unmount
                                        setTimeout(
                                            ()=>togglePlannedWindow({show: false, portal: true}),
                                            10
                                        );
                                    }
                                }
                            }}
                            container={windowContainer.current}
                />
            }
        </PandoraWindowContext.Consumer>

        {portalOpen &&
            <PortalWindow
                windowProps={[
                    `height=${window.innerHeight / 2}`,
                    `width=${window.innerWidth / 2}`,
                    'left=10',
                    'top=10',
                    'resizable=yes',
                    'scrollbars=yes',
                    'toolbar=no',
                    'menubar=no',
                    'location=no',
                    'directories=no',
                    'status=no',
                    'location=no'
                ]}
                onClose={() => togglePlannedWindow({show: false, portal: false})}
                title="PansaUTM Planned Missions"
            >
                <div style={{margin: "1rem"}}>
                    <PlannedListView/>
                </div>
            </PortalWindow>
        }
    </>
);

const mapStateToProps = state => {
    return {
        open: state.windows.plannedWindow.visible,
        portalOpen: state.windows.plannedWindow.portal,
    }
};


const mapDispatchToProps = dispatch =>
    (
        {
            togglePlannedWindow: bindActionCreators(togglePlannedWindow, dispatch),
        }
    );

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(PlannedWindow);