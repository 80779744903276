import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core-v3/styles';
import Slider from "@material-ui/lab-v3/Slider/Slider";
import LensTwoTone from '@material-ui/icons-v3/LensTwoTone';
import CustomizedSvgIcon from "./CustomizedSvgIcon";


const styles = {
    root: {
        width: "100%",
    },
    slider: {
        padding: '30px 0px',
        marginLeft: "10px",
        marginRight: "10px"
    },
    thumbIcon: {
        borderRadius: '50%'
    },
    thumbIconWrapper: {
        backgroundColor: '#fff',
        width: 24,
        height: 24
    },
    thumbIconWrapperLarge: {
        backgroundColor: '#fff',
        width: 48,
        height: 48
    }
};


class MyCustomDoubleSlider extends React.Component {

    // state = {
    //     upperInitialValue: null,
    //     lowerInitialValue: null
    // };
    //
    // //yikes mixing props and local state.
    // static getDerivedStateFromProps(props, state) {
    //     if (props.upperSliderValue !== state.upperInitialValue) {
    //         return {upperInitialValue:props.upperSliderValue, upperSliderValue:props.upperSliderValue};
    //     }
    //     if (props.lowerSliderValue !== state.lowerInitialValue) {
    //         return {lowerInitialValue:props.upperSliderValue, lowerSliderValue:props.lowerSliderValue};
    //     }
    // }
    //
    _handleUpperSliderChange = (event, value) => {
        this.props.onUpperSliderChanged(value);
        //this.setState({upperSliderValue: value});

        if(this.props.lowerSliderValue < (value+this.props.minDiff))
        {
            this.props.onLowerSliderChanged(value+this.props.minDiff);
            //this.setState({lowerSliderValue: value});
        }

    };
    //
    _handleLowerSliderChange = (event, value) => {
        this.props.onLowerSliderChanged(value);
        //this.setState({lowerSliderValue: value});

        if(this.props.upperSliderValue > (value - this.props.minDiff))
        {
            this.props.onUpperSliderChanged(value - this.props.minDiff);
            //this.setState({upperSliderValue: value});

        }
    };

    _returnProperIcon = () => {
        if(this.props.path === "") {
            return (
                <LensTwoTone />
            );
        }
        else {
            return (
                <CustomizedSvgIcon path={this.props.path} iconSize={this.props.iconSize}/>
            );
        }
    };

    render() {
        const {classes} = this.props;
        const {upperSliderValue, lowerSliderValue} = this.props;
        const iconThumb = this._returnProperIcon();
        return (
            <div className={classes.root}>
                <Slider
                    value={upperSliderValue}
                    aria-labelledby="slider-icon"
                    onChange={this._handleUpperSliderChange}
                    step={this.props.step}
                    min={this.props.min}
                    max={this.props.max}
                    classes={{
                        container: classes.slider,
                        thumbIconWrapper: (this.props.thumbSize === "large" ? classes.thumbIconWrapperLarge : classes.thumbIconWrapper)
                    }}
                    thumb={iconThumb}
                />
                <Slider
                    value={lowerSliderValue}
                    aria-labelledby="slider-icon2"
                    onChange={this._handleLowerSliderChange}
                    step={this.props.step}
                    min={this.props.min}
                    max={this.props.max}
                    classes={{
                        container: classes.slider,
                        thumbIconWrapper: (this.props.thumbSize === "large" ? classes.thumbIconWrapperLarge : classes.thumbIconWrapper)
                    }}
                    thumb={iconThumb}
                />
            </div>
        );
    }
}

MyCustomDoubleSlider.propTypes = {
    path: PropTypes.string,
    thumbSize: PropTypes.string,
    onUpperSliderChanged: PropTypes.func,
    onLowerSliderChanged: PropTypes.func,
    iconSize: PropTypes.string,
    step: PropTypes.number,
    min: PropTypes.number,
    max: PropTypes.number,
};
MyCustomDoubleSlider.defaultProps = {
    path: "",
    thumbSize: "normal",
    initialLowerSliderValue: 0,
    initialUpperSliderValue: 0,
    onUpperSliderChanged: () => {},
    onLowerSliderChanged: () => {},
    iconSize: "mini",
    step: 1,
    min: 0,
    max: 100,
    minDiff: 1
};

export default withStyles(styles)(MyCustomDoubleSlider);