import * as React from "react";
import {isEqual, debounce} from "lodash";
import {LostControlAlert} from "../../../LogicV1Redux/constants/CheckinsV2Enums";
import {compose} from 'recompose';
import {connect} from "react-redux";

const BELL_PATH = '/sound/bicycle_bell.wav';
const HORN_PATH = '/sound/PansaUTM_LostControl.mp3';
const GONG_PATH = '/sound/beep.mp3';


class SoundFXController extends React.Component {

    _createTheHorn = () => {
        const horn = new Audio(HORN_PATH);
        horn.loop = true;
        return horn;
    };

    _createTheBell = () => {
        const bell = new Audio(BELL_PATH);
        return bell;
    };

    _createTheGong = () => {
        const gong = new Audio(GONG_PATH);
        return gong;
    };

    state = {
        hornSound: this._createTheHorn(),
        bellSound: this._createTheBell(),
        gongSound: this._createTheGong(),
    };

    static defaultProps = {
        checkins: {},
    };

    componentDidMount() {
        const {uiVolume} = this.props;
        if(!!uiVolume) {
            this._setVolume(uiVolume, {silent: true});
            console.log("SoundFXController did mount, set volume to ", uiVolume);
        }
    }

    _ringTheBell = () => {
        const {bellSound} = this.state;

        if(bellSound.readyState === 4) {
            bellSound.load();
        }
        bellSound.play().catch(
            (e) => console.warn('SoundFXController error on ringing the bell', e)
        );
    };

    _blowTheHorn = () => {
       const {hornSound} = this.state;

       if(hornSound.paused) {
           hornSound.play().catch(
               (e) => console.warn('SoundFXController error on blowing the horn', e)
           );
       }
    };

    _hitTheGong = debounce(
        () => {
            const {gongSound} = this.state;

            if (gongSound.readyState === 4) {
                gongSound.load();
            }
            gongSound.play()
                .catch(
                    (e) => console.warn('SoundFXController error hitting the gong', e)
                );
        },
        400
    );


    _stopTheHorn = () => {
        const {hornSound} = this.state;
        if(hornSound.readyState === 4) {
            hornSound.load();
        }
    };

    _setVolume = (volume, {silent = false} = {}) => {
        const {hornSound, bellSound, gongSound} = this.state;
        hornSound.volume = volume;
        bellSound.volume = volume;
        gongSound.volume = volume;

        if(!silent) {
            this._hitTheGong();
        }
    };

    componentDidUpdate(prevProps, prevState, snapshot) {

        const {rawData: currRawData, lostControlReviews: currLostControlReviews} = this.props.checkins;
        const {rawData: prevRawData, lostControlReviews: prevLostControlReviews} = prevProps.checkins;

        if(!isEqual(prevLostControlReviews, currLostControlReviews)) {
            if(Array.from(currLostControlReviews.values()).some(review=>review===LostControlAlert.SHOULD_REVIEW)) {
                this._blowTheHorn();
            }
            else {
                this._stopTheHorn();
            }
        }

        if(!isEqual(prevRawData, currRawData)) {
            if (!!currRawData) {

                const currLength = this._getInJurisdictionCheckinsIds(currRawData).length;
                const prevLength = this._getInJurisdictionCheckinsIds(prevRawData).length;

                if(currLength > prevLength) this._ringTheBell();
            }
        }

        if(!isEqual(this.props.uiVolume, prevProps.uiVolume)) {
            this._setVolume(this.props.uiVolume);
            console.log("SoundFXController did update, set volume to ", this.props.uiVolume);
        }
    }

    _getInJurisdictionCheckinsIds = (rawCheckinsArray) => {
        return rawCheckinsArray.map(
                checkinBundle =>
                    checkinBundle.inspectData.__inJurisdiction ? checkinBundle.inspectData.checkinRecord.uid : undefined
            )
            .filter(
                el => el !== undefined
            )
    };

    render() {
        return null;
    }
}

const mapStateToProps = state => {
    return {
        uiVolume: state.mapUxConfig.uiVolume,
    }
};

export default compose(
    connect(
        mapStateToProps,
        null
    )
)(SoundFXController);