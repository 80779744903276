import React from "react";
import compose from 'recompose/compose';
import MultiEmergencyPanel from "./prepared-components/MultiEmergencyPanel";

import Button from "@material-ui/core-v3/Button/Button";
import withStyles from "@material-ui/core-v3/styles/withStyles";
import BarsPanel from "./prepared-components/BarsPanel";
import OperationControlPanel from "./prepared-components/OperationControlPanel";
import PlannedWindow from "./prepared-components/PlannedWindow";
import BarsController from "./prepared-components/BarsController";
import ErrorBoundary from "../../dr_ra2/components/ErrorBoundary";

//import { Notification } from 'react-admin';
//import { translate } from 'ra-core';
// import {
//     showNotification,
//     hideNotification,
// } from 'react-admin';
// import LiveMap from "../../gl_map/LiveMap";
// import {Notification} from "ra-ui-materialui";
// import sizeMe from "react-sizeme";

const styles = theme => ({
    root: {
        display: "flex",
        flexDirection: "column",
        height: "100%",
    },
    button: {
        width: "100%",
    },
    list: {
        overflowY: "auto"
    }
});

class OperationTab extends React.Component {

    render() {
        const {
            classes,
        } = this.props;

        return (
            <div className={classes.root}>
                <ErrorBoundary>

                {/*<Button disabled={true} className={classes.button}>PLEASE SELECT OPERATION</Button>*/}
                <MultiEmergencyPanel/>

                <BarsController>
                    <BarsPanel/>
                    <PlannedWindow/>
                    <OperationControlPanel/>
                </BarsController>

                </ErrorBoundary>
            </div>
        )
    }
}

OperationTab.defaultProps = {
    //appBar: AppBar,
    //menu: Menu,
    //Pandora fix!
    //notification: Notification,
};

const enhance = compose(
    //translate,
    //muiThemeable(), // force redraw on theme change
    withStyles(styles),
);

export default enhance(OperationTab);
