import Switch from '@material-ui/core-v3/Switch';

import React, {Component} from "react";
import withStyles from "@material-ui/core-v3/styles/withStyles";
import {connect} from "react-redux";
import {compose} from 'recompose';
import {storeCallsignFloorNumberingEnabled} from "../../LogicV1Redux/actions/MapUxConfigActions";


const styles = theme => ({
    root: {

    },
    masterToggleContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexWrap: 'wrap',
    },
    chipContainer: {
        display: 'flex',
        justifyContent: 'left',
        flexWrap: 'wrap',
    },
    chip: {
        //margin: theme.spacing.unit,
        margin: '5px',
    },
});

class CallsignDisplayConfig extends Component {

    render() {
        const {classes, storeCallsignFloorNumberingEnabled, uiCallsignFloorNumberingEnabled} = this.props;

        return (
            <>
                <div className={classes.masterToggleContainer}>
                    <b>Callsign floor numbering</b>
                    <Switch
                        checked={uiCallsignFloorNumberingEnabled}
                        onChange={() => storeCallsignFloorNumberingEnabled(!uiCallsignFloorNumberingEnabled)}
                        value="checked"
                        color="primary"
                    />
                </div>
            </>
        );
    }
}



const mapStateToProps = (state) => {

    return {
        uiCallsignFloorNumberingEnabled: state.mapUxConfig.uiCallsignFloorNumberingEnabled
    }
};


const enhance = compose(
    withStyles(styles),
    connect(
        mapStateToProps,
        {
            storeCallsignFloorNumberingEnabled,
        },
    )
);

export default enhance(CallsignDisplayConfig);
