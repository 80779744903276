import {InspectTypes} from "../../../modules2lib/common/";
import MissionV2View from "./MissionV2View";

import React from "react";
import withStyles from "@material-ui/core-v3/styles/withStyles";
import {storeInspectData} from "../../../LogicV1Redux/actions/InspectActions";

import {connect} from "react-redux";
import compose from 'recompose/compose';

const LegInfoFixer = ({inspectData, checkinsV2, storeInspectDataAction}) => {
    console.log('leg inspectData', inspectData);
    const mission = inspectData.missionInspect;
    if (!mission) {
        return <div>clicking leg should show mission data.. fixme</div>
    }
    if (!mission.missionRecord) return false;

    //try to find related checkin first..

    console.log('mission', mission);
    const connectedCheckin = checkinsV2.rawData.find(el => {
        console.log('checkinsV2', el);

        const checkinRecord = el.inspectData.checkinRecord;
        if (!checkinRecord.missionid) return false;

        return (checkinRecord.missionid === mission.missionRecord.uid)
    });

    console.log('connected checkin', connectedCheckin);
    //temporarily removing as Hot fix, v2 connecting was not reviewed, to readd for 1.2?
    // if (connectedCheckin) {
    //     const fakeCheckinInspect = {checkin:connectedCheckin.inspectData, inspectType:InspectTypes.CHECKINV2};
    //
    //
    //     storeInspectDataAction(fakeCheckinInspect);
    //
    //     return <div>connecting checkin...</div>
    //
    // }

    const fakeMissionInspect = (mission)?{mission, legInspect:inspectData.legInspect, inspectType:InspectTypes.MISSIONV2}:null;

    return <MissionV2View inspectData={fakeMissionInspect}/>
};


const mapStateToProps = state => {
    //console.log('ColoredCheckInsListView mapStateToProps', state);
    return {
        checkinsV2: state.checkinsV2
    }
};

const mapDispatchToProps = dispatch =>
    (
        {
            storeInspectDataAction : (e) => {
                //hackish, but required
                setTimeout(() => {
                    console.log('delayed dispatchin storeInspectData', e);
                    dispatch(storeInspectData(e))
                }, 100);

            }
        }
    );

const enhance = compose(
    //translate,
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
);

export default enhance(LegInfoFixer);
