import AtcMap from "./AtcMap";
import compose from 'recompose/compose';
import {DRAW_MODES} from "../../glSetups/deckDraw/DrawEditorEnums";
import {
    showNotification,
} from 'react-admin';

import React from "react";
import {connect} from "react-redux";
import {clearAllEditablePrimitives, setDrawMode,} from "../../glSetups/deckDraw/deckDrawStoreBridge";
import PubSub from "pubsub-js";
import MyCustomFloatingButton from "../../../rt_map_ra2/components/prepared-components/MyCustomFloatingButton";
import Icon from "@material-ui/core-v3/Icon/Icon";
import withStyles from "@material-ui/core-v3/styles/withStyles";
import Button from '@material-ui/core-v3/Button';

import {PubSubSignals} from '../../../LogicV1Redux/constants/PubSubSignals'
import {defaultViewportData} from "../../ConfigurableOpts";
import {get} from "lodash";
import {unixTimeNow} from "../../../LogicV1Redux/oldLogic/TimeConverters";
import MyLayerBoundarySlider from "../../../mui_test_suite/customComponents/MyLayerBoundarySlider";
import {invertColorToCSSHexString} from "../../../dr_ra2/utils/cssColorInverter";
import {storeUiHeightFilter} from "../../../LogicV1Redux/actions/MapUxConfigActions";
import {fullAppReload} from "../../../LogicV1Redux/actions/AppStateActions"
import ServerClock from "./ServerClock";

const styles = theme => ({
    button: {
        margin: theme.spacing.unit,
    },
    rightButtons: {
        position: "absolute",
        top: 30,
        right: 0,
        width: theme.sizeLarge ? 130 : 100,
    },
    leftTopButtons: {
        position: "absolute",
        top: 30,
        left: 0,
        display: "flex",
        flexDirection: "row",
    },
    leftBottomButtons : {
        position: "absolute",
        bottom: theme.sizeLarge ? 130 : 80,
        height: 10,
        left: 0
    },
    leftMiddleSlider: {
        position: "absolute",
        top: 200,
        bottom: 200,
        left: 20,
        borderRadius: "5px",
        padding: "10px 10px",
        backgroundColor: invertColorToCSSHexString("#000", 0.75),
        display: "flex",
        flexDirection: "column",
        width: "13px"
    },
    clock : {
        position: "absolute",
        top: 5,
        left: 5,
        height: 10,
    },
});


function overrideViewport (mySelf) {
    let overridenViewportData = Object.assign({}, defaultViewportData);
    overridenViewportData.__ts = unixTimeNow();
    //const viewportOverride = get(mySelf, 'pandoraUser.options.viewport', null);
    const viewportOverride = get(mySelf, 'pandora.viewport', null);
    if (viewportOverride) {
        overridenViewportData.zoom = (viewportOverride.zoom)?viewportOverride.zoom:overridenViewportData.zoom;
        overridenViewportData.latitude = (viewportOverride.lat)?viewportOverride.lat:overridenViewportData.latitude;
        overridenViewportData.longitude = (viewportOverride.lon)?viewportOverride.lon:overridenViewportData.longitude;

    }
    console.log('overrideViewportData', overridenViewportData, viewportOverride);

    return overridenViewportData;
}



//atc map with custom  mui-based ui replacing draw toolbar and map controls
class AtcMapUIBase extends React.Component {
    constructor(props) {
        super(props);
        this.state = {bSliderVal:this.props.bSliderInit};

        this.handleCircleClick = this.handleCircleClick.bind(this);
        this.handleCapsuleClick = this.handleCapsuleClick.bind(this);
        this.handleCircle2kmClick = this.handleCircle2kmClick.bind(this);
        this.handleCapsule2kmClick = this.handleCapsule2kmClick.bind(this);
        this.handleZoomInClick = this.handleZoomInClick.bind(this);
        this.handleZoomOutClick = this.handleZoomOutClick.bind(this);
        this.handleRefreshClick = this.handleRefreshClick.bind(this);
        this.handleHomeClick = this.handleHomeClick.bind(this);
        this.onLayerBoundaryChange = this.onLayerBoundaryChange.bind(this);

    };

    componentDidMount() {
        const overridenViewportData = overrideViewport(this.props.mySelf);
        this.setState({overridenViewportData});
        this.setState({bSliderVal:this.props.bSliderInit});

        this.pubsubOnHomeViewPort = PubSub.subscribe(PubSubSignals.MAP_VIEWPORT_SET_HOME, () => {
            this.handleHomeClick();
        });

    }

    componentWillUnmount() {
        PubSub.unsubscribe(this.pubsubOnHomeViewPort);
    }


    componentWillReceiveProps(nextProps) {
        //console.log('MuiMapUI componentWillReceiveProps', nextProps);
        if (this.props !== nextProps) {
            if (nextProps.drawMode !== this.props.drawMode
                && nextProps.drawMode === DRAW_MODES.EDIT) {
                console.log('onDrawMode muimap ui');
                //force end edit for atc users after finalizing primitive
                // (no edit phase on pandora)
                //and pass signal from draw module
                setDrawMode(DRAW_MODES.NO_DRAW);
                const currPrimitive = this.props.currentEditablePrimitive;

                console.log('final primitive', currPrimitive);
                PubSub.publish(PubSubSignals.CREATE_CUSTOM_ALERT_DIALOG, {primitive:currPrimitive});
                //this.forceUpdate();
            }
            if ((nextProps.bSliderInit[0] !== this.props.bSliderInit[0])
                || (nextProps.bSliderInit[1] !== this.props.bSliderInit[1])) {
                this.setState({bSliderVal:nextProps.bSliderInit})
            }

        }
    }

    handleCircleClick(e) {
        clearAllEditablePrimitives();
        //e.preventDefault();
        if (this.props.drawMode === DRAW_MODES.DRAW_CIRCLE)
            setDrawMode(DRAW_MODES.NO_DRAW);
        else
            setDrawMode(DRAW_MODES.DRAW_CIRCLE);

        //this.forceUpdate();

        console.log('ui circle was clicked.');
    }

    handleCircle2kmClick(e) {
        clearAllEditablePrimitives();

        //e.preventDefault();
        if (this.props.drawMode === DRAW_MODES.DRAW_CIRCLE2KM)
            setDrawMode(DRAW_MODES.NO_DRAW);
        else
            setDrawMode(DRAW_MODES.DRAW_CIRCLE2KM);

        //this.forceUpdate();

        console.log('ui circle2km was clicked.');
    }

    handleCapsuleClick(e) {
        clearAllEditablePrimitives();

        //e.preventDefault();
        if (this.props.drawMode === DRAW_MODES.DRAW_CAPSULE)
            setDrawMode(DRAW_MODES.NO_DRAW);
        else
            setDrawMode(DRAW_MODES.DRAW_CAPSULE);

        //this.forceUpdate();

        console.log('ui capsule was clicked.');
    }

    handleCapsule2kmClick(e) {
        clearAllEditablePrimitives();

        //e.preventDefault();
        if (this.props.drawMode === DRAW_MODES.DRAW_CAPSULE2KM)
            setDrawMode(DRAW_MODES.NO_DRAW);
        else
            setDrawMode(DRAW_MODES.DRAW_CAPSULE2KM);

        //this.forceUpdate();
        console.log('ui capsule2km was clicked.');

    }

    handleRefreshClick(e) {
        //see implementation notes
        this.props.fullAppReload({forceReload:true});
    }

    handleZoomInClick(e) {
        //e.preventDefault();
        PubSub.publish(PubSubSignals.MAP_VIEWPORT_ZOOM_IN);

        //this.forceUpdate();
        console.log('ui zoom in was clicked.');
    }

    handleZoomOutClick(e) {
        //e.preventDefault();
        PubSub.publish(PubSubSignals.MAP_VIEWPORT_ZOOM_OUT);

        //this.forceUpdate();
        console.log('ui zoom out was clicked.');
    }

    handleHomeClick(e) {
        //e.preventDefault();
        const overridenViewportData = overrideViewport(this.props.mySelf);
        this.setState({overridenViewportData});
    }

    onLayerBoundaryChange(value) {
        console.log(value);
        this.setState({bSliderVal:value});
        this.props.storeUiHeightFilter({bottomCutoff:value[0], topCutoff:value[1]});
    }

    //TODO hardcoded margins - class not working
    render() {

        //console.log('muimap render props', this.props);
        //console.warn('muimap menu store override (DEV)');
        const {classes, uiSize} = this.props;
        const {overridenViewportData, bSliderVal} = this.state;

        // if (!overridenViewportData) {
        //     return <div>setting viewport</div>
        // }

        //fixme, unify editors after working implementation
        const lockViewHack = (this.props.drawMode === DRAW_MODES.DRAW_CIRCLE2KM)
            || (this.props.drawMode === DRAW_MODES.DRAW_CIRCLE)
            || (this.props.drawMode === DRAW_MODES.DRAW_CAPSULE2KM)
            || (this.props.drawMode === DRAW_MODES.DRAW_CAPSULE);

        return (

            <div style={{position: "absolute", top: 0, bottom: 0, left: 0, right: 0}}>
                <div>
                    <AtcMap lockViewHack = {lockViewHack}
                            viewportOverride={overridenViewportData}
                            heightBoundary = {this.bSliderVal}
                    />
                </div>

                <div className={classes.leftTopButtons}>
                    <Button variant="fab" color="primary" type="fab" style={{margin: "8px"}} className={classes.button}
                            size={uiSize==='large' ? "large" : "medium"}
                            onClick={this.handleHomeClick}
                    >
                        <Icon>
                            home
                        </Icon>
                    </Button>

                    <Button variant="fab" color="primary" type="fab" style={{margin: "8px"}} className={classes.button}
                            size={uiSize==='large' ? "large" : "medium"}
                            onClick={this.handleZoomInClick}
                    >
                        <Icon>
                            zoom_in
                        </Icon>
                    </Button>

                    <Button variant="fab" color="primary" style={{margin: "8px"}} className={classes.button}
                            size={uiSize==='large' ? "large" : "medium"}
                            onClick={this.handleZoomOutClick}
                    >
                        <Icon>
                            zoom_out
                        </Icon>
                    </Button>
                </div>

                <div className={classes.leftMiddleSlider}>
                    <MyLayerBoundarySlider
                        min={0} max={15000}
                        value={bSliderVal}
                        // marks={{100: '100m', 200: '200m', 300: '300m'}}
                        step={10}
                        onChange={this.onLayerBoundaryChange}
                    />
                </div>

                <div className={classes.leftBottomButtons}>
                    <Button variant="fab" color="primary" className={classes.button} style={{margin: "8px"}}
                            size={uiSize==='large' ? "large" : "medium"}
                            onClick={this.handleRefreshClick}
                    >
                        <Icon>
                            refresh
                        </Icon>
                    </Button>
                </div>

                <div className={classes.rightButtons} >
                    <MyCustomFloatingButton path="img/icons/circle2km-edit_v2.svg"
                                            color={((this.props.drawMode === DRAW_MODES.DRAW_CIRCLE2KM))?'secondary':'inherit'}
                                            iconSize={uiSize==='large' ? "large" : "medium"}
                                            onClick={this.handleCircle2kmClick}/>
                    <MyCustomFloatingButton path="img/icons/capsule2km-edit_v2.svg"
                                            color={((this.props.drawMode === DRAW_MODES.DRAW_CAPSULE2KM))?'secondary':'inherit'}
                                            iconSize={uiSize==='large' ? "large" : "medium"}
                                            onClick={this.handleCapsule2kmClick}/>
                    <MyCustomFloatingButton path="img/icons/circle-edit_v2.svg"
                                            color={((this.props.drawMode === DRAW_MODES.DRAW_CIRCLE))?'secondary':'inherit'}
                                            iconSize={uiSize==='large' ? "large" : "medium"}
                                            onClick={this.handleCircleClick}/>
                    <MyCustomFloatingButton path="img/icons/capsule-edit_v2.svg"
                                            color={((this.props.drawMode === DRAW_MODES.DRAW_CAPSULE))?'secondary':'inherit'}
                                            iconSize={uiSize==='large' ? "large" : "medium"}
                                            onClick={this.handleCapsuleClick}/>
                </div>
                <div className={classes.clock} >
                    <ServerClock />
                </div>

            </div>
        )
    }
}

const mapStateToProps = state => {
    const heigthFilter = state.mapUxConfig.heightFilter;
    //console.log('MuiMapUI mapStateToProps heigthFilter', heigthFilter);

    return {
        bSliderInit:[heigthFilter.bottomCutoff, heigthFilter.topCutoff],
        isLoading: state.admin.loading > 0,
        myAuth: state.myAuth,
        mySelf: state.mySelf,
        drawMode : state.drawEditorData.drawMode,
        currentEditablePrimitive : state.drawEditorData.currentPrimitive,
        editablePrimitives : state.drawEditorData.editablePrimitives,

    }
};

const enhance = compose(
    //muiThemeable(), // force redraw on theme change
    //translate,
    withStyles(styles),
    connect(
        mapStateToProps,
        {
            showNotification,
            storeUiHeightFilter,
            fullAppReload,
        }
    )
);

export default enhance(AtcMapUIBase);
