/**
 * @autor
 */

import ReactHtmlParser from "react-html-parser";
import React from "react";

const CLASS_PREFIX = 'iconClass';


class SvgToReactParser {

    _cutOutString = (str, start, stop) => {
        return str.substring(0, start)+str.substring(stop);
    };

    _createStyleArray = (styleMarkups) => {
        let styles = [];
        for(let styleLine of styleMarkups) {

            styleLine = this._cutOutString(styleLine, 0, 7);
            styleLine = styleLine.substring(0,styleLine.length-1);

            let map = new Map();
            let start = -1;
            while(start !== styleLine.length) {
                let colonPos = styleLine.indexOf(`:`, start);
                const key = styleLine.substring(++start, colonPos);
                start = styleLine.indexOf(`;`, start);
                const value = styleLine.substring(colonPos+1, start !== -1 ? start : start = styleLine.length);
                map.set(key,value);
            }
            styles.push(map);
        }
        return styles;
    };

    _createStyleClasses = (styleArray) => {
        let styles = {};
        for(let i = 0; i < styleArray.length; i++) {
            styles[CLASS_PREFIX + i] = {};
            styleArray[i].forEach((value, key) => {
                styles[CLASS_PREFIX + i][key] = value;
            })
        }

        return styles;
    };

    _createSvgMarkupsArray = (otherMarkups) => {
        
        const svgMarkups = [];
        const clearSvgMarkups = [];
        const svgMarkupsWithReactClassNames = [];
    
        for(const markupLine of otherMarkups) {
            if(markupLine.match(/<\/?g|\/?path|\/?circle|\/?rect|\/?ellipse|\/?line|\/?polyline|\/?polygon/)) {
                svgMarkups.push(markupLine);
            }
        }
    
        for(let svgMarkupLine of svgMarkups) {
            svgMarkupLine = this._sanitizeFromBadProps(svgMarkupLine);
            clearSvgMarkups.push(svgMarkupLine);
        }
        
        for(const clearedSvgMarkupLine of clearSvgMarkups) {
            let position;
            if( (position = clearedSvgMarkupLine.search(/CLASS#/)) !== -1) {
                const classNo = clearedSvgMarkupLine.charAt(position+6);
                const lineWithClassName = clearedSvgMarkupLine.replace(`CLASS#${classNo}`, `className={classes.${CLASS_PREFIX+classNo}}`);
                svgMarkupsWithReactClassNames.push(lineWithClassName);
            }
        }
        return svgMarkupsWithReactClassNames;
    };

    _sanitizeFromBadProps(svgMarkupLine) {
        let position;
        while ((position = svgMarkupLine.search(/inkscape|sodipodi|xml/)) !== -1) {
            let stop = svgMarkupLine.indexOf(`"`, position);
            stop = svgMarkupLine.indexOf(`"`, ++stop);
            svgMarkupLine = this._cutOutString(svgMarkupLine, position, stop+1);
        }
        return svgMarkupLine;
    }

    _splitMarkupsToStyleAndOtherWithClassId = (text) => {

        const styleMarkups = [];
        const otherMarkups = [];

        let styleMarkupsPositions = new Map();
        let otherMarkupsPositions = new Map();
        let start;
        let stop = -1;

        while( (start = text.indexOf(`style="`, ++stop)) !==-1) {
            stop = text.indexOf(`"`, start+7);
            styleMarkupsPositions.set(start, stop);
        }

        styleMarkupsPositions.forEach( (value, key) => {
            let styleSubstring = text.substring(key,value+1);
            styleMarkups.push(styleSubstring);
        });
        
        text = this._addClassId(styleMarkupsPositions, text);

        stop = -1;
        while( (start = text.indexOf(`<`, ++stop)) !==-1) {
            stop = text.indexOf(`>`, ++start);
            otherMarkupsPositions.set(start, stop);
        }

        otherMarkupsPositions.forEach( (value, key) => {
            let otherSubstring = text.substring(key-1,value+1);
            otherMarkups.push(otherSubstring);
        });

        return {
            styleMarkups: styleMarkups,
            otherMarkups: otherMarkups
        };
    };

    _addClassId(styleMarkupsPositions, text) {
        
        const reversedStyleMarkupPositions = new Map(Array.from(styleMarkupsPositions).reverse());
        let size = reversedStyleMarkupPositions.size;

        reversedStyleMarkupPositions.forEach((value, key, index) => {
            text = this._cutOutString(text, key, value + 1);
            text = text.slice(0, key) + ` CLASS#${--size} ` + text.slice(key);
        });
        return text;
    }

    _createSvgBodyMarkupString(text) {
        if(text.match(/<svg/) === null) return "";
        let match = text.match(/<g|<path|<circle|<rect|<ellipse|<line|<polyline|<polygon/);
        match = this._cutOutString(match[0], 0, 1);
        let pos = text.search(/<g|<path|<circle|<rect|<ellipse|<line|<polyline|<polygon/);
        text = this._cutOutString(text, 0, pos);

        let lastPos = 0;
        pos = 0;
        while (pos !== -1) {
            lastPos = pos;
            pos++;
            pos = text.indexOf(`</${match}>`, pos);
        }
        lastPos = text.indexOf(`>`, lastPos);



        text = this._cutOutString(text, lastPos + 1, text.length);

        text = this._sanitizeFromBadProps(text);
/**
        //fix for bad path termination
        let pathBeg = 0;
        let pathEnd = 0;
        pathBeg = text.indexOf(`<path`,pathBeg);
        pathEnd = text.indexOf(`/>`, pathBeg+1);
        let pathSubStr = text.substring(pathBeg, pathEnd);
        pathSubStr = pathSubStr.replace(/\>/, `></path>`);
        text = this._cutOutString(text, pathBeg, pathEnd);

        text = this._replaceAll(text, /\>/, `></path>`);
**/
        return text;
    };

    _replaceAll = (str, find, replace) => {
        return str.replace(new RegExp(find, 'g'), replace);
    };

    _transform = (node) => {
        return this._preventXSSAttack(node);
    };

    _preventXSSAttack = (node) => {
        if( node.name !== 'g' &&
            node.name !== 'path' &&
            node.name !== 'circle' &&
            node.name !== 'line' &&
            node.name !== 'ellipse' &&
            node.name !== 'rect' &&
            node.name !== 'polyline' &&
            node.name !== 'polygon' &&
            node.name !== 'text' &&
            node.name !== 'tspan' &&
            node.type !== 'text') {
            return null;
        }

        if( node.name === 'script' ||
            node.name === 'svg:script') {
            return null;
        }
    };


    /**
     * This function parses a SVG body to clear SVG markups and style class object
     * @param path Path to SVG icon. The icon should be placed in the same directory or deeper as the main html entry point.
     * Usage '/img/icon.svg'
     * @param callback Function that takes the HTML markups array and style class object as output arguments
     */
    parse = (path, callback) => {
        fetch(path)
            .then(response => response.text())
            .catch(reason => console.log(reason))
            .then(text => {
                const splittedWithClassId = this._splitMarkupsToStyleAndOtherWithClassId(text);
                const styleArray = this._createStyleArray(splittedWithClassId.styleMarkups);
                const styleClasses = this._createStyleClasses(styleArray);
                let svgArray = this._createSvgMarkupsArray(splittedWithClassId.otherMarkups);
                callback(styleClasses, svgArray);
            });
    };


    //TODO sodipodi, inkscape, xml:space
    /**
     * Converts SVG body to React component
     * @param path Path to SVG icon. The icon should be placed in the same directory or deeper as the main html entry point.
     * Usage '/img/icon.svg'
     * @param callback Function that takes the converted React component as output.
     */
    convertSvgBodyToReactComponent = (path, callback) => {
        fetch(path)
        .then(response => response.text())
        .catch(reason => console.log(reason))
        .then(text => {
            const clearedMarkupString = this._createSvgBodyMarkupString(text);
            const reactComponent = ReactHtmlParser(clearedMarkupString, {
                transform: this._transform
            });
            callback(reactComponent);
        });
    }


}

export default SvgToReactParser