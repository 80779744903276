import {FlightStatusesV2} from "./CheckinsV2Enums";

export const BarsConstants = {
    MISSION_PLANNED: "mission planned",
    MISSION_ONBLOCK: "mission on block",
    MISSION_INFLIGHT_NOCHECKIN: "mission no checkin",
    // With restrictions checkins are always connected
    WITH_RESTRICTIONS_CHECKIN_ONBLOCK: "connected checkin on block",
    WITH_RESTRICTIONS_CHECKIN_ONBLOCK_ACM_ACCEPTED: "connected checkin acm accepted on block",

    WITH_RESTRICTIONS_CHECKIN_INFLIGHT: "connected checkin in flight",
    WITH_RESTRICTIONS_CHECKIN_INFLIGHT_ACM_ACCEPTED: "connected checkin acm accepted in flight",

    UNKNOWN_STATUS_CHECKIN: "unknow bar status checkin",

    // No restrictions checkins can be connected and not
    NO_RESTRICTIONS_CHECKIN_ONBLOCK: "no restrictions checkin on block",
    NO_RESTRICTIONS_CHECKIN_INFLIGHT: "no restrictions checkin in flight",

    // TODO temporary name
    // Controlled and overdued
    CONTROLLED_CHECKIN_INFLIGHT_OVERDUED: "controlled checkin inflight overdued",
    CONTROLLED_CHECKIN_ONBLOCK_OVERDUED: "connected checkin onblock overdued",

};

export const BarsPriority = {
    [FlightStatusesV2.LOST_CONTROL]: 0,
    [FlightStatusesV2.LAND_NOW]: 1,
    [FlightStatusesV2.LANDING_ACK]: 2,
    [FlightStatusesV2.IN_QUEUE]: 5,
    [FlightStatusesV2.REJECTED]: 6,
    [FlightStatusesV2.REJECTED_ACK]: 7,
    [FlightStatusesV2.ATC_MODIFIED]: 8,
    [FlightStatusesV2.ATC_MODIFIED_ACK]: 9,
    [FlightStatusesV2.ACCEPTED]: 10,
    [FlightStatusesV2.ACCEPTED_ACK]: 11,
    [FlightStatusesV2.CREATED]: 12,
    [BarsConstants.MISSION_PLANNED]: 13,
};