
import {STORE_ALL_RT_MAP_ZONES_V1} from "../constants/RtMapZonesV1Constants";
import {processZonesV1ToDeck} from "../oldLogic/RtMapZonesV1";
import {initializeLayersObject} from "../../gl_map/glSetups/newCommon/mapStateConstructors";
import {processAlertsV1ToDeck} from "../oldLogic/AlertsV1List";
import {unixTimeNow} from "../oldLogic/TimeConverters";

//TODO add zone selection store.. but maybe faster would be switch to new zones,
// (anyway there will be a problem with duplicate in case of named zone alert v1)
const initialState = {rawData:[], deckData:initializeLayersObject(), storeTimestamp : 0};

const rtMapZonesV1Reducer = (previousState = initialState, { type, payload }) => {

    switch (type) {
        case STORE_ALL_RT_MAP_ZONES_V1:
            console.log('----- rtMapZonesReducer reducer STORE_ALL_RT_MAP_ZONES_V1:', type, payload);

            if (payload.rawData) {
                payload.deckData = processZonesV1ToDeck(payload);

            }

            return {...previousState, ...payload, storeTimestamp:unixTimeNow()};


        default:
            return previousState;
    }
};

export default rtMapZonesV1Reducer;
