//v1.0.4


import StoreProvider from '../../dr_ra2/MyStoreProvider'
import {InspectTypes} from "../../modules2lib/common/";
import {checkinStatus2ico, functionalColors} from "../../gl_map/glSetups/oldCommon/commonInits";
import {FlightStatuses} from '../../LogicV1Redux/constants/CheckinsV1Enums'
import {ICO_SIZES} from "../../gl_map/glSetups/oldCommon/deckInits";
import moment from "moment";
import DeckCircle from "../../gl_map/glSetups/deckPrimitives/DeckCircle";
import {turfCircle} from "../../gl_map/glSetups/oldCommon/commonTurf";
//import DataProvider from '../../dr_ra2/MyDataProvider'

//deprecated! for maps only
// export function getCheckIns() {
//     const store = StoreProvider.getStore();
//     const state = store.getState();
//     console.log('getCheckIns', state);
//     return state.checkinsV1;
// }

//see store notes -> current selection is passed from inspect data for now
export function processCheckinsV1ToDeck({selectedCheckin, rawData, bottomCutoff = Number.MIN_SAFE_INTEGER, topCutoff = Number.MAX_SAFE_INTEGER}) {

    let icos = [], points = [], paths = [], polys = [], texts = [];

    //console.warn('=========process checkins, inspect data', selectedCheckin);

    //console.log('checkins', checkins);

    // geometry
    //     :
    // {coordinates: Array(2), type: "Point"}
    // properties
    //     :
    // {height: 150, id: 5072, last_mod_atm_user: null, modified: 1522281738.90293, panic: null, …}

    rawData.forEach((checkin) => {

        try {

            let checkinIco = {}; //Object.assign({}, sampleIco);

            const isSelected = (selectedCheckin && selectedCheckin.properties.id === checkin.properties.id);

            checkinIco.icon = checkinStatus2ico(checkin.properties, isSelected);

            checkinIco.position = checkin.geometry.coordinates;

            //hmm, fixme
            checkinIco.position[2] = 100; //checkin.properties.height; //AGL/AMSL fixme!

            checkinIco.size = ICO_SIZES.CHECKIN;
            checkinIco.inspectData = {
                checkin,
                inspectType: InspectTypes.CHECKINV1
            };

            checkin.__outOfBoundary = (checkin.properties.__max < bottomCutoff);

            checkin.__lostControl = checkin.properties.status === FlightStatuses.LOST_CONTROL;

            if (checkin.__lostControl) {
                let alertPoly = {};

                const {droneLostBattery, droneLostSpeed} = checkin.properties.device_info;
                const opRadius = droneLostSpeed * (droneLostBattery/60) * 1000; //meters
                alertPoly.polygon = turfCircle(checkinIco.position, opRadius);
                alertPoly.elevation = 10000 || alert.extras.amslHeight || 1000; //AGL/AMSL fixme!!
                alertPoly.fillColor = functionalColors.ban;
                alertPoly.inspectData = {
                    checkin,
                    inspectType: InspectTypes.CHECKINV1
                };

                alert.draft = true;
                polys.push(alertPoly);

                //polys.push()
            }

            if (!checkin.__outOfBoundary) {

                icos.push(checkinIco);

                let text = {};
                text.centroid = checkinIco.position;

                //should be preprocessed.. probably
                const shortUntil = moment(checkin.properties.until * 1000).format('HH:mm');

                text.displayName = `${shortUntil}/${checkin.properties.__max}`;

                texts.push(text);
            }
        } catch (e) {
            console.error('error in checkin raw data', e.message);
            console.error('checkin', checkin);
        }

    });
    //console.log('checkins', icos);
    return {icos, points, paths, polys, texts};

}




