import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core-v3/styles';
import Button from '@material-ui/core-v3/Button';
import CustomizedSvgIcon from './CustomizedSvgIcon'
import {createMuiTheme, MuiThemeProvider} from '@material-ui/core-v3/styles';


const theme = createMuiTheme({
    overrides: {
        MuiButton: {
            mini: {
                minWidth: 40,
                minHeight: 40
            },
            sizeSmall: {
                minWidth: 50,
                minHeight: 50,
            },
            fab: {
                minWidth: 64,
                minHeight: 64
            },
            sizeLarge: {
                minWidth: 112,
                minHeight: 112
            }
        }
    }

});

const styles = theme => ({
    button: {
        margin: theme.spacing.unit,
    },
    extendedIcon: {
        marginRight: theme.spacing.unit,
    },
});


/**
 * This component extends standard MUI Button with the capability of setting the relative path to the icon and its size.
 * Optimized for 64x64px size icon and a 24x24px view box.
 * Use iconSize prop to define the size of the button. Accepted inputs: "mini", "small", "medium", "large". Default is set to "medium".
 * Use path prop to define the relative path of the svg file. Note that only public directories are accepted - e.g. "img/myImg.svg"
 */
class MyCustomFloatingButton extends React.Component {

    constructor(props) {
        super(props);

        this.setSize = () => {
            switch(this.props.iconSize) {
                case "mini":
                    return undefined;
                default:
                    return this.props.iconSize
            }
        };

        this.setMini = () => {
            switch(this.props.iconSize) {
                case "mini":
                    return true;
                default:
                    return false
            }
        };
    };

    render() {
        const {classes, color='inherit'} = this.props;

        return (
            <div>
                <MuiThemeProvider theme={theme}>
                     <div>
                        <Button variant="fab" color={color} aria-label="Add" className={classes.button} size={this.setSize()} mini={this.setMini()} onClick={this.props.onClick}>
                            <CustomizedSvgIcon path={this.props.path} iconSize={this.props.iconSize} />
                        </Button>
                    </div>
                </MuiThemeProvider>
            </div>
        );
    }


}


MyCustomFloatingButton.propTypes = {
    classes: PropTypes.object.isRequired,
    iconSize: PropTypes.string,
    path: PropTypes.string,
    onClick: PropTypes.func
};

MyCustomFloatingButton.defaultProps = {
    iconSize: "mini",
    path: "",
    onClick: () => {}
};


export default withStyles(styles)(MyCustomFloatingButton);