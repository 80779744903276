import Chip from "@material-ui/core-v3/Chip";
import EyeOnIcon from '@material-ui/icons-v3/Visibility';
import EyeOffIcon from '@material-ui/icons-v3/VisibilityOffOutlined';
import React from "react";

export const VisibilityChip = ({classes, clickHandler, key, label, active}) => {
    return (
        <Chip
            key={key}
            icon={(!!active) ? <EyeOnIcon/> : <EyeOffIcon/>}
            label={label}
            clickable
            className={classes.chip}
            //color="primary"
            onClick={clickHandler}
            //variant={(!!active)?'default':'outlined'}
            variant={'outlined'}
            //onDelete={handleDelete}
            //deleteIcon={<DoneIcon />}
        />
    );
};
