/**
 * Callsign Generator v1.0
 * @author Daniel Nalazek (C)2019 Droneradar
 */



/**
 * Generator mode
 * @type {{COMPANY: string, NAME: string}}
 */

export const MODE = {
    COMPANY: "mode company",
    NAME: "mode name",
};

/**
 * Letter type
 * @type {{CONSONANT: string, VOWEL: string}}
 */
export const Letter = {
    CONSONANT: 'consonant',
    VOWEL: 'vowel'
};

export function convertPLToAsciiUpperCase(word) {
    const upperCase = word.toUpperCase();
    const converted = [];
    for (let i = 0; i < upperCase.length; i++) {
        let char = upperCase[i];
        switch (char) {
            case 'Ą':
                char = 'A';
                break;
            case 'Ę':
                char = 'E';
                break;
            case 'Ć':
                char = 'C';
                break;
            case 'Ł':
                char = 'L';
                break;
            case 'Ń':
                char = 'N';
                break;
            case 'Ó':
                char = 'O';
                break;
            case 'Ś':
                char = 'S';
                break;
            case 'Ż':
                char = 'Z';
                break;
            case 'Ź':
                char = 'Z';
                break;
        }
        converted.push(char);
    }
    return converted.join("");
}

export function getOnlyAsciiLetters(word) {
    return word.replace(/[^[A-Za-z]]*/g, "");
}

export function endsWithVowel(word = String()) {
    return isVowel(word[word.length - 1]);
}

export function isVowel(letter) {
    if (typeof letter !== 'string') {
        throw new Error("Vowel check invalid argument type. Expected string.")
    }
    if (letter.length > 1) {
        throw new Error("Vowel check input string length error. Expected length equal to 1.")
    }
    return !!letter.match(/[aeiouy]/i);
}

export function* vowelGenerator() {
    yield* ['A', 'E', 'I', 'O', 'U', 'Y'];
}

export function* consonantGenerator() {
    for (let i = 65; i <= 90; i++) {
        const consonant = String.fromCharCode(i);
        if (isVowel(consonant)) {
            continue;
        }
        yield String.fromCharCode(i);
    }
}

export function* _specialLetterGenerator() {
    yield* ['Q', 'Y', 'X'];
}

export function* _thirdLevelGen(infix, blacklist, level) {
    //Level 3
    if (level < 3) return;
    for (let consonant of consonantGenerator()) {
        if (!infix.endsWith(consonant)) {
            const newConsonantCallsign = infix.slice(0, 4) + consonant;

            for (let vowel of vowelGenerator()) {
                const newCallsign = newConsonantCallsign.slice(0, 4) + vowel + newConsonantCallsign.slice(4, 5);
                if (!blacklist.includes(newCallsign)) {
                    yield newCallsign
                }
            }
        }
    }

    //Level 4
    if (level < 4) return;
    if (infix.length < 7) {
        for (let firstLetter of _specialLetterGenerator()) {
            const firstLetterWord = firstLetter + infix;
            if (!blacklist.includes(firstLetterWord)) {
                yield firstLetterWord;
            }
        }
    }


    //Level 5
    if (level < 5) return;
    if (infix.length < 6) {
        for (let firstLetter of _specialLetterGenerator()) {
            const firstLetterWord = firstLetter + infix;
            for (let secondLetter of _specialLetterGenerator()) {
                const secondLetterWord = secondLetter + firstLetterWord;
                if (!blacklist.includes(secondLetterWord)) {
                    yield secondLetterWord;
                }
            }
        }
    }


    //Level 6
    if (level < 6) return;
    for (let consonant of consonantGenerator()) {
        if (!infix.endsWith(consonant)) {
            const newConsonantCallsign = infix.slice(0, 4) + consonant;

            for (let vowel of vowelGenerator()) {
                const newCallsign = newConsonantCallsign.slice(0, 4) + vowel + newConsonantCallsign.slice(4, 5);

                for (let vowel of vowelGenerator()) {
                    const newCallsign2 = newCallsign + vowel;
                    if (!blacklist.includes(newCallsign2)) {
                        yield newCallsign2
                    }
                }
            }
        }
    }

    if (level < 7) return;
    for (let consonant of consonantGenerator()) {
        if (!infix.endsWith(consonant)) {
            const newConsonantCallsign = infix.slice(0, 4) + consonant;

            for (let vowel of vowelGenerator()) {
                const newCallsign = newConsonantCallsign.slice(0, 4) + vowel + newConsonantCallsign.slice(4, 5);

                for (let consonant of consonantGenerator()) {
                    const newCallsign2 = newCallsign + consonant;
                    if (!blacklist.includes(newCallsign2)) {
                        yield newCallsign2
                    }
                }
            }
        }
    }

    //Level 8
    if (level < 8) return;
    const forthLetter = infix.slice(3, 4);
    const isVowe = isVowel(forthLetter);
    const generator = isVowe ? vowelGenerator() : consonantGenerator();
    for (let forthNewLetter of generator) {
        if (forthNewLetter === forthLetter) continue;
        const forthLetterWord = infix.slice(0, 3) + forthNewLetter + infix.slice(4, 5);

        for (let consonant of consonantGenerator()) {
            if (!infix.endsWith(consonant)) {
                const newConsonantCallsign = forthLetterWord.slice(0, 4) + consonant;

                for (let vowel of vowelGenerator()) {
                    const newCallsign = newConsonantCallsign.slice(0, 4) + vowel + newConsonantCallsign.slice(4, 5);

                    for (let vowel2 of vowelGenerator()) {
                        const newCallsign2 = newCallsign + vowel2;
                        if (!blacklist.includes(newCallsign2)) {
                            yield newCallsign2
                        }
                    }
                    for (let consonant of consonantGenerator()) {
                        const newCallsign2 = newCallsign + consonant;
                        if (!blacklist.includes(newCallsign2)) {
                            yield newCallsign2
                        }
                    }
                }
            }
        }
    }
}

export function* _stickLetterGen(prefix, {pattern}) {

    for (const word of letterRecurrency(pattern)) {
        yield prefix + word
    }
}

export function getVowels(word) {
    const result = [];
    const matches = word.match(/[AEIOUY]/ig);
    if(matches) {
        matches.forEach(a => result.push(a.toUpperCase()));
    }
    return result;
}

export function getConsonants(word) {
    const result = [];
    const matches = word.match(/[bcdfghjklmnpqrstvwxz]/ig);
    if(matches) {
        matches.forEach(c => result.push(c.toUpperCase()));
    }
    return result;
}

export function getRandomVowel() {
    const rnd = Math.floor((Math.random() * 6)+1);
    const gen = vowelGenerator();
    let result;
    for(let i = 0; i < rnd; i++) {
       result = gen.next().value;
    }
    console.log(result, rnd);
    return result;
}

export function getRandomConsonant() {
    const rnd = Math.floor((Math.random() * 20)+1);
    const gen = consonantGenerator();
    let result;
    for(let i = 0; i < rnd; i++) {
        result = gen.next().value;
    }
    console.log(result, rnd);
    return result;
}

/**
 * Generates callsign from firstName and lastName or only company
 * @param firstName {*} string with first name
 * @param lastName {*} string with last name
 * @param company {*} string with company name
 * @param blacklist {Array} of callsigns that should not be generated
 * @param level {int} Level of generator in range 1-8
 * @returns {*} Callsing as a {String}
 */
export function callsignGenerator({firstName, lastName, company, blacklist = [], level = 2}) {
    const mode = _checkInputAndGetMode(firstName, lastName, company, blacklist, level);

    switch (mode) {
        case MODE.COMPANY:
            return _companyCallsignGen(company, blacklist, level);
        case MODE.NAME:
            return _nameCallsignGen(firstName, lastName, blacklist, level);
    }
    return null;
}

function* _getNameCallsign(lastName, firstName, blacklist, level) {
    const lastNameNonPolish = convertPLToAsciiUpperCase(lastName);
    const firstNameNonPolish = convertPLToAsciiUpperCase(firstName);
    const lastNameOnlyLetters = getOnlyAsciiLetters(lastNameNonPolish);
    const firstNameOnlyLetters = getOnlyAsciiLetters(firstNameNonPolish);


    let infix;

    if ((firstNameOnlyLetters.length + lastNameOnlyLetters.length) < 5) {
        infix = lastNameOnlyLetters + firstNameOnlyLetters;
        const endsWithConsonant = !endsWithVowel(infix);

        switch (infix.length) {
            case 1:
                if (endsWithConsonant) {
                    for (const word of _stickLetterGen(infix, {
                        pattern: [
                            Letter.VOWEL,
                            Letter.CONSONANT,
                            Letter.VOWEL,
                            Letter.CONSONANT
                        ]
                    })) {
                        infix = word;
                        if (!blacklist.includes(infix)) {
                            yield infix;
                        }
                    }
                }
                else {
                    for (const word of _stickLetterGen(infix, {
                        pattern: [
                            Letter.CONSONANT,
                            Letter.VOWEL,
                            Letter.CONSONANT,
                            Letter.VOWEL
                        ]
                    })) {
                        infix = word;
                        if (!blacklist.includes(infix)) {
                            yield infix;
                        }
                    }
                }
                break;
            case 2:
                if (endsWithConsonant) {
                    for (const word of _stickLetterGen(infix, {
                        pattern: [
                            Letter.VOWEL,
                            Letter.CONSONANT,
                            Letter.VOWEL,
                        ]
                    })) {
                        infix = word;
                        if (!blacklist.includes(infix)) {
                            yield infix;
                        }
                    }
                }
                else {
                    for (const word of _stickLetterGen(infix, {
                        pattern: [
                            Letter.CONSONANT,
                            Letter.VOWEL,
                            Letter.CONSONANT,
                            Letter.VOWEL
                        ]
                    })) {
                        infix = word;
                        if (!blacklist.includes(infix)) {
                            yield infix;
                        }
                    }
                }
                break;
            case 3:
                if (endsWithConsonant) {
                    for (const word of _stickLetterGen(infix, {
                        pattern: [
                            Letter.VOWEL,
                            Letter.CONSONANT,
                        ]
                    })) {
                        infix = word;
                        if (!blacklist.includes(infix)) {
                            yield infix;
                        }
                    }
                }
                else {
                    for (const word of _stickLetterGen(infix, {
                        pattern: [
                            Letter.CONSONANT,
                            Letter.VOWEL,
                            Letter.CONSONANT,
                        ]
                    })) {
                        infix = word;
                        if (!blacklist.includes(infix)) {
                            yield infix;
                        }
                    }
                }
                break;
            case 4:
                if (endsWithConsonant) {
                    for (const word of _stickLetterGen(infix, {
                        pattern: [
                            Letter.VOWEL,
                            Letter.CONSONANT,
                        ]
                    })) {
                        infix = word;
                        if (!blacklist.includes(infix)) {
                            yield infix;
                        }
                    }
                }
                else {
                    for (const word of _stickLetterGen(infix, {
                        pattern: [
                            Letter.CONSONANT,
                        ]
                    })) {
                        infix = word;
                        if (!blacklist.includes(infix)) {
                            yield infix;
                        }
                    }
                }
                break;


        }
    }
    else {
        let lastNmInfix = lastNameOnlyLetters.substr(0, 2);
        let firstNmInfix = firstNameOnlyLetters.substr(0, 3);
        const endsLstWithVowel = endsWithVowel(lastNmInfix);
        const endsFstWithConsonant = !endsWithVowel(firstNmInfix);
        const beginsFstWithVowel = isVowel(firstNmInfix[0]);

        if(endsLstWithVowel && endsFstWithConsonant && !beginsFstWithVowel) {
            infix = lastNmInfix + firstNmInfix
        }
        else {
            //BR+E
            if(!endsLstWithVowel) {
                const vowel = getVowels(lastNameOnlyLetters.replace(lastNmInfix, ""))[0];
                lastNmInfix += (vowel ? vowel : getRandomVowel());
            }
            //AME+K
            if(!endsFstWithConsonant) {
                const consonant = getConsonants(firstNameOnlyLetters.replace(firstNmInfix, ""))[0];
                firstNmInfix += (consonant ? consonant : getRandomConsonant());
            }


            if (beginsFstWithVowel && (lastNmInfix.length + firstNmInfix.length ) > 5 && lastNmInfix.length < 3) {
                const consonant = getConsonants(lastNameOnlyLetters.replace(lastNmInfix, ""))[0];
                infix = lastNmInfix + (consonant ? consonant : getRandomConsonant()) + firstNmInfix;
            }
            else if (beginsFstWithVowel && (lastNmInfix.length + firstNmInfix.length ) === 5) {
                const consonant = getConsonants(lastNameOnlyLetters.replace(lastNmInfix, ""))[0];
                infix = lastNmInfix + (consonant ? consonant : getRandomConsonant()) + firstNmInfix;
            }
            else if(beginsFstWithVowel && (lastNmInfix.length + firstNmInfix.length > 5)) {
                infix = lastNmInfix.substr(0, 2) + firstNmInfix;
            }
            else {
                infix = lastNmInfix + firstNmInfix;
            }

        }



        if (!blacklist.includes(infix)) {
            yield infix;
        }

        //Level 2
        if (level < 2) return;
        for (let consonant of consonantGenerator()) {
            if (!infix.endsWith(consonant)) {
                const newCallsign = infix.slice(0, infix.length-1) + consonant;
                if (!blacklist.includes(newCallsign)) {
                    yield newCallsign
                }
            }
        }
    }
    //Level3+
    for (const result of _thirdLevelGen(infix, blacklist, level)) {
        yield result;
    }
    return null;
}

function* _nameCallsignGen(firstName, lastName, blacklist, level) {
    for (const callsign of _getNameCallsign(lastName, firstName, blacklist, level)) {
        yield callsign
    }
}

function _checkInputAndGetMode(firstName, lastName, company, blacklist, level) {
    const name = firstName && lastName;

    if(!(level > 0 && level <= 8)) {
        throw new Error("Callsign Generator: invalid arguments in input object. Level shoud be int in range 1 to 8");
    }
    if (!company && !name) {
        throw new Error("Callsign Generator: invalid arguments in input object. Required company or both firstName and lastName");
    }
    if (company && typeof company !== 'string') {
        throw new Error("Callsign Generator: invalid type of company. Expected String");
    }
    if (firstName && typeof firstName !== 'string') {
        throw new Error("Callsign Generator: invalid type of firstName. Expected String");
    }
    if (lastName && typeof lastName !== 'string') {
        throw new Error("Callsign Generator: invalid type of lastName. Expected String");
    }

    if (!Array.isArray(blacklist)) {
        throw new Error("Callsign Generator: invalid type of blacklist. Expected Array");
    }
    else {
        for (const black of blacklist) {
            if (typeof black !== 'string') {
                throw new Error("Callsign Generator: invalid type of blacklist array element. Expected String");
            }
        }
    }
    if (company) {
        return MODE.COMPANY
    }
    else {
        return MODE.NAME
    }
}

function* letterRecurrency(pattern, result = "", i = 0) {
    if (pattern[i] === Letter.CONSONANT) {
        for (const consonant of consonantGenerator()) {
            result = result.substr(0, i) + consonant;
            if (i === pattern.length - 1) {
                yield result;
            }
            else {
                for (const res of letterRecurrency(pattern, result, i + 1)) {
                    yield res;
                }
            }
        }
    }
    else {
        for (const vowel of vowelGenerator()) {
            result = result.substr(0, i) + vowel;
            if (i === pattern.length - 1) {
                yield result;
            }
            else {
                for (const res of letterRecurrency(pattern, result, i + 1)) {
                    yield res;
                }
            }
        }
    }
}

function* _getCompanyCallsign(company, blacklist, level) {
    const companyNonPolish = convertPLToAsciiUpperCase(company);
    const companyOnlyLetters = getOnlyAsciiLetters(companyNonPolish);

    let infix;

    if (companyOnlyLetters.length < 5) {
        const endsWithConsonant = !endsWithVowel(companyOnlyLetters);
        infix = companyOnlyLetters;
        switch (companyOnlyLetters.length) {
            case 1:
                if (endsWithConsonant) {
                    for (const word of _stickLetterGen(infix, {
                        pattern: [
                            Letter.VOWEL,
                            Letter.CONSONANT,
                            Letter.VOWEL,
                            Letter.CONSONANT
                        ]
                    })) {
                        infix = word;
                        if (!blacklist.includes(infix)) {
                            yield infix;
                        }
                    }
                }
                else {
                    for (const word of _stickLetterGen(infix, {
                        pattern: [
                            Letter.CONSONANT,
                            Letter.VOWEL,
                            Letter.CONSONANT,
                            Letter.VOWEL
                        ]
                    })) {
                        infix = word;
                        if (!blacklist.includes(infix)) {
                            yield infix;
                        }
                    }
                }
                break;
            case 2:
                if (endsWithConsonant) {
                    for (const word of _stickLetterGen(infix, {
                        pattern: [
                            Letter.VOWEL,
                            Letter.CONSONANT,
                            Letter.VOWEL,
                        ]
                    })) {
                        infix = word;
                        if (!blacklist.includes(infix)) {
                            yield infix;
                        }
                    }
                }
                else {
                    for (const word of _stickLetterGen(infix, {
                        pattern: [
                            Letter.CONSONANT,
                            Letter.VOWEL,
                            Letter.CONSONANT,
                            Letter.VOWEL
                        ]
                    })) {
                        infix = word;
                        if (!blacklist.includes(infix)) {
                            yield infix;
                        }
                    }
                }
                break;
            case 3:
                if (endsWithConsonant) {
                    for (const word of _stickLetterGen(infix, {
                        pattern: [
                            Letter.VOWEL,
                            Letter.CONSONANT,
                        ]
                    })) {
                        infix = word;
                        if (!blacklist.includes(infix)) {
                            yield infix;
                        }
                    }
                }
                else {
                    for (const word of _stickLetterGen(infix, {
                        pattern: [
                            Letter.CONSONANT,
                            Letter.VOWEL,
                            Letter.CONSONANT,
                        ]
                    })) {
                        infix = word;
                        if (!blacklist.includes(infix)) {
                            yield infix;
                        }
                    }
                }
                break;
            case 4:
                if (endsWithConsonant) {
                    for (const word of _stickLetterGen(infix, {
                        pattern: [
                            Letter.VOWEL,
                            Letter.CONSONANT,
                        ]
                    })) {
                        infix = word;
                        if (!blacklist.includes(infix)) {
                            yield infix;
                        }
                    }
                }
                else {
                    for (const word of _stickLetterGen(infix, {
                        pattern: [
                            Letter.CONSONANT,
                        ]
                    })) {
                        infix = word;
                        if (!blacklist.includes(infix)) {
                            yield infix;
                        }
                    }
                }
                break;


        }
    }
    else {
        infix = companyOnlyLetters.substr(0, 5);
        const endsWithConsonant = !endsWithVowel(infix);

        if (endsWithConsonant && !blacklist.includes(infix)) {
            yield infix;
        }
        else {
            const nextLetterWord = companyOnlyLetters.substr(0, 6);
            if (!endsWithVowel(nextLetterWord) && !blacklist.includes(nextLetterWord)) {
                infix = nextLetterWord;
                yield nextLetterWord
            }
            else {
                const fullLetterWord = companyOnlyLetters.substr(0, 7);
                if (!endsWithVowel(nextLetterWord) && !blacklist.includes(fullLetterWord)) {
                    yield fullLetterWord
                }
            }
        }

        //Level 2
        if (level < 2) return;
        for (let consonant of consonantGenerator()) {
            if (!infix.endsWith(consonant)) {
                const newCallsign = infix.slice(0, 4) + consonant;
                if (!blacklist.includes(newCallsign)) {
                    yield newCallsign
                }
            }
        }
    }
    //Level3+
    for (const result of _thirdLevelGen(infix, blacklist, level)) {
        yield result;
    }
    return null;
}

function* _companyCallsignGen(company, blacklist, level) {
    for (const callsign of _getCompanyCallsign(company, blacklist, level)) {
        yield callsign
    }
}
