import React, {Component} from "react";
import {connect} from 'react-redux';
import compose from 'recompose/compose';
import moment from "moment";

class ServerClock extends Component {

    render() {
        if (!this.props.socket) return null;
        const {lastServerTs} = this.props.socket;
        if (!lastServerTs) return null;
        //const fTime = moment(lastServerTs*1000).format('HH:mm:ss.SS');
        const fTime = moment(lastServerTs*1000).utc().format('HH:mm:ss');
        //TODO add styling based on estimated connectiity issues
        return (
            <code style={{color:'#222'}}>
                {fTime}
            </code>
        )
    }
}



const mapStateToProps = state => {
    return {
        socket: state.socket
    }
};

const enhance = compose(
    //muiThemeable(), // force redraw on theme change
    //translate,
    //withStyles(styles),
    connect(
        mapStateToProps,
        null
    )
);

export default enhance(ServerClock);
