//v1 related

export const FlightStatuses = {
    POSTED : 0,
    ACCEPTED : 1,
    REJECTED : -1,
    CANCELED : -2,
    LOST_CONTROL : -3,
    LANDING_ACK : -4,
    HOLD_RQ : -5,
    HOLD_ACK : -6,
    HOLD_ON : -7
};
export const RenderModes = {
    DEFAULT : 'default',
    FLIGHT_ACTIVE : 'flightActive',
    LANDING_ACK : 'landingAck',
    LOST_CONTROL: 'lostControl',
    HOLD_RQ: 'holdRq',
    HOLD_ACK: 'holdAck',
    HOLD_ON : 'holdOn',

};

export const PostRqEnums = {
    CREATE : 0,
    ACCEPT : 1,
    REJECT : -1,
    CANCEL : -2,
    LOST_CONTROL : -3,
    LANDING_ACK : -4,
    HOLD_RQ : -5,
    HOLD_ACK : -6,
    HOLD_ON : -7
};
