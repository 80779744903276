import React from "react";
import withStyles from "@material-ui/core-v3/styles/withStyles";
import Card from "@material-ui/core-v3/Card/Card";
import CardContent from "@material-ui/core-v3/CardContent/CardContent";
import Typography from "@material-ui/core-v3/Typography/Typography";
import {dateTimeFormatter} from "../../../dr_ra2/utils/momentHelpers";
import MyCollapse from "../prepared-components/MyCollapse";
import {ft2m} from "../../../LogicV1Redux/oldLogic/GeoConverters";
import {get} from "lodash";
import {AppConfig} from "../../AppRtMap";

const styles = theme => ({
    layoutRow: {
        display: "flex",
        flexDirection: "row",
    },
    hr: {
        alignSelf: "center",
        flex: "1"
    },
    plannedMissionCard: {
        width: "100%",
        marginBottom: "2em",
    },
    dots: {
        flex: 1,
        minWidth: 0,
        '&:before': {
            color: "#888",
            marginLeft: "5px",
            marginRight: "5px",
            display: "block",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "clip",
            content: "'. . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .'"
        }


    },
    greyCard: {
        backgroundColor: "rgb(245, 245, 245)"
    },
    collapseDetails: {
        marginTop: "1em",
        willChange: "content"
    },
    expand: {
        transform: 'rotate(0deg)',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
        [theme.breakpoints.up('sm')]: {
            marginRight: -8,
        },
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    collapseButton: {
        padding: "unset",
        paddingRight: "8px",
        willChange: "transform"
    },
});

//TODO refactor
//used by rt-map2 now...
class MissionV2View extends React.Component {

    state = {
        expandedObservator: false,
        expandedLegs: false,
    };

    _handleExpandClick = (stateName) => {
        this.setState(state => ({ [stateName]: !state[stateName] }));
    };

    render() {
        const {classes, inspectData} = this.props;

        console.log('render MissionV2View props', this.props);

        const missionRecord = get(inspectData, 'mission.missionRecord', {});

        console.log('missionRecord', missionRecord);

        const {start, stop, created, legs, operator = {}, etc = {}, govMsgs, name, approval, mcmt, callsign} = missionRecord;
        const {observers, is112, smbt, activityType, shortDescription} = etc;

        //fck,
        const id = missionRecord.uid;
        const type = missionRecord.typ;

        //hmm
        const displayMaxHeight = missionRecord.displayMaxHeight || 'N/A';

        //extras
        const {issuerEmail} = (govMsgs)?govMsgs[govMsgs.length-1]:{};
        const hasJurisdiction = inspectData.mission.__inJurisdiction;

        return (
            <>

            <Card className={classes.plannedMissionCard}>
                <CardContent>
                    <Typography variant="title">Mission details</Typography>
                </CardContent>

                <CardContent className={classes.greyCard}>

                    <Typography variant="body1">
                        {approval || 'N/A'}
                    </Typography>

                    <Typography variant="inherit">
                        <b>{name || 'N/A'}</b>
                    </Typography>
                    {
                        is112 &&
                        <Typography variant="button">
                            112R
                        </Typography>
                    }
                    <Typography variant="caption">
                        {shortDescription || 'N/A'}
                    </Typography>

                    <div className={classes.collapseDetails}>
                        <Typography variant="body1">
                            {operator.displayName}
                        </Typography>
                        <Typography variant="body1">
                            {operator.phone || 'phone N/A'}
                        </Typography>
                        {observers &&
                        <MyCollapse title={'Observers'}>
                            {observers.map((el,i) =>
                                <div key={i}>
                                    <Typography variant="body1">
                                        {el.name}
                                    </Typography>
                                    <Typography variant="body1">
                                        {el.phone || 'phone N/A'}
                                    </Typography>
                                </div>
                            )}
                        </MyCollapse>
                        }
                    </div>
                </CardContent>

                <CardContent>
                    {
                        AppConfig.callsignEngineStatic &&
                        <div className={classes.layoutRow}>
                            <Typography variant="inherit">callsign</Typography>
                            <span className={classes.dots}/>
                            <Typography variant="inherit" >{callsign}</Typography>
                        </div>
                    }

                    <div className={classes.layoutRow}>
                        <Typography variant="inherit">ID</Typography>
                        <span className={classes.dots}/>
                        <Typography variant="inherit" >{id}</Typography>
                    </div>
                    <div className={classes.layoutRow}>
                        <Typography variant="inherit">created</Typography>
                        <span className={classes.dots}/>
                        <Typography variant="inherit">{dateTimeFormatter(created, {useUtc:true})}</Typography>
                    </div>
                    <div className={classes.layoutRow}>
                        <Typography variant="inherit">activity</Typography>
                        <span className={classes.dots}/>
                        <Typography variant="inherit">{activityType || 'N/A'}</Typography>
                    </div>
                    <div className={classes.layoutRow}>
                        <Typography variant="inherit">starting (UTC)</Typography>
                        <span className={classes.dots}/>
                        <Typography variant="inherit">{dateTimeFormatter(start, {useUtc:true})}</Typography>
                    </div>
                    <div className={classes.layoutRow}>
                        <Typography variant="inherit">ending (UTC)</Typography>
                        <span className={classes.dots}/>
                        <Typography variant="inherit">{dateTimeFormatter(stop, {useUtc:true})}</Typography>
                    </div>
                    {/*was removed from specification*/}
                    {/*<div className={classes.layoutRow}>*/}
                        {/*<Typography variant="inherit">additional/alternative dates</Typography>*/}
                        {/*<span className={classes.dots}/>*/}
                        {/*<Typography variant="inherit">{fallbackDates}</Typography>*/}
                    {/*</div>*/}
                    <div className={classes.layoutRow}>
                        <Typography variant="inherit">max height AMSL*</Typography>
                        <span className={classes.dots}/>
                        <Typography variant="inherit">{displayMaxHeight} ft / {Math.round(ft2m(displayMaxHeight))} m</Typography>
                    </div>
                    <div className={classes.layoutRow}>
                        <Typography variant="inherit">type</Typography>
                        <span className={classes.dots}/>
                        <Typography variant="inherit">{type || 'N/A'}</Typography>
                    </div>
                    <div className={classes.layoutRow}>
                        <Typography variant="inherit">MCMT</Typography>
                        <span className={classes.dots}/>
                        <Typography variant="inherit">{mcmt || 'N/A'}</Typography>
                    </div>
                    <div className={classes.layoutRow}>
                        <Typography variant="inherit">SMBT</Typography>
                        <span className={classes.dots}/>
                        <Typography variant="inherit">{smbt || 'N/A'}</Typography>
                    </div>
                    <div className={classes.layoutRow}>
                        <Typography variant="inherit">accepted by</Typography>
                        <span className={classes.dots}/>
                        <Typography variant="inherit">{issuerEmail || 'N/A'}</Typography>
                    </div>
                    <br/>
                    <Typography variant="caption">* NOTICE: AMSL calculated height, can contain error</Typography>
                    <br/>
                    {legs && legs.length>0 && //hmm?
                    <MyCollapse title={'Legs'} style={{display: 'none'}}>
                        {legs.map(el =>
                            el.zoneLeg.map((zoneLeg,i)=>
                                <div className={classes.layoutRow} key={i}>
                                    <Typography variant="inherit">{++i}</Typography>
                                    <span className={classes.dots}/>
                                    <Typography variant="body1">
                                        {zoneLeg.zoneId}
                                    </Typography>
                                </div>
                            )
                        )}
                    </MyCollapse>
                    }

                </CardContent>
                <CardContent className={classes.greyCard}>
                    {(hasJurisdiction)?'IN JURISDICTION':'OUTSIDE OF JURISDICTION'}
                </CardContent>
            </Card>
            {/*<pre>*/}
                {/*{JSON.stringify(inspectData, undefined, 2)}*/}
            {/*</pre>*/}
        </>
        )
    }
}


export default withStyles(styles)(MissionV2View);
