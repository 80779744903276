import React from "react";
import withStyles from "@material-ui/core-v3/styles/withStyles";
import Card from "@material-ui/core-v3/Card/Card";
import CardContent from "@material-ui/core-v3/CardContent/CardContent";
import PropTypes from "prop-types";
import {get} from 'lodash';
import Typography from "@material-ui/core-v3/Typography";
import {InspectTypes} from "../../../modules2lib/common/";
import {calcThreeLights} from '../../../modules2lib/examples/myEsmWrapper'


const styles = {
    layoutRow: {
        display: "flex",
        flexDirection: "row",
    },
    droneCard: {
        width: "100%",
        marginBottom: "2em",
    },
    dots: {
        flex: 1,
        minWidth: 0,
        '&:before': {
            color: "#888",
            marginLeft: "5px",
            marginRight: "5px",
            display: "block",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "clip",
            content: "'. . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .'"
        }


    },
    greyCard: {
        backgroundColor: "rgb(245, 245, 245)"
    },
};

//stub now

const RowRenderer = ({data, idx, classes}) => (
    <div className={classes.layoutRow}>
        <Typography variant="inherit">{data.__zoneName}</Typography>
        <span className={classes.dots}/>
        <Typography variant="inherit" >{data.code}</Typography>
    </div>
)

const ZonesLights = ({threeLightsData, classes}) => {
    const views = [];

    for (let i = 0; i < threeLightsData.length; i++) {
        const v = <RowRenderer key={i} data={threeLightsData[i]} classes={classes} />;
        views.push(v);
    }

    return views;
};

//should be optimized to pure and (ontick), but won't be used probably
class ThreeLightsView extends React.Component {

    render() {
        const {classes, inspectData} = this.props;
        console.log('ThreeLightsView props', this.props);
        const {pickedLngLat, pickedInfos} = inspectData;

        const zonesForThreeLighs = [];
        for (let i = 0; i < pickedInfos.length; i++) {
            console.log('pickedInfoEl', pickedInfos[i]);
            const inspectData = get(pickedInfos[i], 'object.inspectData', false);
            if (inspectData.inspectType === InspectTypes.ZONEV2) {
                zonesForThreeLighs.push(inspectData);
            }
        }

        let threeLightsData = calcThreeLights({zones:zonesForThreeLighs});

        threeLightsData.sort((a, b) =>
            (a.value > b.value ? 1
                : a.value === b.value ?
                    (a.__zoneName > b.__zoneName ? 1 : -1) : -1));

        console.warn('three lighs response', threeLightsData);

        return (
            <div>
                <Card className={classes.droneCard}>
                    <CardContent>
                        Color: (highest from list)
                    </CardContent>
                    <CardContent className={classes.greyCard}>
                        <div>
                            <Typography variant="inherit">
                                {pickedLngLat[1]}
                            </Typography>
                        </div>
                        <div>
                            <Typography variant="inherit">
                                {pickedLngLat[0]}
                            </Typography>
                        </div>
                    </CardContent>
                    <CardContent>
                    <div className={classes.layoutRow}>
                        <Typography variant="inherit">weight</Typography>
                        <span className={classes.dots}/>
                        <Typography variant="inherit" >{}</Typography>
                    </div>
                    </CardContent>
                    <CardContent>
                        <ZonesLights classes={classes} threeLightsData={threeLightsData} />
                    </CardContent>
                </Card>
                {/*<EmergencyPanel/>*/}
            </div>
        )
    }
}

// ZoneV1View.propTypes = {
//     zoneName: PropTypes.string
// };
//
// ZoneV1View.defaultProps = {
//     zoneName: "Zone Name"
// };

export default withStyles(styles)(ThreeLightsView);
