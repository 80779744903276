import {initializeLayersObject} from "../../gl_map/glSetups/newCommon/mapStateConstructors";
import {unixTimeNow} from "../oldLogic/TimeConverters";
import {STORE_ALL_RTDM_AEROBITS_TICKS} from "../constants/RtdmAerobitsTicksConstants";
import {processRtdmAerobitsTicksToDeck} from "../oldLogic/RtdmSources";

const initialState = {rawData: [], deckData: initializeLayersObject(), storeTimestamp: 0};

const rtdmAerobitsTicksReducer = (previousState = initialState, { type, payload }) => {

    switch (type) {
        case STORE_ALL_RTDM_AEROBITS_TICKS:
            //console.log('----- checkinsReducer reducer STORE_ALL_CHECKINS_V1:', type, payload);

            if (payload.rawData) {
                payload.deckData = processRtdmAerobitsTicksToDeck(payload);
            }
            //console.log('----- checkinsReducer reducer STORE_ALL_CHECKINS_V1:', type, payload);

            return {...previousState, ...payload, storeTimestamp:unixTimeNow()};

        default:
            return previousState;
    }
};

export default rtdmAerobitsTicksReducer;
