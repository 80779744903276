import {initializeLayersObject} from "../../gl_map/glSetups/newCommon/mapStateConstructors";
import {unixTimeNow} from "../oldLogic/TimeConverters";
import {STORE_ALL_LIVE_ADSB_EXCHANGE} from "../constants/LiveAdsbExchangeConstants";
import {processLiveAdsbExchangeToDeck} from "../oldLogic/LiveAdsbExchangeList";

const initialState = {rawData: [], deckData: initializeLayersObject(), storeTimestamp: 0};

const liveAdsbExchangeReducer = (previousState = initialState, { type, payload }) => {

    switch (type) {
        case STORE_ALL_LIVE_ADSB_EXCHANGE:
            //console.log('----- checkinsReducer reducer STORE_ALL_CHECKINS_V1:', type, payload);

            if (payload.rawData) {
                payload.deckData = processLiveAdsbExchangeToDeck(payload.rawData);
            }
            //console.log('----- checkinsReducer reducer STORE_ALL_CHECKINS_V1:', type, payload);

            return {...previousState, ...payload, storeTimestamp:unixTimeNow()};

        default:
            return previousState;
    }
};

export default liveAdsbExchangeReducer;
