//during dev one can switch mode by uncommenting/commenting correct apps

//import * as CurrApp from './checkin_flow/App';
//import * as CurrApp from './mui_test_suite/App';
//import * as CurrApp from './dr_ra2/AppGov';
//import * as CurrApp from './dr_ra2/AppOperator';
//import * as CurrApp from './gl_map/AppDeckGL';
//import * as CurrApp from './rt_map_ra2/AppRtMap';

//this line is set in build.sh
import * as CurrApp from './rt_map_ra2/AppRtMap';

export const App = CurrApp.App;
export const CurrAppConfig = CurrApp.AppConfig;
