import React from "react";
import withStyles from "@material-ui/core-v3/styles/withStyles";
import Card from "@material-ui/core-v3/Card/Card";
import CardContent from "@material-ui/core-v3/CardContent/CardContent";
import PropTypes from "prop-types";
import get from 'lodash.get';
import RowRenderer from './RowRenderer'
import moment from 'moment';
// stub now
import Typography from "@material-ui/core-v3/Typography/Typography";
import {
    mapAtrributeToName,
    mapLprCallsignToOrigin,
    mapLprStatusToDescription
} from "../utils/aircraftAttributeNameMapper";

const VISIBLE_ATTRIBUTES = ['altitude', 'callSign', 'speed', 'teamName', 'status'];

const styles = {
    blueCard: {
        backgroundColor: "rgb(21, 101, 192)",
        color: "rgba(255, 255, 255, 0.87)",
    },
    card: {
        width: "100%",
        marginBottom: "2em",
    },

    layoutRow: {
        display: "flex",
        flexDirection: "row",
    },

    dots: {
        flex: 1,
        minWidth: 0,
        '&:before': {
            color: "#888",
            marginLeft: "5px",
            marginRight: "5px",
            display: "block",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "clip",
            content: "'. . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .'"
        }
    },
};

export const RowItem = ({name, value, classes = {}}) => (
<div className={classes.layoutRow}>

    <Typography variant="inherit">{name}:</Typography>
    <div className={classes.dots} />
    <Typography variant="inherit">{value}</Typography>
</div>
)

class RtdmSoapLprUnitsTickView extends React.Component {

    render() {
        const {classes, inspectData} = this.props;
        console.log('RtdmSoapLprUnitsTickView props', this.props);
        console.log('rtdmsoaplprunit tick ', inspectData);

        let listItems = [];

        if (inspectData.tick) {
            for (const [key, value] of Object.entries(inspectData.tick)) {
                //console.log(`${key} ${value}`); // "a 5", "b 7", "c 9"
                if(!VISIBLE_ATTRIBUTES.includes(key)) {
                    continue;
                }
                let fixedValue = (key!=='timestamp')?String(value):moment(Math.round(value*1000)).utc().format();
                listItems.push(<RowItem key={key} name={mapAtrributeToName(key)} value={fixedValue} classes={this.props.classes}/>);
                if(key === 'teamName' && value.includes('Ratownik')) {
                    listItems.push(<RowItem key={'Origin'} name={'Origin'} value={mapLprCallsignToOrigin(value)} classes={this.props.classes}/>);
                }
                if(key === 'status') {
                    listItems.push(<RowItem key={'sDsc'} name={'Status description'} value={mapLprStatusToDescription(value)} classes={this.props.classes}/>);
                }
            }
        }

        return (
            <div>
                <Card className={classes.card}>
                    <CardContent className={classes.blueCard}>
                        {/*{JSON.stringify(inspectData,null, 2)}*/}
                        {inspectData.inspectType}
                    </CardContent>
                    <CardContent>
                        {listItems}
                    </CardContent>
                </Card>

            </div>
        )
    }
}

export default withStyles(styles)(RtdmSoapLprUnitsTickView);
