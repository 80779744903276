import React from 'react';
import { withStyles } from '@material-ui/core-v3/styles';
import {Component} from 'react'
import OperationTab from './OperationTab'
import ContextTab from './ContextTab'
import AppBar from "@material-ui/core-v3/AppBar/AppBar";
import Tabs from "@material-ui/core-v3/Tabs/Tabs";
import Tab from "@material-ui/core-v3/Tab/Tab";
import SwipeableViews from 'react-swipeable-views';
import Typography from "@material-ui/core-v3/Typography/Typography";
import Icon from "@material-ui/core-v3/Icon/Icon";
import ConfigTab from "./ConfigTab";
import PubSub from "pubsub-js";
import {connect} from "react-redux";
import compose from 'recompose/compose';
import {debounce} from "lodash";

import {PubSubSignals} from '../../LogicV1Redux/constants/PubSubSignals'
import {InspectTypes} from "../../modules2lib/common/";
import LandNowDialog from "./prepared-components/LandNowDialog";
import CancelAlertModal from "./prepared-components/CancelAlertModal";

const OPERATION_AUTO_CHANGE_TIME = 30000;

const styles = {
    root: {
        backgroundColor: "#f5f5f5",
        height: "100%",
        display: "flex",
        flexDirection: "column"
    },
    tab: {
        height: "100%"
    },
    swipeableViews: {
        maxHeight: "100%",
        height: "100%"
    },
    tabContainer: {
        padding: "0.5rem",
        height: "calc(100% - (16px))"
    }
};


function TabContainer({ children, dir, classes}) {
    return (
        <Typography component="div" dir={dir} className={classes.tabContainer} >
            {children}
        </Typography>
    );
}

//we don;t want to switch to context if type is not supported,
const inspectableWhitelist = [
    InspectTypes.CUSTOM_ALERT_V1,
    InspectTypes.CUSTOM_ALERT_V2,
    InspectTypes.CHECKINV1,
    InspectTypes.CHECKINV2,
    InspectTypes.MISSIONV2,
    InspectTypes.ZONEV1_FEATURE,
    InspectTypes.LPR_HELIPAD_V1,
    InspectTypes.LIVE_ADSB,
    InspectTypes.ZONEV2,
    InspectTypes.RTDM_EXCHANGE_AIRCRAFT,
    InspectTypes.RTDM_EXCHANGE_TICK,
    InspectTypes.RTDM_CREOTECH_AIRCRAFT,
    InspectTypes.RTDM_CREOTECH_TICK,
    InspectTypes.RTDM_SOAP_LPR_UNITS_TICK,
    InspectTypes.RTDM_AEROBITS_TICK,
    InspectTypes.MISSIONLEGFIXME, //fixme
];


class AtcTabs extends Component {

    state = {
        tabIdx: 0, lastInspectData: null
    };


    _startAutoChangeTimer = debounce(
        () => {
            if(this.state.tabIdx !== 0) {
                this._handleChangeIndex(0);
            }
        },
        OPERATION_AUTO_CHANGE_TIME
    );


    _handleChange = (event, value) => {
        this._handleChangeIndex(value);
    };


    _handleChangeIndex = index => {
        if(index === 0) {
            PubSub.publish(PubSubSignals.MAP_VIEWPORT_SET_HOME);
        }

        if(index!==0) {
            this._startAutoChangeTimer()
        }

        this.setState({tabIdx: index})
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.inspectData && prevState.lastInspectData !== this.props.inspectData) {
            const changeToInspectTab = inspectableWhitelist.includes(this.props.inspectData.inspectType);
            this.setState({
                lastInspectData: this.props.inspectData
            });
            if(changeToInspectTab) {
                this._handleChangeIndex(1)
            }
        }
    }

    componentDidMount() {
        this.pubsubShowOperationTab = PubSub.subscribe(PubSubSignals.SHOW_OPERATION_TAB, () => {
            this._handleChangeIndex(0);
        });
    }

    componentWillUnmount() {
        PubSub.unsubscribe(this.pubsubShowOperationTab);
    }

    render() {
        const {classes, theme, inspectData} = this.props;
        console.log('===AtcTabs render', this.state);

        return (
            <div className={classes.root}>
                <AppBar position="static" color="default">
                    <Tabs
                        value={this.state.tabIdx}
                        onChange={this._handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        fullWidth
                    >
                        <Tab className={classes.tab} label="OPERATION" />
                        <Tab className={classes.tab} label="CONTEXT" />
                        <Tab className={classes.tab} icon={<Icon>settings</Icon>}/>
                    </Tabs>
                </AppBar>
                <SwipeableViews
                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                    index={this.state.tabIdx}
                    onChangeIndex={this._handleChangeIndex}
                    className={classes.swipeableViews}
                    containerStyle={{maxHeight: "100%", height: "100%"}}
                >
                    <TabContainer dir={theme.direction} {...{classes}}><OperationTab/></TabContainer>
                    <TabContainer dir={theme.direction} {...{classes}}>
                        <ContextTab inspectData={inspectData}/>
                    </TabContainer>
                    <TabContainer dir={theme.direction} {...{classes}}><ConfigTab/></TabContainer>
                </SwipeableViews>

                <LandNowDialog/>
                <CancelAlertModal/>
            </div>
        )
    };
}

AtcTabs.propTypes = {
    //FIXME
    //children: PropTypes.node.isRequired,
    //dir: PropTypes.string.isRequired,
};

const mapStateToProps = (state, props) => {
    //console.log('AtcTabs mapStateToProps', state, props);
    return {
        //myAuth : state.myAuth,
        //mySelf : state.mySelf,
        //myAppExtras : state.myAppExtras,
        inspectData: state.inspectData,
    }
};

const enhance = compose(
    //translate,
    withStyles(styles, {withTheme: true}),
    connect(mapStateToProps)
);

export default enhance(AtcTabs);
