import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core-v3/styles';
import Typography from '@material-ui/core-v3/Typography';
import Modal from '@material-ui/core-v3/Modal';
import Button from '@material-ui/core-v3/Button';
import IconButton from '@material-ui/core-v3/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import PubSub from "pubsub-js";

const styles = theme => ({
    paper: {
        position: 'absolute',
        width: theme.spacing.unit * 50,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing.unit * 4,
    },
    closeButton: {
        position: 'absolute',
        right: '0px',
        top: '0px',
    },
    rowSpacing: {
        margin: theme.spacing.unit
    },
});


class MyModal extends React.Component {

    state = {
        open: false,
    };

    _rand = () => {
        return Math.round(Math.random() * 20) - 10;
    };

    _getModalStyle = () => {
        const top = 50 + this._rand();
        const left = 50 + this._rand();

        return {
            top: `${top}%`,
            left: `${left}%`,
            transform: `translate(-${top}%, -${left}%)`,
        };
    };

    _handleCancel = () => {
        this.setState({ open: false });
        this.props.onCancel();
    };

    _handleAccept = () => {
        this.setState({ open: false });
        this.props.onAccept();
    };

    componentWillMount() {
        this.pubsubListener = PubSub.subscribe(this.props.pubSubMessageListener, (msg, data) => {
            this.setState({
                open: true,
            });
        });
    }

    componentWillUnmount() {
        PubSub.unsubscribe(this.pubsubListener);
    }

    render() {
        const { classes, title, description } = this.props;

        return (
            <div>
                <Modal
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={this.state.open}
                    onClose={this._handleCancel}
                >
                    <div style={this._getModalStyle()} className={classes.paper}>
                        <IconButton
                            key="close"
                            aria-label="Close"
                            color="inherit"
                            className={classes.closeButton}
                            onClick={this._handleCancel}
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="title" id="modal-title" className={classes.rowSpacing}>
                            {title}
                        </Typography>
                        <Typography variant="subheading" id="simple-modal-description" className={classes.rowSpacing}>
                            {description}
                        </Typography>
                        <div className={classes.rowSpacing}>
                            <Button onClick={this._handleCancel}>
                                NO
                            </Button>
                            <Button onClick={this._handleAccept}>
                                YES
                            </Button>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}


MyModal.propTypes = {
    classes: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    onCancel: PropTypes.func,
    onAccept: PropTypes.func,
    pubSubMessageListener: PropTypes.string
};

MyModal.defaultProps = {
    onCancel: () => {},
    onAccept: () => {},
    pubSubMessageListener: null
};


export default withStyles(styles)(MyModal);