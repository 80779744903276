import * as React from "react";
import Button from "@material-ui/core-v3/Button/Button";
import Typography from "@material-ui/core-v3/Typography/Typography";
import ExpandMoreIcon from '@material-ui/icons-v3/ExpandMore';
import Collapse from "@material-ui/core-v3/Collapse/Collapse";
import classnames from 'classnames';
import withStyles from "@material-ui/core-v3/styles/withStyles";


const styles = theme => ({
    collapseDetails: {
        willChange: "content"
    },
    expand: {
        transform: 'rotate(0deg)',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
        [theme.breakpoints.up('sm')]: {
            marginRight: -8,
        },
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    collapseButton: {
        padding: "unset",
        paddingRight: "8px",
        willChange: "transform"
    },
});

class MyCollapse extends React.Component {

    state = {
        isExpanded: false
    };

    _handleExpandClick = () => {
        this.setState({
            isExpanded: !this.state.isExpanded
        });
    };

    render() {
        const {isExpanded} = this.state;
        const {children, title, classes, style} = this.props;

        return (
        <div className={classes.collapseDetails} style={style}>

            <Button
                onClick={this._handleExpandClick}
                aria-expanded={isExpanded}
                aria-label="Show more"
                size="small"
                className={classes.collapseButton}
            >
                <Typography>
                    {title}
                </Typography>
                <ExpandMoreIcon
                    className={classnames(classes.expand, {
                        [classes.expandOpen]: isExpanded,
                    })}/>
            </Button>
            <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                {children}
            </Collapse>
        </div>
        )
    }
}

export default withStyles(styles)(MyCollapse)