import React from "react";
import {connect} from "react-redux";
import {compose} from 'recompose';

const PureCallsign = ({callsign, floorNumbering}) => {
    const _callsignParts =  (callsign && callsign.includes(':')) ? callsign.split(":") : null;

    return _callsignParts ?
            <>
                {_callsignParts[0]}
                {
                    floorNumbering ?
                        <span style={{
                            display: "inline-block",
                            fontSize: "0.8rem",
                            marginLeft: "0.2rem",
                            fontWeight: "600"
                        }}>
                            {
                                _callsignParts.map(
                                    (part, idx) => {
                                        if (idx > 0) return <div key={idx}>{part}</div>
                                    }
                                )
                            }
                        </span>
                        :
                        <span style={{fontSize: "1.2rem", fontWeight: "600"}}>
                            {callsign.slice(callsign.indexOf(':'))}
                        </span>
                }
            </>
            :
            callsign;
};

const mapStateToProps = (state) => {
    return {
        floorNumbering: state.mapUxConfig.uiCallsignFloorNumberingEnabled
    }
};

export const Callsign = compose(
    connect(
        mapStateToProps,
        null
    )
)(PureCallsign);
