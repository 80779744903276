//old atc functionality
//ugh, rewritten roughly from angular (v1.0)

import MySocketIoV1Client from './MySocketIoV1Client'
import {getPandoraUser} from "./dirtyAuth";
import {deserializePrimitive, serializePrimitive} from "../../gl_map/glSetups/deckPrimitives/DeckPrimitiveSerialization";

export const AlertTypes = {
    CUSTOM_ALERT: 'CUSTOM_ALERT', //*almost* the same as inspecttype, huh
    OWNED_ALERT: 'OWNED_ALERT'
};


//buu helper for now.
// export function getOwnedAreaName() {
//     const user = getPandoraUser();
//     return user.options.area;
// }

//huh
export const radioValueTxts = {
    'LPR': "LPR/HEMS ALARM",
    'CTR': "CTR ALARM"
};

//huh x2, iirc this is identifier used somewhere in fcm messaging
const radioValueSubtypes = {
    'LPR': {
        [AlertTypes.OWNED_ALERT]:"namedZoneAlertLPR",
        [AlertTypes.CUSTOM_ALERT]:"customAreaAlertLPR"
    },
    'CTR': {
        [AlertTypes.OWNED_ALERT]:"namedZoneAlertCTR",
        [AlertTypes.CUSTOM_ALERT]:"customAreaAlertCTR"
    }
};

const emitSignals = {
    [AlertTypes.OWNED_ALERT]:"setOwnedAreaAlert",
    [AlertTypes.CUSTOM_ALERT]:"setCustomAreaAlert"
};


export function cancelAlert({alertData}) {
    console.log('cancel alert here', alertData);
    const userData = getPandoraUser();
    const alertType = alertData.type;
    const emitSignal = emitSignals[alertType];
    //should be cleaned up , but since we are going to switch to new db.. later
    MySocketIoV1Client.emit(emitSignal, {user:userData, status:0, alert:alertData});
}

//CUSTOM_ALERT - fire and forget, custom geometry,
//OWNED_ALERT - named zone , cancel call required
//default Status -4 = BAN
export function emitAlert({alertData, timeout, radioValue, status = -4}) {
    const userData = getPandoraUser();
    const alertType = alertData.type;

    const emitSignal = emitSignals[alertType];
    const alertSubtype = radioValueSubtypes[radioValue][alertType];
    const alertTxt = radioValueTxts[radioValue];

    const extras = {
        alertSubtype,
        alertTxt,
        timeout, //minutes
        isTest: false //(can be removed? to check out with mobile app )
    };


    //huh, copying functionality from angular..
    if (alertType === AlertTypes.OWNED_ALERT) {
        extras.amslHeight = 1000; //m
    } else if (alertType === AlertTypes.CUSTOM_ALERT) {
        const primitive = alertData.primitive;
        console.log('primitive', primitive);
        //convert to wkt, fixme -> backend should support wkb.
        const toWKT = true;
        const serializedPrimitive = serializePrimitive(primitive, toWKT);
        console.log('serializedPrimitive', serializedPrimitive);
        extras.wkt = serializedPrimitive.wkt;
    }

    const alert = {extras:extras};

    console.log('emitAlert', emitSignal, status, alertSubtype);

    console.log('==== emit here fixme', {user:userData, status:status, alert:alert});

    //mySocket.emit('setCustomAreaAlert', {user:userData, status:status, alert:alert});
    //mySocket.emit('setOwnedAreaAlert', {user:userData, status:status, alert:myAlert});

    MySocketIoV1Client.emit(emitSignal, {user:userData, status:status, alert:alert});
}

export default {
    emitAlert,
    cancelAlert
}
