import React from "react";
import {RowList} from "./RowList";
import {BarsConstants} from "../../../LogicV1Redux/constants/BarsConstants";
import {sortBars} from "./BarsController";

export class InFlightListView extends React.Component {


    render() {
        const { items } = this.props;


        return (
            <RowList
                visibleItems={
                    items.filter(
                        item =>
                            item.props.type === BarsConstants.WITH_RESTRICTIONS_CHECKIN_INFLIGHT ||
                            item.props.type === BarsConstants.UNKNOWN_STATUS_CHECKIN ||
                            item.props.type === BarsConstants.CONTROLLED_CHECKIN_INFLIGHT_OVERDUED
                    ).sort(sortBars)
                }
                hiddenItems={
                    items.filter(
                        item =>
                            item.props.type === BarsConstants.NO_RESTRICTIONS_CHECKIN_INFLIGHT ||
                            item.props.type === BarsConstants.WITH_RESTRICTIONS_CHECKIN_INFLIGHT_ACM_ACCEPTED
                    ).sort(sortBars)
                }
            />
        )
    }
}
