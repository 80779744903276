import * as React from "react";
import {isMissionEnded, isMissionOnBlock, isMissionUnderWay,} from "../../../LogicV1Redux/oldLogic/MissionsListV2";
import MissionRowRenderer from "./MissionRowRenderer";
import {BarsConstants, BarsPriority} from "../../../LogicV1Redux/constants/BarsConstants";
import CheckInRowRendererBase from "./CheckInRowRenderer";
import moment from "moment";
import {InspectTypes} from "../../../modules2lib/common/";
import {showOnMap, storeInspectData} from "../../../LogicV1Redux/actions/InspectActions";
import connect from "react-redux/es/connect/connect";
import compose from 'recompose/compose'
import {callsignGenerator} from "../../../callsignGenerator/callsignGenerator";
import {FlightStatusesV2} from "../../../LogicV1Redux/constants/CheckinsV2Enums";
import {AppConfig} from "../../AppRtMap";
import SoundFXController from "./SoundFXController";
import {storeSelectedMission} from "../../../LogicV1Redux/actions/MissionsV2Actions";

export const PlannedBarsContext = React.createContext(null);
export const OnBlockBarsContext = React.createContext(null);
export const InFlightBarsContext = React.createContext(null);

export function isCheckinInFlightSlot(checkin) {
    return !checkin.__isBeforeStart && !checkin.__isAfterStop;
}

export function isCheckinAfterFlightSlot(checkin) {
    return checkin.__isAfterStop;
}

export function isAcmTouchedOnlyCheckin(checkin) {
    const {govMsgs} = checkin.checkinRecord;
    return govMsgs && govMsgs.length === 1 && govMsgs[0].issuerEmail === 'acm@pansa.pl';
}

export function isAcmTouchedOnlyMission(mission) {
    const {govMsgs} = mission.missionRecord;
    return govMsgs && govMsgs.length === 1 && govMsgs[0].issuerEmail === 'acm@pansa.pl';
}

export function sortBars(previousBar, nextBar) {
    console.log("sort bars", previousBar, nextBar);

    const {type: nType, checkin: nCheckin = {}} = nextBar.props;
    const {__status: nStatus = ""} = nCheckin;

    const {type: pType, checkin: pCheckin = {}} = previousBar.props;
    const {__status: pStatus = ""} = pCheckin;

    let pValue = Infinity, nValue = Infinity;

    for (let [key, value] of Object.entries(BarsPriority)) {
        // bar type has priority over checkin status
        if(pType === key) {
            pValue = value;
            break;
        }
        if(pStatus === key) {
            pValue = value;
            break;
        }

    }

    for (let [key, value] of Object.entries(BarsPriority)) {
        if(nType === key) {
            nValue = value;
            break;
        }
        if(nStatus === key) {
            nValue = value;
            break;
        }
    }

    return pValue - nValue;
}

class BarsController extends React.Component {
    state = {
        connectedMissions: new Set(),
        connectedCheckins: new Set(),
        connections: new Map(),
        planned: [],
        onblock: [],
        inflight: [],

        missionCallsigns: new Map(),
        checkinCallsigns: new Map(),
        approvalToMissionId: new Map(),
        nameFromCheckinToCallsignInfix: new Map(),
        anonymCounter: 1,
    };

    _isMissionConnected = (mission) => {
        const {connectedMissions} = this.state;
        return connectedMissions.has(mission.missionRecord.uid);
    };

    _isCheckInConnected = (checkin) => {
        const {connectedCheckins} = this.state;
        return connectedCheckins.has(checkin.checkinRecord.uid);
    };

    _isWithRestrictionsCheckInOnBlock = (checkin) => {
        const {status} = checkin.checkinRecord;
        return (
            status === FlightStatusesV2.IN_QUEUE        ||
            status === FlightStatusesV2.REJECTED        ||
            status === FlightStatusesV2.REJECTED_ACK    ||
            (
                checkin.__isBeforeStart
                &&
                (status === FlightStatusesV2.ATC_MODIFIED || status === FlightStatusesV2.ATC_MODIFIED_ACK)
            )
                ||
            (
                checkin.__isBeforeStart
                &&
                (status === FlightStatusesV2.ACCEPTED || status === FlightStatusesV2.ACCEPTED_ACK)
                &&
                isAcmTouchedOnlyCheckin(checkin)
            )
        );
    };

    _isNoRestrictionsCheckInOnBlock = (checkin) => {
        return checkin.__isBeforeStart && checkin.checkinRecord.status === FlightStatusesV2.CREATED;
    };

    _isCheckInToMissionOverdued = (checkin) => {
        //if checkin is not connected skip
        if(!this._isCheckInConnected(checkin)) return false;

        const {connections} = this.state;

        const {uid} = checkin.checkinRecord;
        const missionId = connections.get(uid);
        const mission = this._getMissionById(missionId);


        console.log("BC mission check", mission);

        const missionStopTime = mission.missionRecord.stop;

        console.log("BC after", moment().isAfter(moment(missionStopTime)), missionStopTime );
        return (
            moment().isAfter(moment(missionStopTime))
        );
    };

    _isControlledCheckinOverdued = (checkin) => {
        const {ctrl} = checkin.checkinRecord;
        return ctrl && isCheckinAfterFlightSlot(checkin);
    };

    _isMissionInConnections = (missionId) => {
        const {connections} = this.state;
        for(const uid of connections.values()) {
            if(uid === missionId) {
                return true;
            }
        }
        return false;
    };

    _updateConnections = (checkin) => {
        const {connections, connectedCheckins, connectedMissions} = this.state;

        console.log("Connections", connections, connectedMissions, connectedCheckins);

        //check if connected-checkins are overdued
        if(this._isCheckInConnected(checkin)) {
            // connected and not controlled
            if(checkin.__isAfterStop && !checkin.checkinRecord.ctrl) {

                // delete connected missions
                //TODO support for multi drone mission
                const checkinId = checkin.checkinRecord.uid;
                const missionId = connections.get(checkinId);

                connectedCheckins.delete(checkinId);
                connections.delete(checkinId);

                if(!this._isMissionInConnections(missionId)) {
                    console.log("Bars delete mission from connections", missionId, Array.from(connections.values()));
                    connectedMissions.delete(missionId);
                }
                console.log("Bars cleaning connections end", connections, connectedMissions, connectedCheckins);
            }
            return;
        }

        //if checkin is after and is not controlled (OVD case) now don't connect
        if(checkin.__isAfterStop && !checkin.checkinRecord.ctrl) return;

        const missionId = checkin.checkinRecord.missionapproval ? checkin.checkinRecord.missionapproval : null;
        //hack for droniada
        // const approvalId = checkin.properties.device_info.approvalId ? checkin.properties.device_info.approvalId : null;
        // if(!!approvalId) {
        //     missionId = approvalId;
        // }


        //if has missionId find connected-mission and connect
        if(missionId) {
            const connectedMission = this.props.missionsV2.rawData.find(primitive => primitive.missionRecord.approval === missionId);
            if(connectedMission) {
                connections.set(checkin.checkinRecord.uid, connectedMission.missionRecord.uid);
                connectedMissions.add(connectedMission.missionRecord.uid);
                connectedCheckins.add(checkin.checkinRecord.uid);
            }
        }
        console.log("Bar connections", connections, connectedCheckins, connectedMissions)
    };

    //clean bindings for cancelled and landed checkins
    //TODO I think it would be better to pass this states to pandora instead of removal from checkinsV1
    _cleanLooseBindings = (activeCheckinsId) => {
        const {connections, connectedCheckins, connectedMissions} = this.state;

        const connectedCheckinsKeys = Array.from(connections.keys());

        for(const checkinId of connectedCheckinsKeys) {

            if(!activeCheckinsId.includes(checkinId)) {
                const missionId = connections.get(checkinId);
                connectedCheckins.delete(checkinId);
                connections.delete(checkinId);
                if(!this._isMissionInConnections(missionId)) {
                    console.log("Bars delete mission from connections in loose bindings", missionId, Array.from(connections.values()));
                    connectedMissions.delete(missionId);
                }
                console.log("Bars cleaning loose bindings", checkinId, missionId);
            }
        }
    };

    _getAllInflatedCallsigns = () => {
        const {missionCallsigns, checkinCallsigns} = this.state;
        return [...Array.from(checkinCallsigns.values()), ...Array.from(missionCallsigns.values())];
    };


    _isCallsignInflated = (callsign) => {
        return this._getAllInflatedCallsigns().includes(callsign);
    };

    _getNextAnonymCount = () => {
        const {anonymCounter} = this.state;
        this.setState({
            anonymCounter: anonymCounter+1
        });
        return anonymCounter;
    };

    _generateUniqueCallsign = ({firstName, lastName}) => {
        for(const callsign of callsignGenerator({firstName, lastName})) {
            if(!this._isCallsignInflated(callsign)) return callsign;
        }
    };

    _updateMissionCallsignMap = (mission) => {

            const {missionCallsigns, approvalToMissionId} = this.state;
            const {uid, approval} = mission.missionRecord;

            //if mission doesn't have callsign generate
            if (!missionCallsigns.has(uid)) {
                const name = mission.missionRecord.operator.displayName.split(" ");
                const firstName = name[0];
                const lastName = name[1];
                try {
                    const newCallsign = this._generateUniqueCallsign({firstName, lastName});
                    missionCallsigns.set(uid, newCallsign);
                    approvalToMissionId.set(approval, uid);
                }
                catch (e) {
                    const newCallsign = 'ANONYM' + this._getNextAnonymCount();
                    missionCallsigns.set(uid, newCallsign);
                    approvalToMissionId.set(approval, uid);
                }

            }

    };

    _updateCheckinsCallsignMap = (checkin) => {

            const {checkinCallsigns, approvalToMissionId, missionCallsigns, nameFromCheckinToCallsignInfix} = this.state;
            const approval = checkin.checkinRecord.missionapproval;
            //hack for droniada
            // let approvalId = checkin.properties.device_info.approvalId;
            // if(!!approvalId) {
            //     approval = approvalId;
            // }

            const {uid, operator} = checkin.checkinRecord;

            //if checkin doesn't have callsign and valid approval no other drones in air generate new one
            if (!checkinCallsigns.has(uid) && !approvalToMissionId.has(approval) && !nameFromCheckinToCallsignInfix.has(operator)) {
                const name = operator.split(" ");
                const firstName = name[0];
                const lastName = name[1];
                try {
                    const newCallsign = this._generateUniqueCallsign({firstName, lastName});
                    checkinCallsigns.set(uid, newCallsign + "01");
                    nameFromCheckinToCallsignInfix.set(operator, newCallsign);
                }
                catch (e) {
                    const newCallsign = 'ANONYM' + this._getNextAnonymCount();
                    checkinCallsigns.set(uid, newCallsign + "01");
                    nameFromCheckinToCallsignInfix.set(operator, newCallsign);
                }
            }

            //if checkin doesn't have callsign and valid approval but other drones in air get inflated checkin
            else if (!checkinCallsigns.has(uid) && !approvalToMissionId.has(approval) && nameFromCheckinToCallsignInfix.has(operator)) {
                const callsignFromName = nameFromCheckinToCallsignInfix.get(operator);
                let callsign;
                let counter = 0;
                while(true) {
                    callsign = callsignFromName + "0" + ++counter;
                    if(!this._isCallsignInflated(callsign)) break;
                }
                checkinCallsigns.set(uid, callsign);
            }

            //if checkin doesn't have callsign but has has valid approval, get missions callsign
            else if (!checkinCallsigns.has(uid) && approvalToMissionId.has(approval)) {
                const missionId = approvalToMissionId.get(approval);
                const missionCallsign = missionCallsigns.get(missionId);
                let callsign;
                let counter = 0;
                while(true) {
                    callsign = missionCallsign + "0" + ++counter;
                    if(!this._isCallsignInflated(callsign)) break;
                }
                checkinCallsigns.set(uid, callsign);
            }

    };

    _getMissionById = (uid) => {
        const {rawData: missions} = this.props.missionsV2;
        console.log("BC get mission by id", uid, missions, missions.find(mission=>mission.missionRecord.uid === uid), this.props.missionsV2);
        return missions.find(mission=>mission.missionRecord.uid === uid);

    };

    _isOutsideJurisdictionCheckinConnected = (checkin) => {
        //TODO this is a logic hack, it would be better if this check is in checkinsV2list or somewhere higher
        // see CheckinV2View const hasJurisdiction notes
        const {missionsV2} = this.props;
        const connectedMission = missionsV2.rawData.find(
            el =>
                checkin.checkinRecord.missionid !== null &&
                el.missionRecord.uid === checkin.checkinRecord.missionid
        );
        return connectedMission && connectedMission.__inJurisdiction;
    };

    componentDidUpdate(previousProps, prevoiusState, prevoiusContext) {
        if(previousProps.tick !== this.props.tick) {
            const {missionCallsigns, checkinCallsigns} = this.state;

            const planned = [];
            const onblock = [];
            const inflight = [];

            const activeCheckinsId = [];

            const {checkinsV1, checkinsV2, selectCheckinAction, missionsV2, selectMissionAction} = this.props;

            //main mission bars logic
            for (const mission of missionsV2.rawData) {
                console.log("INSPECT DATA mission", mission);
                if(!mission.__inJurisdiction) continue;

                let callsign;
                if(AppConfig.callsignEngineStatic) {
                    callsign = mission.missionRecord.callsign
                }
                else {
                    this._updateMissionCallsignMap(mission);
                    callsign = missionCallsigns.get(mission.missionRecord.uid) + "00";
                }


                if (!isMissionOnBlock(mission.missionRecord) &&
                    !isMissionEnded(mission.missionRecord) &&
                    !this._isMissionConnected(mission)
                ) {

                    const modifiedMission = {
                        ...mission,
                        missionRecord: {
                            ...mission.missionRecord,
                            status: 'PREP',
                        }
                    };

                    console.log("Bars controller pushing planned mission", mission);
                    planned.push(
                        <MissionRowRenderer key={mission.missionRecord.uid}
                                            mission={modifiedMission}
                                            selectMissionAction={selectMissionAction}
                                            type={BarsConstants.MISSION_PLANNED}
                                            callsign={callsign}
                                            selectedMissionUid={missionsV2.selectedMissionUid}
                        />
                    )
                }
                else if (isMissionOnBlock(mission.missionRecord) && !this._isMissionInConnections(mission.missionRecord.uid)) {

                    const modifiedMission = {
                        ...mission,
                        missionRecord: {
                            ...mission.missionRecord,
                            status: 'PREP',
                        }
                    };

                    console.log("Bars controller pushing onblock mission", modifiedMission);
                    onblock.push(
                        <MissionRowRenderer key={mission.missionRecord.uid}
                                            mission={modifiedMission}
                                            selectMissionAction={selectMissionAction}
                                            type={BarsConstants.MISSION_ONBLOCK}
                                            callsign={callsign}
                                            selectedMissionUid={missionsV2.selectedMissionUid}
                        />
                    )
                }
            }

            //main checkins bars logic
            // const callsignCounters = new Map();

            for (const checkinBundle of checkinsV2.rawData) {
                console.log("INSPECT DATA checkin", checkinBundle);
                const checkin = checkinBundle.inspectData;

                const isOutsideJurisdictionConnected = this._isOutsideJurisdictionCheckinConnected(checkin);
                if(!checkin.__inJurisdiction && !isOutsideJurisdictionConnected) continue;

                //add active checkins for further binding checks
                activeCheckinsId.push(checkin.checkinRecord.uid);

                //connect or disconnect checkin logic
                this._updateConnections(checkin);

                let callsign;
                if(AppConfig.callsignEngineStatic) {
                    callsign = checkin.checkinRecord.callsign;
                }
                else {
                    this._updateCheckinsCallsignMap(checkin);
                    callsign = checkinCallsigns.get(checkin.checkinRecord.uid);
                }
                // let callsign;
                // const count = callsignCounters.get(callsignInfix);
                //
                // if(count) {
                //     callsignCounters.set(callsignInfix, count+1);
                //     callsign = callsignInfix + "0" + (count+1);
                // }
                // else {
                //     callsignCounters.set(callsignInfix, 1);
                //     callsign = callsignInfix + "0" + 1;
                // }

                const CheckInRowRenderer =
                    <CheckInRowRendererBase
                        key={checkin.checkinRecord.uid}
                        missionsV2={missionsV2}
                        checkin={checkin}
                        selectCheckinAction={selectCheckinAction}
                        callsign={callsign}
                        lostControlReviews={checkinsV2.lostControlReviews}
                        selectedCheckinUid={checkinsV2.selectedCheckinV2 ? checkinsV2.selectedCheckinV2.checkinRecord.uid : null}
                    />;

                if (this._isNoRestrictionsCheckInOnBlock(checkin)) {
                    console.log("Bars controller pushing onblock no restrictions", checkin);
                    onblock.push(
                        React.cloneElement(
                            CheckInRowRenderer,
                            {type: BarsConstants.NO_RESTRICTIONS_CHECKIN_ONBLOCK})
                    )
                }
                else if (this._isControlledCheckinOverdued(checkin) && this._isWithRestrictionsCheckInOnBlock(checkin)) {
                    console.log("Bars controller pushing onblock controlled checkin overdued", checkin);
                    onblock.push(
                        React.cloneElement(
                            CheckInRowRenderer,
                            {type: BarsConstants.CONTROLLED_CHECKIN_ONBLOCK_OVERDUED}
                        )
                    )
                }
                else if (this._isWithRestrictionsCheckInOnBlock(checkin) &&
                    (
                        checkin.checkinRecord.status === FlightStatusesV2.ACCEPTED ||
                        checkin.checkinRecord.status === FlightStatusesV2.ACCEPTED_ACK
                    ) &&
                    isAcmTouchedOnlyCheckin(checkin)
                ) {
                    console.log("Bars controller pushing onblock with restrictions connected checkin acm touched only", checkin);
                    onblock.push(
                        React.cloneElement(
                            CheckInRowRenderer,
                            {type: BarsConstants.WITH_RESTRICTIONS_CHECKIN_ONBLOCK_ACM_ACCEPTED}
                        )
                    )
                }
                else if (this._isWithRestrictionsCheckInOnBlock(checkin)) {
                    console.log("Bars controller pushing onblock with restrictions connected checkin", checkin);
                    onblock.push(
                        React.cloneElement(
                            CheckInRowRenderer,
                            {type: BarsConstants.WITH_RESTRICTIONS_CHECKIN_ONBLOCK}
                        )
                    )
                }
                else if (
                    this._isControlledCheckinOverdued(checkin)
                ) {
                    console.log("Bars controller pushing inflight controlled checkin overdued", checkin);
                    inflight.push(
                        React.cloneElement(
                            CheckInRowRenderer,
                            {type: BarsConstants.CONTROLLED_CHECKIN_INFLIGHT_OVERDUED}
                        )
                    )
                }
                else if (
                    isCheckinInFlightSlot(checkin) &&
                    (
                        checkin.checkinRecord.status === FlightStatusesV2.ACCEPTED ||
                        checkin.checkinRecord.status === FlightStatusesV2.ACCEPTED_ACK
                    ) &&
                    isAcmTouchedOnlyCheckin(checkin)
                ) {
                    console.log("Bars controller pushing inflight with restrictions checkin accepted and touched by ACM only", checkin);
                    inflight.push(
                        React.cloneElement(
                            CheckInRowRenderer,
                            {type: BarsConstants.WITH_RESTRICTIONS_CHECKIN_INFLIGHT_ACM_ACCEPTED}
                        )
                    )
                }
                else if (isCheckinInFlightSlot(checkin) && checkin.checkinRecord.status !== FlightStatusesV2.CREATED) {
                    console.log("Bars controller pushing inflight with restrictions checkin", checkin);
                    inflight.push(
                        React.cloneElement(
                            CheckInRowRenderer,
                            {type: BarsConstants.WITH_RESTRICTIONS_CHECKIN_INFLIGHT}
                        )
                    )
                }
                else if (isCheckinInFlightSlot(checkin) && checkin.checkinRecord.status === FlightStatusesV2.CREATED) {
                    console.log("Bars controller pushing inflight no restriction checkin", checkin);
                    inflight.push(React.cloneElement(
                        CheckInRowRenderer,
                        {type: BarsConstants.NO_RESTRICTIONS_CHECKIN_INFLIGHT}
                        )
                    )
                }
                else {
                    console.log("Bars controller pushing inflight unknown status bar", checkin);
                    inflight.push(React.cloneElement(
                        CheckInRowRenderer,
                        {type: BarsConstants.UNKNOWN_STATUS_CHECKIN}
                        )
                    )
                }
            }

            this._cleanLooseBindings(activeCheckinsId);

            this.setState({
                planned,
                onblock,
                inflight
            });

            console.log("Bars controller update end ======", planned, onblock,inflight);
        }
    }

    render() {
        const {children, checkinsV2} = this.props;
        return (
            <>
                <PlannedBarsContext.Provider value={this.state.planned}>
                    <OnBlockBarsContext.Provider value={this.state.onblock}>
                        <InFlightBarsContext.Provider value={this.state.inflight}>
                            {children}
                        </InFlightBarsContext.Provider>
                    </OnBlockBarsContext.Provider>
                </PlannedBarsContext.Provider>

                <SoundFXController checkins={checkinsV2} />
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        missionsV2 : state.missionsV2,
        checkinsV1 : state.checkinsV1,
        checkinsV2 : state.checkinsV2,
        tick: state.tick.ticks,
    }
};


const mapDispatchToProps = dispatch =>
    (
        {
            selectMissionAction : (el) => {
                const inspectData = {mission:el, inspectType:InspectTypes.MISSIONV2};
                console.log('dispatchin mission storeInspectData',el, inspectData);
                dispatch(storeInspectData(inspectData));
                dispatch(showOnMap(inspectData))
            },
            selectCheckinAction : (checkin, connectedMission) => {
                const inspectData = {checkinRecord: checkin.checkinRecord,  inspectType: InspectTypes.CHECKINV2, __latLng:checkin.__latLng}; //incoming geometry removed, hacking support (sprint)
                console.log('dispatchin checkin storeInspectData', checkin, inspectData);
                dispatch(storeInspectData(inspectData));
                if(connectedMission) {
                    dispatch(storeSelectedMission({selectedMissionUid: connectedMission.missionRecord.uid}));
                }
                dispatch(showOnMap(inspectData))
            },
        }
    );

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(BarsController);
