import React from "react";
import SvgToReactParser from "../utils/SvgToReactParser";
import PropTypes from "prop-types";
import SvgIcon from '@material-ui/core-v3/SvgIcon';


const svgIconScaleFactors = {
    mini: "scale(1 1)",
    small: "scale(1.875 1.875)",
    medium: "scale(2.2 2.2)",
    large: "scale(3.8 3.8)"
};

/**
 * This component creates a custom Svg Icon. Use the path prop to set the relative path to the icon (e.g. "img/icon.svg").
 * Use the prop transform to make some transformations (e.g. "scale(3.8 3.8)")
 * Optimized for 64x64px size icon and a 24x24px view box.
 */
class CustomizedSvgIcon extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            reactSvgBodyComponent: null
        };
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
       const {reactSvgBodyComponent} = nextState;
       if (reactSvgBodyComponent !== this.state.reactSvgBodyComponent) {
           return true;
       }
       return false;
    }

    componentWillMount() {
        let parser = new SvgToReactParser();
        parser.convertSvgBodyToReactComponent(this.props.path, (reactComponent) => {
            this.setState({
                reactSvgBodyComponent: reactComponent
            });
        });
    };

    _setIconSize() {
        if(this.props.transform === "")
            return svgIconScaleFactors[this.props.iconSize];
        else
            return this.props.transform;
    }

    _renderSvgIcon() {
        if(this.state.reactSvgBodyComponent)
            return (
                <SvgIcon transform={this._setIconSize()}>
                    {this.state.reactSvgBodyComponent}
                </SvgIcon>
            );
    }

    render() {
        return (
            <div>
                {this._renderSvgIcon()}
            </div>
        );
    }
}

CustomizedSvgIcon.propTypes = {
    transform: PropTypes.string,
    iconSize: PropTypes.string,
    path: PropTypes.string
};

CustomizedSvgIcon.defaultProps = {
    transform: "",
    iconSize: "mini",
    path: ""
};

export default CustomizedSvgIcon;