import {List} from "@material-ui/core-v3";
import React from "react";
import MyCollapse from "./MyCollapse";
import Divider from "@material-ui/core-v3/Divider/Divider";


const RowsHeader = () =>
    <table style={{width: "100%", tableLayout: "fixed"}}>
        <thead>
        <tr style={{textAlign: "left"}}>
            <th style={{width: "12%", overflow: "hidden"}}>A</th>
            <th style={{width: "35%", overflow: "hidden"}}>B</th>
            <th style={{width: "7%", overflow: "hidden"}}>C</th>
            <th style={{width: "7%", overflow: "hidden"}}>D</th>
            <th style={{width: "15%", overflow: "hidden"}}>E</th>
            <th style={{width: "12%", overflow: "hidden"}}>F</th>
            <th style={{width: "12%", overflow: "hidden"}}>G</th>
        </tr>
        </thead>
    </table>;

export const RowList = ({visibleItems, hiddenItems = []}) => (
    <div>
        {
            (hiddenItems.length > 0 || visibleItems.length > 0) && <RowsHeader/>
        }
        {
            hiddenItems.length > 0 &&
            <>
                <Divider/>
                <MyCollapse title={'Other check-ins'} style={{textAlign: 'right'}}>
                    <List>
                        {hiddenItems}
                    </List>
                </MyCollapse>
                <Divider/>
            </>
        }
        {
            visibleItems.length > 0 &&
            <>
                <List>
                    {visibleItems}
                </List>
            </>
        }

    </div>
);

