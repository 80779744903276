//v1.0.4


import {APP_MODES, urls} from '../../Configs'
import PubSub from 'pubsub-js'
import logger from './Logger'
import {PubSubSignals} from '../constants/PubSubSignals'

import StoreProvider from '../../dr_ra2/MyStoreProvider'
//import DataProvider from '../../dr_ra2/MyDataProvider'
import {fetchAllAlertsV1Rq} from '../actions/AlertsV1Actions'
import {InspectTypes} from "../../modules2lib/common/";
import {functionalColors} from "../../gl_map/glSetups/oldCommon/commonInits";

//deprecated! for maps only
// export function getAlerts() {
//     const store = StoreProvider.getStore();
//     const state = store.getState();
//     console.log('getAlerts', state);
//     return state.alertsV1;
// }


//default = temp? solution for old setups
export function processAlertsV1ToDeck(rawAlerts = []) {

    let icos = [], points = [], paths = [], polys = [], texts = [];

    //console.log('fixme -> process geom on load');

    // if (alerts[0])
    //     console.log('processAlerts', alerts[0]);

    // ended_by:null
    // extras:{alertSubtype: "namedZoneAlertLPR", alertTxt: "LPR/HEMS ALARM", amslHeight: 1000, isTest: false}
    // geojson:{coordinates: Array(1), type: "Polygon"}
    // id:789
    // started:1522284897.89835
    // started_by:"test"
    // status:-4
    // until:null
    // zone_name:"EA 48 Warszawa"

    console.log('rawAlerts', rawAlerts);


    rawAlerts.forEach((alert) => {

        let alertPoly = {};
        try {
            alertPoly.polygon = alert.geojson.coordinates;
            alertPoly.elevation = 10000 || alert.extras.amslHeight || 1000; //AGL/AMSL fixme!!
            alertPoly.fillColor = functionalColors.ban;
            alertPoly.inspectData = {
                alert,
                inspectType: InspectTypes.CUSTOM_ALERT_V1
            };

            alert.draft = true;
            polys.push(alertPoly);
        } catch (e) {
            console.error('error in rawAlerts', e.message);
            console.error('wrong alert data, skipping deck init', alert);
        }
    });

    return {icos, points, paths, polys, texts};
}


//call on tick update
//testing for outdated alerts is client-side based (reducing bandwidth)
//unixTime should be serverTime.
export function cleanOutdatedAlerts(rawData, tick) {

    let cleanedList = [];
    let dirty = false;
    //console.log('alertsStore/tick', alertsStore, tick);

    for (let i = 0; i < rawData.length; i++) {

        if ((!rawData[i].until) //can be null
            || (rawData[i].until > tick.unixTime)) {
            cleanedList.push(rawData[i]);
        }
        else {
            // no timeout in owned area, so not need yet... but fixme
            // if (alertsArr[i].zone_name === getOwnedAreaName()) {
            //     setOwnedAreaAlert(null);
            // }
            dirty = true;
        }
    }
    if (dirty) {
        console.log('removed alerts!');
    }

    return {isDirty:dirty, cleanedList };
}


