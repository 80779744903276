import React from "react";
import withStyles from "@material-ui/core-v3/styles/withStyles";
import Card from "@material-ui/core-v3/Card/Card";
import CardContent from "@material-ui/core-v3/CardContent/CardContent";
import PropTypes from "prop-types";
import get from 'lodash.get';
import {RowItem} from "./RtdmCreotechTick";
import moment from "moment";
import {connect} from "react-redux";
import compose from 'recompose/compose';
import {mapAtrributeToName} from "../utils/aircraftAttributeNameMapper";

const VISIBLE_ATTRIBUTES = ['Alt', 'Call', 'Sqk', 'Spd', 'Vsi'];

// stub now

const styles = {
    blueCard: {
        backgroundColor: "rgb(21, 101, 192)",
        color: "rgba(255, 255, 255, 0.87)",
    },
    card: {
        width: "100%",
        marginBottom: "2em",
    },

    layoutRow: {
        display: "flex",
        flexDirection: "row",
    },

    dots: {
        flex: 1,
        minWidth: 0,
        '&:before': {
            color: "#888",
            marginLeft: "5px",
            marginRight: "5px",
            display: "block",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "clip",
            content: "'. . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .'"
        }
    },
};

class RtdmExchangeAircraftView extends React.Component {

    render() {
        const {classes, inspectData} = this.props;
        console.log('RtdmExchangeAircraftView props', this.props);
        console.log('====rtdmaircraft ', inspectData);
        let listItems = [];

        if (inspectData.flight) {

            for (const [key, fixedValue] of Object.entries(inspectData.flight)) {
                //console.log(`${key} ${value}`); // "a 5", "b 7", "c 9"
                //let fixedValue = (key!=='timestamp')?value:moment(Math.round(value*1000)).utc().format();
                if(!VISIBLE_ATTRIBUTES.includes(key)) {
                    continue;
                }
                if  (key!=='timestamp') {
                    listItems.push(<RowItem key={'F_'+key} name={mapAtrributeToName(key)} value={fixedValue} classes={this.props.classes}/>);
                }
            }
        }
        if (inspectData.tick) {
            for (const [key, value] of Object.entries(inspectData.tick)) {
                //console.log(`${key} ${value}`); // "a 5", "b 7", "c 9"
                if(!VISIBLE_ATTRIBUTES.includes(key)) {
                    continue;
                }
                let fixedValue = (key!=='timestamp')?String(value):moment(Math.round(value*1000)).utc().format();

                listItems.push(<RowItem key={'T_'+key} name={mapAtrributeToName(key)} value={fixedValue} classes={this.props.classes}/>);
            }
        }
        return (
            <Card className={classes.card}>
                <CardContent className={classes.blueCard}>
                    {/*{JSON.stringify(inspectData,null, 2)}*/}
                    {inspectData.inspectType}
                </CardContent>
                <CardContent>
                    {listItems}
                </CardContent>
            </Card>

        )
    }
}


const mapStateToProps = (state, props) => {
    //console.log('AtcTabs mapStateToProps', state, props);
    return {
        //myAuth : state.myAuth,
        //mySelf : state.mySelf,
        //myAppExtras : state.myAppExtras,
        inspectData: state.inspectData,
        inspectTimestamp : state.inspectData.timestamp
    }
};

const enhance = compose(
    //translate,
    withStyles(styles),
    connect(mapStateToProps)
);

export default enhance(RtdmExchangeAircraftView);

//export default withStyles(styles)(RtdmExchangeAircraftView);
