import Switch from '@material-ui/core-v3/Switch';

import React, {Component} from "react";
import withStyles from "@material-ui/core-v3/styles/withStyles";
import {connect} from "react-redux";
import {compose} from 'recompose';
import {storeUiEntitiesVisibility, storeUiZonesV2EnableRq} from "../../LogicV1Redux/actions/MapUxConfigActions";

import {unixTimeNow} from "../../LogicV1Redux/oldLogic/TimeConverters";
import {VisibilityChip} from "./VisibilityChip";

const styles = theme => ({
    root: {

    },
    masterToggleContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexWrap: 'wrap',
    },
    chipContainer: {
        display: 'flex',
        justifyContent: 'left',
        flexWrap: 'wrap',
    },
    chip: {
        //margin: theme.spacing.unit,
        margin: '5px',
    },
});

class ZoneTypesVisibility extends Component {

    chipClickHandler(zoneType) {
        return (event) => {
            //console.log('cjip was cliked', zoneType);
            this.props.visibilityPrefs.zoneTypes[zoneType] = !this.props.visibilityPrefs.zoneTypes[zoneType];
            this.props.storeUiEntitiesVisibility({
                zoneTypes:this.props.visibilityPrefs.zoneTypes,
                zoneTypesTS:unixTimeNow()
            });
        }
    }

    render() {
        const {classes, visibilityPrefs, uiZonesV2Enabled} = this.props;

        //console.warn(this.props);

        //console.log('==vis change detected. ')
        if (!visibilityPrefs.zoneTypes) return null;

        const visChips = Object.entries(visibilityPrefs.zoneTypes).map((entry, idx) => {
            return VisibilityChip({
                key: idx,
                label: entry[0],
                active: entry[1],
                clickHandler: this.chipClickHandler(entry[0]),
                classes: classes
            });
        }).filter(el => el.props.label !== 'EA');
        //^ this filter is a nasty clean on special email request (15.10.19, Fwd: Wyświetlanie stref Pandora).
        // .. but in fact db should be cleaned.
        return (
            <>
            <div className={classes.masterToggleContainer}>
                <b>Zones Visibility</b>
                <Switch
                    checked={uiZonesV2Enabled}
                    onChange={() => this.props.storeUiZonesV2EnableRq(!uiZonesV2Enabled)}
                    value="checkedB"
                    color="primary"
                />
            </div>
                {(uiZonesV2Enabled) ?
                    <div className={classes.chipContainer}>
                        {visChips}
                    </div>
                    : null
                }
            </>
        );
    }
}



const mapStateToProps = (state, props) => {
    //console.log('ZoneTypesVisibility mapStateToProps', state, props);

    return {
        //mapUxConfig: Object.assign({},state.mapUxConfig),
        visibilityPrefs: state.mapUxConfig.visibilityPrefs,
        //myAppExtras: state.myAppExtras
        uiZonesV2Enabled: state.mapUxConfig.uiZonesV2Enabled
    }
};


const enhance = compose(
    //translate,
    withStyles(styles),
    connect(
        mapStateToProps,
        {
            storeUiEntitiesVisibility,
            storeUiZonesV2EnableRq
        },
        // null,
        // {
        //     areStatesEqual: (prev, next) => {
        //         console.log('comparing states', prev, next)
        //         console.log('shallowEqual1', shallowEqual(prev, next))
        //         console.log('shallowEqual2', shallowEqual(prev.mapUxConfig, next.mapUxConfig))
        //         return (
        //             shallowEqual(prev.mapUxConfig, next.mapUxConfig)
        //         );
        //     }
        // }
    )
);

export default enhance(ZoneTypesVisibility);
