
//kind of temporary solution to combine with live map later if reasonable.
//almost the same as live map, but tailored as old rt-map replacement for show
//so... isAtcMap =
//using old zones,


import React, {Component} from "react";

import 'mapbox-gl/dist/mapbox-gl.css';

import { connect } from 'react-redux';
import compose from 'recompose/compose';

import DeckGLOverlay from '../../Components/DeckGLOverlay';

import {clearAllEditablePrimitives} from "../../glSetups/deckDraw/deckDrawStoreBridge";

import AdsbSources from "../../../LogicV1Redux/oldLogic/RtdmSources";
import AdsbExchange from '../../../LogicV1Redux/oldLogic/LiveAdsbExchangeList.js';


import {timerStartRq, timerStopRq} from "../../../LogicV1Redux/actions/TickActions";

import {runUiMapSetup} from "../../../LogicV1Redux/actions/MapUxConfigActions";

import {AtcMapStateConstructor} from "../../glSetups/newCommon/mapStateConstructors";
import {FullMapLayersRedraw} from "../../glSetups/newCommon/mapStateLayersRedraws";

import "../../simplified.css";
import {unixTimeNow} from "../../../LogicV1Redux/oldLogic/TimeConverters";
import PubSub from "pubsub-js";
import {PubSubSignals} from "../../../LogicV1Redux/constants/PubSubSignals";

//import {bandwidthIssues} from "../../../Configs"; //fck

class AtcMap extends Component {

    constructor(props) {
        super(props);
        this.state = AtcMapStateConstructor();
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        const redrawThrottle = 0.7;

        if (unixTimeNow() < (prevState.__redrawnTS + redrawThrottle)) {
            //console.log('throtled');
            return null;
        }
        //console.log('should run');
        return FullMapLayersRedraw({
            prevState: prevState,
            nextProps: nextProps,
        });

    }

    //hmm requesting initial data should be somewhere else probably, but here we are sure we have been authorized, fixme.
    componentDidMount() {

        //hmm

        const {mapUxConfig} = this.props;


        //fixme -> config object, should be run earlier (probably)

        //only for pandora (rt-map), but this is ATCMap anyway

        //const pandoraTrafficEnabled = mapUxConfig.pandoraTrafficEnabled;

        console.warn('disabling some realtime streams for prod 1.1 in runUiMapSetup', mapUxConfig);

        this.props.runUiMapSetup({
            tickUpdate: true,

            //passes enable-rq, show to fix
            checkinsV2: true,

            alertsV2: true,

            zonesV2: {meta:{forceShow:true, withAupsAips:true}},

            //liveAbsbExchange: true,// <= just test

            //rtdmExchangeTicks: pandoraTrafficEnabled, //no rtdm exchange now
            //rtdmExchangeAircrafts: pandoraTrafficEnabled, //no rtdm exchange now
            rtdmCreotechTicks: true, //(!bandwidthIssues), //no rtdm creotech for prod 1.1 now (limited broadband!)
            rtdmSoapLprUnitsTicks: true, //forcing enabled
            rtdmAerobitsTicks: true,
            missionsV2: true,

        });

        this.pubsubOnNewViewport = PubSub.subscribe(PubSubSignals.MAP_VIEWPORT_SET_BBOX, (msg, {bBox}) => {
            this.setState({bBox});
        });

        //to check if still problem
        console.warn('fix unmounting!!! (liveadsb/socket/timer)')

    };

    componentWillUnmount() {
        this.props.timerStopRq();
        PubSub.unsubscribe(this.pubsubOnNewViewport);

        clearAllEditablePrimitives();

    };



    render() {
        // HACK: Wait for setting relative to parent element.
        //if (!this.state.isMounted) return <div />;

        const {icos, points, paths, cursor, polys, texts, textsData, bBox} = this.state;

        const {viewportOverride, lockViewHack}  = this.props;

        //console.log('overrideViewportData atcmap', viewportOverride);
        return (

            <div className="map-window">
                <DeckGLOverlay
                    lockViewHack = {lockViewHack}
                    cursor={cursor}
                    icos={icos}
                    polys={polys}
                    points={points}
                    paths={paths}
                    texts = {texts}
                    textsData = {textsData}
                    viewportOverride = {viewportOverride}
                    bBox = {bBox}
                />
                {/* temp for unify tests */}

                {/*<MyMenu menuComponent={LiveMapMenu}*/}
                        {/*menuStore={{ tickUpdate: true}}*/}
                        {/*onMenuChange={this.onMenuChange}*/}

                {/*/>*/}
            </div>

        );
    }
}


//hmm works without draweditor data?

//(24-01-19 refactoring) probably this is a case we don;t want to pull updates redux way.. (gl animation)
//(31-01-19) or we want, but we don;t wont it here? (ui? pandora window?)
const mapStateToProps = state => {
    //console.log('AtcMap, mapStateToProps', state);

    return {
        //isLoading: state.admin.loading > 0, //fix rerenders on props change
        alertsV2 : state.alertsV2,
        checkinsV2 : state.checkinsV2,
        inspectData: state.inspectData,
        mapUxConfig : state.mapUxConfig,
        tick: state.tick, //needed?
        zonesV2 : state.zonesV2, //do not use before format setup is finished. enable just for tests...
        liveAbsbExchange: state.liveAdsbExchange,
        rtdmExchangeTicks: state.rtdmExchangeTicks,
        rtdmExchangeAircrafts: state.rtdmExchangeAircrafts,
        rtdmCreotechTicks: state.rtdmCreotechTicks,
        rtdmSoapLprUnitsTicks:state.rtdmSoapLprUnitsTicks,
        rtdmAerobitsTicks:state.rtdmAerobitsTicks,
        missionsV2 : state.missionsV2,
        mySelf : state.mySelf,

    }
};

const enhance = compose(
    //translate,
    //muiThemeable(), // force redraw on theme change
    connect(
        mapStateToProps,
        {
            timerStartRq, timerStopRq,
            runUiMapSetup,
            //userLogout: userLogoutAction,
        },
        null,
        // {
        //     areStatesEqual: (prev, next) => {
        //         return (
        //             shallowEqual(prev, next)
        //         );
        //     }
        // }
    )
);

export default enhance(AtcMap);

