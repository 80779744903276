import {initializeLayersObject} from "../../gl_map/glSetups/newCommon/mapStateConstructors";
import {unixTimeNow} from "../oldLogic/TimeConverters";
import {STORE_ALL_RTDM_EXCHANGE_AIRCRAFTS} from "../constants/RtdmExchangeAircraftsConstants";

const initialState = {
    rawData: [],
    //deckData: initializeLayersObject(), //aircrafts data is displayed by exchange ticks as for now...
    storeTimestamp: 0
};

const rtdmExchangeAircraftsReducer = (previousState = initialState, { type, payload }) => {

    switch (type) {
        case STORE_ALL_RTDM_EXCHANGE_AIRCRAFTS:

            return {...previousState, ...payload, storeTimestamp:unixTimeNow()};

        default:
            return previousState;
    }
};

export default rtdmExchangeAircraftsReducer;
