import React from 'react';
import {List, ListItem} from '@material-ui/core-v3'
import PubSub from "pubsub-js"
import logger from '../../../LogicV1Redux/oldLogic/Logger'
import Card from "@material-ui/core-v3/Card/Card";
import withStyles from "@material-ui/core-v3/styles/withStyles";
import compose from 'recompose/compose';
import {connect} from "react-redux";

import CardActionArea from "@material-ui/core-v3/CardActionArea/CardActionArea";
import {PubSubSignals} from '../../../LogicV1Redux/constants/PubSubSignals'
import {timeFormatter, timeFormatterShort} from "../../../dr_ra2/utils/momentHelpers";
import {storeInspectData} from "../../../LogicV1Redux/actions/InspectActions";
import {InspectTypes} from "../../../modules2lib/common/";

//import {mission2displayWarnings} from "./MissionRowRenderer";
import {RowListItemTable} from "./RowListItemTable";
import {shortenMissionType} from "../utils/missionTypeShortener";
import {has, get} from "lodash";
import {FlightStatusesV2, LostControlAlert} from "../../../LogicV1Redux/constants/CheckinsV2Enums";
import pure from 'recompose/pure';
import {BarsConstants} from "../../../LogicV1Redux/constants/BarsConstants";
import moment from "moment";
import {m2ftCeiling} from "../../../LogicV1Redux/oldLogic/GeoConverters";
import classnames from 'classnames';
import {functionalColors} from "../../../gl_map/glSetups/oldCommon/commonInits";
import {isAcmTouchedOnlyCheckin as isAcmTouchedOnlyFn} from "./BarsController";
import {OperatorPrivileges} from "../../../dr_ra2/utils/operatorHelpers";
import {AppConfig} from "../../AppRtMap";

//TODO refactor bar

const styles = {
    root: {
        minWidth: "630px",
        overflow: "hidden",
        whiteSpace: "nowrap",
        border: "1px",
        borderStyle: "solid",
        borderRadius: "4px",
        marginBottom: "1px"
        // "&:not(:first-child)": {
        //     top: "-1px"
        // }
    },

    checkInBar: {
        width: "100%",
    },
    barPosted: {
        backgroundColor: "#ffff99"
    },
    barPostedAndWaiting: {
        backgroundColor: "#ffffff"
    },
    barAccepted: {
        backgroundColor: "rgb(195, 212, 183)"
    },
    barModified: {
        backgroundColor: "rgb(191, 228, 250)"
    },
    barRejected: {
        backgroundColor: "rgb(242, 207, 199)"
    },
    barPlanned: {
        backgroundColor: "rgb(200, 191, 231)"
    },
    barOverdued: {
        backgroundColor: "#a05a2b",
        color: "#fff"
    },
    barLostControl: {
        backgroundColor: "rgb(200, 0, 0)",
        color: "#ffffff"
    },
    barLostControlAck: {
        backgroundColor: "rgb(156, 38, 176)",
        color: "#ffffff"
    },
    barsInQueque: {
        backgroundColor: "#ffffff",
        color: "#ff00ff",
    },
    gutters: {
        padding: "0 0",
    },
    selected: {
        outlineStyle: "solid",
        outlineOffset: "-5px",
        outlineWidth: "3px",
        outlineColor: `rgba(${functionalColors.selected})`,
    }
};

const _getStatusWord = (status, barType) => {

    switch (status) {
        case FlightStatusesV2.ACCEPTED:
        case FlightStatusesV2.ACCEPTED_ACK:
            return `ACT`;
        case FlightStatusesV2.REJECTED:
        case FlightStatusesV2.REJECTED_ACK:
            return `RCT`;
        case FlightStatusesV2.CREATED:
            return `POST`;
        case FlightStatusesV2.LOST_CONTROL:
            return `LSTC`;
        case FlightStatusesV2.LAND_NOW:
        case FlightStatusesV2.LANDING_ACK:
            return `LND`;
        case FlightStatusesV2.CANCELLED:
            return `CNL`;
        case FlightStatusesV2.ATC_MODIFIED:
        case FlightStatusesV2.ATC_MODIFIED_ACK:
            return `MODF`;
        case FlightStatusesV2.SEEN:
            return `SEEN`;
        case FlightStatusesV2.IN_QUEUE:
            return `CHKD`;
        default:
            return ``;
    }
};

const _getStatusColor = (status, classes, barType) => {

    // primary override for overdued state, it is a pseudo status recognized by bar type only
    // only lost control has higher priority
    if(
        (barType === BarsConstants.CONTROLLED_CHECKIN_INFLIGHT_OVERDUED || barType === BarsConstants.CONTROLLED_CHECKIN_ONBLOCK_OVERDUED)
        && status !== FlightStatusesV2.LOST_CONTROL
    )
        return classes.barOverdued;

    switch (status) {
        case FlightStatusesV2.ACCEPTED:
        case FlightStatusesV2.SEEN:
        case FlightStatusesV2.ACCEPTED_ACK:
            return classes.barAccepted;
        case FlightStatusesV2.REJECTED:
        case FlightStatusesV2.REJECTED_ACK:
            return classes.barRejected;
        case FlightStatusesV2.ATC_MODIFIED:
        case FlightStatusesV2.ATC_MODIFIED_ACK:
            return classes.barModified;
        case FlightStatusesV2.LOST_CONTROL:
            return classes.barLostControl;
        case FlightStatusesV2.LAND_NOW:
        case FlightStatusesV2.LANDING_ACK:
            return classes.barLostControlAck;
        case FlightStatusesV2.CREATED:
            if(barType === BarsConstants.WITH_RESTRICTIONS_CHECKIN_INFLIGHT || barType === BarsConstants.NO_RESTRICTIONS_CHECKIN_INFLIGHT)
            {
                return classes.barPosted;
            }
            else {
                return classes.barPostedAndWaiting;
            }
        case FlightStatusesV2.IN_QUEUE:
            return classes.barsInQueque;
        default:
            return classes.barPlanned;
    }
};

// function checkin2displayWarnings (checkin){
//     let warningsNames = [];
//     let displayWarnings = null;
//
//     checkin.__warningsNames.forEach((warning) => {
//         warningsNames.push(warning.name);
//         displayWarnings = warningsNames[0]; //hmm
//
//         if (checkin.__warningsNames.length > 1) {
//             displayWarnings += `... (+${warningsNames.length - 1})`
//         }
//     });
//     return displayWarnings;
// }

const CheckInRowRenderer = (props) => {

    const {classes, checkin, selectCheckinAction, missionsV2, type, lostControlReviews, selectedCheckinUid, callsign} = props;

    const {
        uid,
        registry,
        operator,
        phone,
        aglmax,
        status,
        modified,
        missionapproval,
        ctrl,
        etc,
        //operatorHack,
        priv,
        govMsgs,
    } = checkin.checkinRecord;

    const {
        __min,
        __max,
        __start,
        __stop,
        __isAfterStop,
    } = checkin;

    let missionId = missionapproval ? missionapproval : null;
    // //hack for droniada
    // const approvalId = device_info.approvalId ? device_info.approvalId : null;
    // if(!!approvalId) {
    //     missionId = approvalId;
    // }


    let connectedMission;
    if(missionId) {
        connectedMission = missionsV2.rawData.find(primitive => primitive.missionRecord.approval === missionId);
    }

    console.log("==== checkinRowRenderer connectedMission", connectedMission, missionId, missionsV2);

    //const fakeMissionInspect = (connectedMission)?{mission:connectedMission, inspectType:InspectTypes.MISSIONV2}:null;

    const approvalNo = (missionId) ? missionId : 'N/A';

    const missionType = (connectedMission) ? connectedMission.missionRecord.typ[0] : 'V';

    //TODO add check if is in leg, and if didn't exceed max level approved, and if exceeded add warning color
    //const displayHeight = (connectedMission) ? `${connectedMission.missionRecord.displayMaxHeight}/${__max}ft AMSL` : `---/${__max}ft AMSL`;
    const displayMaxHeight = m2ftCeiling(aglmax + etc.__srtmV1Gnd);

    //const displayWarnings =  (connectedMission) ? mission2displayWarnings(connectedMission) :checkin2displayWarnings (checkin);

    const warnings = (connectedMission) ? connectedMission.warnings : checkin.__warningsNames;

    //TODO which are more important?
    const operatorName = (connectedMission) ? connectedMission.missionRecord.operator.displayName : operator;
    const firstName = operatorName.split(" ")[0];
    const lastName = operatorName.split(" ")[1];

    // TODO this is temporary bridge solution until callsign engine backend deployment
    //const {priv} = operatorHack;
    const operatorPrivileges = new OperatorPrivileges(priv);
    let isSuavo = false;
    let is112 = false;

    // turn on when this info is not provided inside callsign
    if(!AppConfig.callsignEngineStatic) {
        if(operatorPrivileges.isSuavo()) isSuavo = true;
        if(connectedMission) if(get(connectedMission, 'missionRecord.etc.is112')) is112 = true;
    }



    const phoneNo =  (connectedMission) ? connectedMission.missionRecord.operator.phone : phone;

    const mStart = (connectedMission) ? connectedMission.missionRecord.start : "";
    const mStop = (connectedMission) ? connectedMission.missionRecord.stop : "";

    const {droneLostBattery} = etc;

    const droneLostBatteryTminus = !!droneLostBattery && (status === FlightStatusesV2.LOST_CONTROL) ?
        `${Math.ceil(moment.duration(
            moment(modified).add(droneLostBattery, 'm').diff(
                moment()
            )).asMinutes())}min`
        :
        null;

    const barColor = _getStatusColor(status, classes, type);

    const isSelected = selectedCheckinUid === uid || (connectedMission && connectedMission.missionRecord.uid === missionsV2.selectedMissionUid);
    const isAcmTouched = govMsgs && govMsgs.some(msg=>msg.issuerEmail === 'acm@pansa.pl');
    const isAcmTouchedOnly = isAcmTouchedOnlyFn(checkin);
    // console.log("CIRR", selectedCheckinUid, uid);
    return (
        <ListItem key={uid}
                  className={
                      classnames(
                          classes.root,
                          {
                              [classes.selected]: isSelected
                          }
                      )
                  }
                  classes={{gutters: classes.gutters}}>
            <Card className={[classes.checkInBar, barColor].join(' ')}>
                <CardActionArea onClick={() => {
                    selectCheckinAction(checkin, connectedMission)
                }}>
                    <RowListItemTable
                        {...{
                            approvalNo,
                            callsign,
                            missionType: shortenMissionType(missionType),
                            //displayWarnings,
                            warnings,
                            displayMaxHeight,
                            rmk: `RMK: ${has(connectedMission, 'missionRecord.etc.shortDescription') ? connectedMission.missionRecord.etc.shortDescription : 'N/A'}`,
                            status: _getStatusWord(status, type),
                            phone: phoneNo,
                            start: timeFormatterShort(__start * 1000, {useUtc: true}),
                            stop: timeFormatterShort(__stop * 1000, {useUtc: true}),
                            missionStart: timeFormatterShort(mStart, {useUtc: true}),
                            missionStop: timeFormatterShort(mStop, {useUtc: true}),
                            isConnectedMission: !!connectedMission,
                            beforeMissionStartAlert: moment(__start * 1000).isBefore(moment(mStart)) && moment().isBefore(moment(mStart)),
                            afterMissionStopAlert: moment(__stop * 1000).isAfter(moment(mStop)) && moment().isAfter(moment(mStop)),
                            droneLostBattery: droneLostBatteryTminus,
                            waitingForAck:
                                status === FlightStatusesV2.ATC_MODIFIED ||
                                status === FlightStatusesV2.REJECTED ||
                                status === FlightStatusesV2.ACCEPTED ||
                                status === FlightStatusesV2.LAND_NOW,
                            acked:
                                status === FlightStatusesV2.ATC_MODIFIED_ACK ||
                                status === FlightStatusesV2.REJECTED_ACK ||
                                status === FlightStatusesV2.ACCEPTED_ACK ||
                                status === FlightStatusesV2.LANDING_ACK,
                            lostControlAlert: lostControlReviews.has(uid) && lostControlReviews.get(uid) === LostControlAlert.SHOULD_REVIEW,
                            isAcmTouched: isAcmTouched,
                            isAcmTouchedOnly: isAcmTouchedOnly,
                            isOverduedCheckin: __isAfterStop,
                            isOverduedMission: moment().isAfter(mStop),
                            is112,
                            isSuavo
                        }}
                    />
                </CardActionArea>
            </Card>
        </ListItem>
    )
};


const enhance = compose(
    //translate,
    pure,
    withStyles(styles),
);

export default enhance(CheckInRowRenderer);

