
import { SET_APP_STATE_STORE, SET_INIT_PHASE } from "../constants/AppStateConstants";
import {unixTimeNow} from "../../modules2lib/common/src/TimeUtils";


const initialState = {storeTimestamp: 0};

const AppStateReducer = (previousState = initialState, { type, payload }) => {
    switch (type) {
        case SET_INIT_PHASE:
        case SET_APP_STATE_STORE:
            console.log(`----- AppStateReducer reducer ${type}}:`);
            return {...previousState, ...payload, storeTimestamp:unixTimeNow()};
        default:
            return previousState;
    }
};

export default AppStateReducer;
