import React, {Component, createElement} from 'react';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core-v3/styles';

import AtcMapUI from '../../gl_map/Components/Maps/AtcMapUI';
import AtcTabs from "./AtcTabs";
import {Notification} from "ra-ui-materialui";
import MyNotification from './MyNotification';
import {MuiThemeProvider} from "@material-ui/core-v3/styles/";
import {createMuiTheme} from "@material-ui/core-v3";
import {connect} from "react-redux";
import {startup} from "../../LogicV1Redux/actions/AppStateActions"
import {FETCH_PHASES, INIT_PHASES} from "../../LogicV1Redux/constants/AppStateEnums";
import {AppConfig} from "../AppRtMap";

const styles = {
    fixmeMap: {
        position:'absolute',
        top:0,
        bottom:0,
        left:0,
        //right:0
        width:"60%"
    },
    fixmePanel: {
        position:'absolute',
        top:0,
        bottom:0,
        right:0,
        width:"40%",
        overflow:'hidden'
    },
};

const themeLarge = createMuiTheme({
    sizeLarge: true,
    typography: {
        fontSize: 26
    },
    overrides: {
        MuiButton: {
            sizeLarge: {
                minHeight: "112px"
            }
        }
    }
});

const themeSmall = createMuiTheme();

export const PandoraWindowContext = React.createContext(null);


class PandoraWindow extends Component {

    state = {
        showCallsignEngineInfo: !AppConfig.callsignEngineStatic,
    };

    container = React.createRef();

    componentDidMount() {
        this.props.startup();
    }

    _delayCallsignInfoHide = () => {
        setTimeout(
            ()=>this.setState({showCallsignEngineInfo: false}),
            300
        );
    };

    render() {

        const {classes, notification, uiSize, mySelf, myAuth, appState} = this.props;
        const {showCallsignEngineInfo} = this.state;

        if (appState.fetchPhase === FETCH_PHASES.ZONE_TYPES) {
            return <div>waiting for zonetypes</div>;
        }


        else if (appState.fetchPhase === FETCH_PHASES.ZONES) {
            return <div>waiting for zones</div>;
        }

        else if (appState.fetchPhase === FETCH_PHASES.CHECKINS) {
            return <div>waiting for checkins</div>;
        }

        else if (appState.fetchPhase === FETCH_PHASES.MISSIONS) {
            return <div>waiting for missions</div>;
        }

        else if(appState.initPhase === INIT_PHASES.READY &&
            !AppConfig.callsignEngineStatic &&
            showCallsignEngineInfo
        ) {
            this._delayCallsignInfoHide();
            return <div>callsign engine works in dynamic mode</div>
        }

        else if(appState.initPhase === INIT_PHASES.READY &&
            !!AppConfig.callsignEngineStatic &&
            showCallsignEngineInfo
        ) {
            this._delayCallsignInfoHide();
            return <div>callsign engine works in static mode</div>
        }

        if (!myAuth.token) {
            return null;
        }

        if(appState.initPhase === INIT_PHASES.READY && !showCallsignEngineInfo)
            return (
                <MuiThemeProvider
                    theme={uiSize === 'large' ? themeLarge : themeSmall}
                >
                    <div ref={this.container}>
                        <PandoraWindowContext.Provider value={this.container}>

                            <div className={classes.fixmeMap}>
                                <AtcMapUI uiSize={uiSize}/>
                            </div>

                            <div className={classes.fixmePanel}>
                                <AtcTabs/>
                            </div>

                            <div style={{position: "absolute", bottom: 0, left: 0}}>
                                {createElement(notification)}
                            </div>

                        </PandoraWindowContext.Provider>
                    </div>
                </MuiThemeProvider>
            );

        return null
    }
}

PandoraWindow.defaultProps = {
    notification: MyNotification,
};

const mapStateToProps = (state, props) => ({
    uiSize : state.mapUxConfig.uiSize,
    mySelf : state.mySelf,
    myAuth : state.myAuth,
    appState : state.appState,
});

const enhance = compose(
    connect(mapStateToProps,
        {
        startup
    }),
    withStyles(styles)
);

export default enhance(PandoraWindow);
