export function mapAtrributeToName(attr) {
    switch (attr) {
        case 'Alt':
        case 'altitude':
        case 'alt':
            return 'Altitude';

        case 'Call':
        case 'teamName':
        case 'call':
            return 'Callsign';

        case 'Sqk':
        case 'sq':
            return 'Squawk';

        case 'Spd':
        case 'speed':
        case 'velh':
            return 'Speed';

        case 'Vsi':
        case 'velv':
            return 'Vertical speed';

        case 'Reg':
        // lpr naming fault
        case 'callSign':
            return 'Registry';

        case 'Type':
            return 'Type';

        case 'Op':
            return 'Airline operator';

        case 'status':
            return 'Status';

        default:
            return attr;
    }
}

export function mapLprCallsignToOrigin(callsign) {
    switch (callsign) {
        case 'Ratownik 1':
            return 'Białystok';
        case 'Ratownik 2':
            return 'Bydgoszcz';
        case 'Ratownik 3':
            return 'Gdańsk';
        case 'Ratownik 4':
            return 'Gliwice';
        case 'Ratownik 5':
            return 'Kielce';
        case 'Ratownik 6':
            return 'Kraków';
        case 'Ratownik 7':
            return 'Lublin';
        case 'Ratownik 8':
            return 'Olsztyn';
        case 'Ratownik 9':
            return 'Poznań';
        case 'Ratownik 10':
            return 'Sanok';
        case 'Ratownik 11':
            return 'Szczecin';
        case 'Ratownik 12':
            return 'Warszawa';
        case 'Ratownik 13':
            return 'Wrocław';
        case 'Ratownik 15':
            return 'Zielona Góra';
        case 'Ratownik 16':
            return 'Łódź';
        case 'Ratownik 17':
            return 'Suwałki';
        case 'Ratownik 18':
            return 'Płock';
        case 'Ratownik 19':
            return 'Sokołów Podlaski';
        case 'Ratownik 21':
            return 'Ostrów Wielkopolski';
        case 'Ratownik 22':
            return 'Koszalin';
        case 'Ratownik 23':
            return 'Opole';
        case 'Ratownik 24':
            return 'Gorzów Wielkopolski';
        default:
            return undefined
    }
}

export function mapLprStatusToDescription(status) {
    switch (status) {
        case 0:
            return 'Ready to start';
        case 1:
            return 'Inflight to injured';
        case 2:
            return 'In place';
        case 3:
            return 'With injured';
        case 4:
            return 'In hospital';
        case 5:
            return 'Return to base (on radio)';
        case 6:
            return 'Duty suspended';
        case 7:
            return 'Technical service';
        case 8:
            return 'Other tasks';
        case 9:
            return 'Duty finished';
        case 10:
            return 'Emergency';
        case 100:
            return 'Error';
        default:
            return 'Status not recognized';
    }
}