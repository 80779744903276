import {initializeLayersObject} from "../../gl_map/glSetups/newCommon/mapStateConstructors";
import {unixTimeNow} from "../oldLogic/TimeConverters";
import {STORE_ALL_RTDM_EXCHANGE_TICKS} from "../constants/RtdmExchangeTicksContants";
import {processAdsbExchangeTicks} from "../oldLogic/RtdmSources";

const initialState = {rawData: [], deckData: initializeLayersObject(), storeTimestamp: 0};

const rtdmExchangeTicksReducer = (previousState = initialState, { type, payload }) => {

    switch (type) {
        case STORE_ALL_RTDM_EXCHANGE_TICKS:

            if (payload.rawData) {
                //special case, processor mixes aircraft data here...
                payload.deckData = processAdsbExchangeTicks(payload);
            }

            return {...previousState, ...payload, storeTimestamp:unixTimeNow()};

        default:
            return previousState;
    }
};

export default rtdmExchangeTicksReducer;
