import {
    TOGGLE_PLANNED_WINDOW,
} from "./WindowActions";

const initialState = {
    plannedWindow: {
        visible: false,
        portal: false
    },

};

export default (state = initialState, {type, show, portal}) => {
    switch (type) {
        case TOGGLE_PLANNED_WINDOW:
            return {
                ...state,
                plannedWindow: {
                    visible: show !== undefined ? show : !state.plannedWindow.visible,
                    portal: portal !== undefined ? portal : false,
                }
            };
        default:
            return state;
    }
}