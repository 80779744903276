import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core-v3/styles';
import Radio from '@material-ui/core-v3/Radio';
import RadioGroup from '@material-ui/core-v3/RadioGroup';
import FormControlLabel from '@material-ui/core-v3/FormControlLabel';
import FormControl from '@material-ui/core-v3/FormControl';
import {ALERT_SUBTYPES, ALERT_TYPES, RADIO_VALUE_TEXTS} from "../../../LogicV1Redux/constants/AlertsV2Enums";

const styles = theme => ({
    root: {
        display: 'flex',
    },
    formControl: {
        margin: theme.spacing.unit * 3,
    },
    group: {
        margin: `${theme.spacing.unit}px 0`,
    },
});


class AlertSubtypesRadioSelection extends React.Component {

    handleChange = event => {
        let value = event.target.value;
        this.props.onRadioButtonChanged(value);
    };

    render() {
        const { classes, radioValue } = this.props;
        //console.log('====radio selection', radioValue)

        return (
            <div className={classes.root}>
                <FormControl component="fieldset" className={classes.formControl}>
                    <RadioGroup
                        aria-label="Alert subtype"
                        name="alert_subtype"
                        className={classes.group}
                        value={radioValue}
                        onChange={this.handleChange}
                    >
                        <FormControlLabel value={ALERT_SUBTYPES.LPR} control={<Radio />} label={RADIO_VALUE_TEXTS[ALERT_SUBTYPES.LPR]} />
                        <FormControlLabel value={ALERT_SUBTYPES.CTR} control={<Radio />} label={RADIO_VALUE_TEXTS[ALERT_SUBTYPES.CTR]} />
                    </RadioGroup>
                </FormControl>
            </div>
        );
    }
}


AlertSubtypesRadioSelection.propTypes = {
    classes: PropTypes.object.isRequired,
    onRadioButtonChanged: PropTypes.func
};

export default withStyles(styles)(AlertSubtypesRadioSelection);
