import React from 'react'
import Slider from "rc-slider";
import Tooltip from "rc-tooltip";
import Range from "rc-slider/es/Range";
import 'rc-slider/assets/index.css';
import "./MyLayerBoundarySlider.css"
import {withStyles} from "@material-ui/core";
import {Layers} from "@material-ui/icons";



const styles = {
    valueBox: {
        marginBottom: "0.9rem",
    },
    boxText: {
        display: "inline-block",
    },
    boxRow: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
    },
    slider: {
        marginTop: "20px",
        marginBottom: "20px"
    }
};

class MyLayerBoundarySlider extends React.Component {

    render() {
        const Handle = Slider.Handle;
        const {onChange, classes, ...rest} = this.props;

        const handle = (props) => {
            const {value, dragging, index, ...restProps} = props;
            return (
                <Tooltip
                    overlay={`${value}ft`}
                    visible={true}
                    placement="right"
                    key={index}
                    prefixCls={'my-rc-tooltip'}
                >
                    <Handle value={value} {...restProps} />
                </Tooltip>
            );
        };

        return (
            <>
                <Layers style={{marginLeft: "-5px"}}/>
                <Range pushable handle={handle} vertical onChange={onChange} {...rest} className={classes.slider}/>
            </>
        )
    }
}

export default withStyles(styles)(MyLayerBoundarySlider);
