//misleading names ? should be lpr hems bases?
//https://www.lpr.com.pl/pl/dla-dyspozytorow-medycznych/czas-dyzurow-baz-hems/
// currently we have over 200 hospitals in db

import axios from 'axios'
import {APP_MODES, urls} from '../../Configs'
import PubSub from 'pubsub-js'
import StoreProvider from "../../dr_ra2/MyStoreProvider";
import logger from "./Logger";
import {fetchAllLprHelipadsRq} from "../actions/LprHelipadsV1Actions";
import {InspectTypes} from "../../modules2lib/common/";
import {ICONS} from "../../gl_map/glSetups/oldCommon/commonInits";
import {ICO_SIZES} from "../../gl_map/glSetups/oldCommon/deckInits";




//fixme -> unify to new format..
//and.. wrong.. select requires rebuild/rerender . dang.
//// almost (after init) static (no changes).. so... cached
//// (should somewhere in store /fetch ?, but that is common question...
//// to solve during refactoring/optimizations)
//let lprHelipadsIcos = [];
//let lastselectedHelipad = null;

export function processLprHelipadsV1ToDeck({selectedHelipad,rawData}) {

    let icos = [], points = [], paths = [], polys = [], texts = [];

    // lat: 53.8561667
    // lng: 23.0061667
    // name_location: "Augustów"

    //hmm for rtmap now
    // const selectedLprHems = (inspectData && inspectData.inspectType === InspectTypes.LPR_HELIPAD_V1)
    //     ? inspectData.lprHelipads
    //     : null;

    //console.warn('=========process lprhems, inspect data', inspectData, selectedLprHems);
    //console.log('lprhems', lprHelipadsV1);

    let lprHelipadsIcos = [];

    rawData.forEach((record) => {

        const isSelected = (selectedHelipad && selectedHelipad.name_location === record.name_location); ///aargh, no id!

        // if (selectedLprHems) {
        //     console.log(record.name_location, selectedLprHems.name_location);
        // }

        let lprHelipadsIco = {}; //Object.assign({}, sampleIco);

        lprHelipadsIco.position = [record.lng, record.lat];
        lprHelipadsIco.position[2] = 100; //damn. fixme...

        lprHelipadsIco.icon = (isSelected)?ICONS.BLUE_HOSPITAL_SELECTED_ICO:ICONS.BLUE_HOSPITAL_ICO; //huh good place to test icos

        lprHelipadsIco.size = ICO_SIZES.LPR_HELIPAD;
        lprHelipadsIco.inspectData = {
            lprHelipads: record,
            inspectType:InspectTypes.LPR_HELIPAD_V1
        };

        icos.push(lprHelipadsIco);
    });

    return {icos, points, paths, polys, texts};


}


export function requestLprHelipadsV1() {

    console.warn('OLD request lprHelipads here!');

    const store = StoreProvider.getStore();
    store.dispatch(fetchAllLprHelipadsRq());

}


export default {
    requestLprHelipadsV1,
}


