import Typography from "@material-ui/core-v3/Typography/Typography";
import {Component} from "react";
import React from "react";
import withStyles from "@material-ui/core-v3/styles/withStyles";
import Card from "@material-ui/core-v3/Card/Card";
import CardContent from "@material-ui/core-v3/CardContent/CardContent";
import FormControl from "@material-ui/core-v3/FormControl/FormControl";
import RadioGroup from "@material-ui/core-v3/RadioGroup/RadioGroup";
import Radio from "@material-ui/core-v3/Radio/Radio";
import FormControlLabel from "@material-ui/core-v3/FormControlLabel/FormControlLabel";
import Checkbox from "@material-ui/core-v3/Checkbox/Checkbox";
import MyCustomSlider from "./prepared-components/MyCustomSlider";
import ZoneTypesVisibility from './ZoneTypesVisibility'
import TrafficVisibility from './TrafficVisibility'

import {connect} from "react-redux";
import {compose} from 'recompose';
import {
    storeUiShowMatz,
    storeUiSize,
    storeUiVolume,
    storeUiTickEnabled,
    storeUiAlertsV1Show,
    storeUiAlertsV1EnableRq,
    storeUiAlertsV2Show,
    storeUiAlertsV2EnableRq,
    storeUiCheckinsV1Show,
    storeUiCheckinsV1EnableRq,
    storeUiCheckinsV2Show,
    storeUiCheckinsV2EnableRq,
    storeUiMissionsV2Show,
    storeUiMissionsV2EnableRq,
    storeUiRtMapZonesV1Show,
    storeUiRtMapZonesV1EnableRq,
    storeUiZonesV2Show,
    storeUiZonesV2EnableRq,

    storeUiLiveAbsbExchangeShow,
    storeUiLiveAbsbExchangeEnableRq,

    storeUiLprHelipadsV1Show,
    storeUiLprHelipadsV1EnableRq,

    storeUiRtdmCreotechTicksShow,
    storeUiRtdmCreotechTicksEnableRq,

    storeUiRtdmSoapLprUnitsTicksShow,
    storeUiRtdmSoapLprUnitsTicksEnableRq,

    storeUiRtdmExchangeTicksShow,
    storeUiRtdmExchangeTicksEnableRq

} from "../../LogicV1Redux/actions/MapUxConfigActions";
import PureRichTextField from "ra-ui-materialui/lib/field/RichTextField";
import {richText} from "../../docs/en/gov/help";
import {Divider} from "@material-ui/core-v3";
import CallsignDisplay from "./CallsignDisplayConfig";
import {AppConfig} from "../AppRtMap";



const styles = theme => ({
    root: {
        display: "block",
    },
    yellowCard: {
        backgroundColor: "rgb(250, 250, 210)",
        marginTop: "1em",
        marginBottom: "2em"
    },
    card: {
        marginBottom: "0.5em"
    },
    formControl: {
        margin: theme.spacing.unit,
        width: "100%"
    },
    group: {
        flexDirection: "row"
    }
});

function ConfigContainer({ children, dir, className}) {
    return (
        <Typography className={className} component="div" dir={dir} style={{ padding: 8 * 3 }}>
            {children}
        </Typography>
    );
}


class ConfigTab extends Component {


    _handleRadioChange = event => {
        this.props.storeUiSize(event.target.value);
    };

    _handleToggleChange = event => {
        const handlersMapping = {
            "uiTickEnabledToggle":this.props.storeUiTickEnabled,
            "uiMatzShowToggle":this.props.storeUiShowMatz,

            "uiAlertsV1ShowToggle":this.props.storeUiAlertsV1Show,
            "uiAlertsV1EnabledToggle":this.props.storeUiAlertsV1EnableRq,

            "uiAlertsV2ShowToggle":this.props.storeUiAlertsV2Show,
            "uiAlertsV2EnabledToggle":this.props.storeUiAlertsV2EnableRq,

            "uiCheckinsV1ShowToggle":this.props.storeUiCheckinsV1Show,
            "uiCheckinsV1EnabledToggle":this.props.storeUiCheckinsV1EnableRq,

            "uiCheckinsV2ShowToggle":this.props.storeUiCheckinsV2Show,
            "uiCheckinsV2EnabledToggle":this.props.storeUiCheckinsV2EnableRq,

            "uiLprHelipadsShowToggle":this.props.storeUiLprHelipadsV1Show,
            "uiLprHelipadsEnabledToggle":this.props.storeUiLprHelipadsV1EnableRq,

            "uiMissionsV2ShowToggle":this.props.storeUiMissionsV2Show,
            "uiMissionsV2EnabledToggle":this.props.storeUiMissionsV2EnableRq,

            "uiRtMapZonesShowV1Toggle":this.props.storeUiRtMapZonesV1Show,
            "uiRtMapZonesEnabledV1Toggle":this.props.storeUiRtMapZonesV1EnableRq,

            "uiZonesV2ShowToggle":this.props.storeUiZonesV2Show,
            "uiZonesV2EnabledToggle":this.props.storeUiZonesV2EnableRq,

            "uiLiveAbsbExchangeShowToggle":this.props.storeUiLiveAbsbExchangeShow,
            "uiLiveAbsbExchangeEnabledToggle":this.props.storeUiLiveAbsbExchangeEnableRq,


            "uiRtdmCreotechTicksShowToggle":this.props.storeUiRtdmCreotechTicksShow,
            "uiRtdmCreotechTicksEnabledToggle":this.props.storeUiRtdmCreotechTicksEnableRq,

            "uiRtdmSoapLprUnitsTicksShowToggle":this.props.storeUiRtdmSoapLprUnitsTicksShow,
            "uiRtdmSoapLprUnitsTicksEnabledToggle":this.props.storeUiRtdmSoapLprUnitsTicksEnableRq,

            "uiRtdmExchangeTicksShowToggle":this.props.storeUiRtdmExchangeTicksShow,
            "uiRtdmExchangeTicksEnabledToggle":this.props.storeUiRtdmExchangeTicksEnableRq,
        };

        console.log("calling", event.target.value);

        handlersMapping[event.target.value](event.target.checked);
    };



    //hmm, maybe onEnd? is onChange.
    //LGZ -> for UX better to see live
    handleVolumeSliderChange = value => {
        this.props.storeUiVolume(value);
    };


    // componentDidMount() {
    //     console.log('==============mounut')
    //
    //     this.timer = setInterval(
    //         () => this.props.storeUiVolume(this.state.volumeSliderValue + 10),
    //         5000,
    //     );
    // }
    //
    // componentWillUnmount() {
    //     console.log('==============umount')
    //
    //     clearInterval(this.timer);
    // }


    componentWillReceiveProps(nextProps) {
        //console.log('ConfigTab componentWillReceiveProps', nextProps);
        if (this.props !== nextProps) {
            //console.log('new props received', this.state);
        }
    }

    render() {

        const {
            classes,
        } = this.props;


        const {
            uiVolume,
            uiSize,
            uiMatzShow,
            uiTickEnabled,

            uiAlertsV1Show,
            uiAlertsV1Enabled,
            uiAlertsV2Show,
            uiAlertsV2Enabled,
            uiCheckinsV1Show,
            uiCheckinsV1Enabled,
            uiCheckinsV2Show,
            uiCheckinsV2Enabled,
            uiLprHelipadsV1Show,
            uiLprHelipadsV1Enabled,
            uiMissionsV2Show,
            uiMissionsV2Enabled,
            uiRtMapZonesV1Show,
            uiRtMapZonesV1Enabled,
            uiZonesV2Show,
            uiZonesV2Enabled,

            uiLiveAbsbExchangeEnabled,
            uiLiveAbsbExchangeShow,
            uiRtdmSourcesEnabled,
            uiRtdmSourcesUseSocketIo,


            uiRtdmCreotechTicksShow,
            uiRtdmCreotechTicksEnabled,
            uiRtdmSoapLprUnitsTicksShow,
            uiRtdmSoapLprUnitsTicksEnabled,

            uiRtdmExchangeTicksShow,
            uiRtdmExchangeTicksEnabled,


        } = this.props.mapUxConfig;



        const {app, builtOn, devBuild} =  this.props.myAppExtras;
        const devInfo = `${app.name.toLowerCase()} app, ${builtOn}`;
        //console.log('==============ConfigTab render', this.props.mapUxConfig.uiLprHelipadsV1Show, uiLprHelipadsV1Show, this.props.myAppExtras);

        const hideDevToolbarStyle = {display:"none"};

        return (
            <ConfigContainer className={classes.root}>


                <pre>You are logged in as: <b>{this.props.mySelf.username}</b></pre>
                <pre>Application version: {devInfo}</pre>
                <Divider/>
                <Card className={classes.yellowCard}>
                    <CardContent>
                        <PureRichTextField record={richText} source='title'/>
                        <PureRichTextField record={richText} source='content'/>
                    </CardContent>
                </Card>

                <Card className={classes.card}>
                    <CardContent>
                        <TrafficVisibility />
                    </CardContent>
                </Card>

                <Card className={classes.card}>
                    <CardContent>
                        <ZoneTypesVisibility />
                    </CardContent>
                </Card>

                {
                    AppConfig.callsignEngineStatic &&
                    <Card className={classes.card}>
                        <CardContent>
                            <CallsignDisplay />
                        </CardContent>
                    </Card>
                }

                <Card className={classes.card}>
                    <CardContent>
                        <FormControl component="fieldset" className={classes.formControl}>
                            <b>User interface size</b>
                            <RadioGroup
                                aria-label="UI size"
                                name="ui.size"
                                className={classes.group}
                                value={uiSize}
                                onChange={this._handleRadioChange}
                            >
                                <FormControlLabel value="large" control={<Radio />} label="LG" />
                                <FormControlLabel value="small" control={<Radio />} label="SM" />
                            </RadioGroup>

                            <br/>
                            <b>Volume</b>
                            <span style={{marginTop: "0.5em"}}>Level: {Math.round(uiVolume*100)}%</span>
                            <MyCustomSlider
                                            onSliderChanged={this.handleVolumeSliderChange}
                                            value={Number(uiVolume)}
                                            min={0.0}
                                            max={1.0}
                                            step={0.01}
                            />
                            {

                            <div style={hideDevToolbarStyle}>
                                {/*!!hidden now!!*/}

                            <div className={classes.group}>
                                <Checkbox
                                    checked={!!uiTickEnabled}
                                    onChange={this._handleToggleChange}
                                    value="uiTickEnabledToggle"
                                    color="primary"
                                />
                                (DEV ONLY) TICK UPDATE
                            </div>

                            <div className={classes.group}>
                                <Checkbox
                                    checked={!!uiLprHelipadsV1Show}
                                    onChange={this._handleToggleChange}
                                    value="uiLprHelipadsShowToggle"
                                />
                                LPR Hospitals
                            </div>
                            <div className={classes.group}>
                                <Checkbox
                                    //disabled={true}
                                    checked={!!uiLprHelipadsV1Enabled}
                                    onChange={this._handleToggleChange}
                                    value="uiLprHelipadsEnabledToggle"
                                />
                                (DEV) LPR Hospitals Enabled
                            </div>

                            <div className={classes.group}>
                                <Checkbox
                                    checked={!!uiAlertsV1Show}
                                    onChange={this._handleToggleChange}
                                    value="uiAlertsV1ShowToggle"
                                    color="primary"
                                />
                                (DEV ONLY) ALERTSV1 SHOW
                            </div>
                            <div className={classes.group}>
                                <Checkbox
                                    //disabled={true}
                                    checked={!!uiAlertsV1Enabled}
                                    onChange={this._handleToggleChange}
                                    value="uiAlertsV1EnabledToggle"
                                />
                                (DEV) ALERTSV1 Enabled
                            </div>

                            <div className={classes.group}>
                                <Checkbox
                                    checked={!!uiAlertsV2Show}
                                    onChange={this._handleToggleChange}
                                    value="uiAlertsV2ShowToggle"
                                    color="primary"
                                />
                                (DEV ONLY) ALERTSV2 SHOW
                            </div>
                            <div className={classes.group}>
                                <Checkbox
                                    //disabled={true}
                                    checked={!!uiAlertsV2Enabled}
                                    onChange={this._handleToggleChange}
                                    value="uiAlertsV2EnabledToggle"
                                />
                                (DEV) ALERTSV2 Enabled
                            </div>

                            <div className={classes.group}>
                                <Checkbox
                                    checked={!!uiCheckinsV1Show}
                                    onChange={this._handleToggleChange}
                                    value="uiCheckinsV1ShowToggle"
                                    color="primary"
                                />
                                (DEV ONLY) CHECKINSV1 SHOW
                            </div>
                            <div className={classes.group}>
                                <Checkbox
                                    //disabled={true}
                                    checked={!!uiCheckinsV1Enabled}
                                    onChange={this._handleToggleChange}
                                    value="uiCheckinsV1EnabledToggle"
                                />
                                (DEV) CHECKINSV1 Enabled
                            </div>

                            <div className={classes.group}>
                                <Checkbox
                                    checked={!!uiCheckinsV2Show}
                                    onChange={this._handleToggleChange}
                                    value="uiCheckinsV2ShowToggle"
                                    color="primary"
                                />
                                (DEV ONLY) CHECKINSV2 SHOW
                            </div>
                            <div className={classes.group}>
                                <Checkbox
                                    //disabled={true}
                                    checked={!!uiCheckinsV2Enabled}
                                    onChange={this._handleToggleChange}
                                    value="uiCheckinsV2EnabledToggle"
                                />
                                (DEV) CHECKINSV2 Enabled
                            </div>

                            <div className={classes.group}>
                                <Checkbox
                                    checked={!!uiRtMapZonesV1Show}
                                    onChange={this._handleToggleChange}
                                    value="uiRtMapZonesShowV1Toggle"
                                    color="primary"
                                />
                                (DEV ONLY) OLD_ZONES SHOW
                            </div>
                            <div className={classes.group}>
                                <Checkbox
                                    //disabled={true}
                                    checked={!!uiRtMapZonesV1Enabled}
                                    onChange={this._handleToggleChange}
                                    value="uiRtMapZonesEnabledV1Toggle"
                                />
                                (DEV) OLD_ZONES Enabled
                            </div>
                            <div className={classes.group}>
                                <Checkbox
                                    checked={!!uiMissionsV2Show}
                                    onChange={this._handleToggleChange}
                                    value="uiMissionsV2ShowToggle"
                                    color="primary"
                                />
                                (DEV ONLY) MISSIONS_V2 SHOW
                            </div>
                            <div className={classes.group}>
                                <Checkbox
                                    //disabled={true}
                                    checked={!!uiMissionsV2Enabled}
                                    onChange={this._handleToggleChange}
                                    value="uiMissionsV2EnabledToggle"
                                />
                                (DEV ONLY) MISSIONS_V2 Enabled
                            </div>
                            <div className={classes.group}>
                                <Checkbox
                                    checked={!!uiZonesV2Show}
                                    onChange={this._handleToggleChange}
                                    value="uiZonesV2ShowToggle"
                                    color="primary"
                                />
                                (DEV ONLY) ZONES_V2 SHOW
                            </div>
                            <div className={classes.group}>
                                <Checkbox
                                    //disabled={true}
                                    checked={!!uiZonesV2Enabled}
                                    onChange={this._handleToggleChange}
                                    value="uiZonesV2EnabledToggle"
                                />
                                (DEV) ZONES_V2 Enabled
                            </div>

                            <div className={classes.group}>
                                <Checkbox
                                    checked={!!uiLiveAbsbExchangeShow}
                                    onChange={this._handleToggleChange}
                                    value="uiLiveAbsbExchangeShowToggle"
                                    color="primary"

                                />
                                (DEV) LIVE_ADBS_EXCHANGE SHOW
                            </div>
                            <div className={classes.group}>
                                <Checkbox
                                    //disabled={true}
                                    checked={!!uiLiveAbsbExchangeEnabled}
                                    onChange={this._handleToggleChange}
                                    value="uiLiveAbsbExchangeEnabledToggle"
                                />
                                (DEV) LIVE_ADBS_EXCHANGE Enabled
                            </div>

                            <div className={classes.group}>
                                <Checkbox
                                    checked={!!uiRtdmCreotechTicksShow}
                                    onChange={this._handleToggleChange}
                                    value="uiRtdmCreotechTicksShowToggle"
                                    color="primary"
                                />
                                (DEV) RTDM_CREOTECH SHOW
                            </div>
                            <div className={classes.group}>
                                <Checkbox
                                    //disabled={true}
                                    checked={!!uiRtdmCreotechTicksEnabled}
                                    onChange={this._handleToggleChange}
                                    value="uiRtdmCreotechTicksEnabledToggle"
                                />
                                (DEV) RTDM_CREOTECH Enabled
                            </div>


                            <div className={classes.group}>
                                <Checkbox
                                    checked={!!uiRtdmSoapLprUnitsTicksShow}
                                    onChange={this._handleToggleChange}
                                    value="uiRtdmSoapLprUnitsTicksShowToggle"
                                    color="primary"
                                />
                                (DEV) RTDM_LPR_UNITS SHOW
                            </div>
                            <div className={classes.group}>
                                <Checkbox
                                    //disabled={true}
                                    checked={!!uiRtdmSoapLprUnitsTicksEnabled}
                                    onChange={this._handleToggleChange}
                                    value="uiRtdmSoapLprUnitsTicksEnabledToggle"
                                />
                                (DEV) RTDM_LPR_UNITS Enabled
                            </div>

                            <div className={classes.group}>
                                <Checkbox
                                    checked={!!uiRtdmExchangeTicksShow}
                                    onChange={this._handleToggleChange}
                                    value="uiRtdmExchangeTicksShowToggle"
                                    color="primary"
                                />
                                (DEV) RTDM_EXCHANGE_TICKS_SHOW
                            </div>
                            <div className={classes.group}>
                                <Checkbox
                                    //disabled={true}
                                    checked={!!uiRtdmExchangeTicksEnabled}
                                    onChange={this._handleToggleChange}
                                    value="uiRtdmExchangeTicksEnabledToggle"
                                />
                                (DEV) RTDM_EXCHANGE_TICKS Enabled
                            </div>


                            {/*<div className={classes.group}>*/}
                                {/*<Checkbox*/}
                                    {/*disabled={true}*/}
                                    {/*checked={!!uiRtdmSourcesUseSocketIo}*/}
                                    {/*//onChange={this._handleToggleChange}*/}
                                    {/*value="uiRtdmSourcesUseSocketIoToggle"*/}
                                {/*/>*/}
                                {/*(DEV) RTDM_SOURCES SocketIo Enabled switch.. hmm*/}
                            {/*</div>*/}

                            {/*<div className={classes.group}>*/}
                            {/*    <Checkbox*/}
                            {/*        disabled={true}*/}

                            {/*        checked={!!uiMatzShow}*/}
                            {/*        onChange={this._handleToggleChange}*/}
                            {/*        value="uiMatzShowToggle"*/}
                            {/*        color="primary"*/}
                            {/*    />*/}
                            {/*    MATZ Zones*/}
                            {/*</div>*/}
                            </div>
                            }

                        </FormControl>
                    </CardContent>
                </Card>
                {/*Dev info*/}
                <Card style={{marginTop: "5px", display: "none"}}>
                    <div>
                        <code>
                            <div>Dev info</div>
                            {/*{JSON.stringify(this.state.info, undefined, 2)}*/}
                        </code>
                    </div>
                </Card>
            </ConfigContainer>
        )
    }
}



const mapStateToProps = (state, props) => {
    //console.log('ConfigTab mapStateToProps', state, props);

    return {
        //mapUxConfig: Object.assign({},state.mapUxConfig),
        mySelf: state.mySelf,
        mapUxConfig: state.mapUxConfig,
        myAppExtras: state.myAppExtras
        // uiVolume: state.mapUxConfig.uiVolume,
        // uiSize: state.mapUxConfig.uiSize,
        // uiMatzShow: state.mapUxConfig.uiMatzShow,
        //
        // uiTickEnabled: state.mapUxConfig.uiTickEnabled,
        //
        // uiAlertsV1Show: state.mapUxConfig.uiAlertsV1Show,
        // uiAlertsV1Enabled: state.mapUxConfig.uiAlertsV1Enabled,
        //
        // uiCheckinsV1Show: state.mapUxConfig.uiCheckinsV1Show,
        // uiCheckinsV1Enabled: state.mapUxConfig.uiCheckinsV1Enabled,
        //
        // uiLprHelipadsV1Show: state.mapUxConfig.uiLprHelipadsV1Show,
        // uiLprHelipadsV1Enabled: state.mapUxConfig.uiLprHelipadsV1Enabled,
        //
        // uiMissionsV2Show: state.mapUxConfig.uiMissionsV2Show,
        // uiMissionsV2Enabled: state.mapUxConfig.uiMissionsV2Enabled,
        //
        // uiRtMapZonesV1Show: state.mapUxConfig.uiRtMapZonesV1Show,
        // uiRtMapZonesV1Enabled: state.mapUxConfig.uiRtMapZonesV1Enabled,
        //
        // uiZonesV2Show: state.mapUxConfig.uiZonesV2Show,
        // uiZonesV2Enabled: state.mapUxConfig.uiZonesV2Enabled,
        //
        // uiLiveAbsbExchangeEnabled:state.mapUxConfig.uiLiveAbsbExchangeEnabled,
        // uiLiveAbsbExchangeShow: state.mapUxConfig.uiLiveAbsbExchangeShow,
        //
        // uiRtdmSourcesEnabled: state.mapUxConfig.uiRtdmSourcesEnabled,
        // uiRtdmSourcesUseSocketIo: state.mapUxConfig.uiRtdmSourcesUseSocketIo,
        // uiRtdmSourcesShow: state.mapUxConfig.uiRtdmSourcesShow,
    }
};


const enhance = compose(
    //translate,
    withStyles(styles),
    connect(
        mapStateToProps,
        {
            storeUiVolume,
            storeUiSize,

            storeUiShowMatz,
            storeUiTickEnabled,

            storeUiAlertsV1Show,
            storeUiAlertsV1EnableRq,
            storeUiAlertsV2Show,
            storeUiAlertsV2EnableRq,
            storeUiCheckinsV1Show,
            storeUiCheckinsV1EnableRq,
            storeUiCheckinsV2Show,
            storeUiCheckinsV2EnableRq,
            storeUiLprHelipadsV1Show,
            storeUiLprHelipadsV1EnableRq,

            storeUiMissionsV2Show,
            storeUiMissionsV2EnableRq,
            storeUiRtMapZonesV1Show,
            storeUiRtMapZonesV1EnableRq,
            storeUiZonesV2Show,
            storeUiZonesV2EnableRq,

            storeUiLiveAbsbExchangeShow,
            storeUiLiveAbsbExchangeEnableRq,


            storeUiRtdmCreotechTicksShow,
            storeUiRtdmCreotechTicksEnableRq,

            storeUiRtdmSoapLprUnitsTicksShow,
            storeUiRtdmSoapLprUnitsTicksEnableRq,

            storeUiRtdmExchangeTicksShow,
            storeUiRtdmExchangeTicksEnableRq
        },
        // null,
        // {
        //     areStatesEqual: (prev, next) => {
        //         console.log('comparing states', prev, next)
        //         console.log('shallowEqual1', shallowEqual(prev, next))
        //         console.log('shallowEqual2', shallowEqual(prev.mapUxConfig, next.mapUxConfig))
        //         return (
        //             shallowEqual(prev.mapUxConfig, next.mapUxConfig)
        //         );
        //     }
        // }
    )
);

export default enhance(ConfigTab);
