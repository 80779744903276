import React from "react";
import withStyles from "@material-ui/core-v3/styles/withStyles";
import Card from "@material-ui/core-v3/Card/Card";
import CardContent from "@material-ui/core-v3/CardContent/CardContent";
import PropTypes from "prop-types";
import Typography from "@material-ui/core-v3/Typography/Typography";
import {dateTimeFormatter} from "../../../dr_ra2/utils/momentHelpers";
import {connect} from "react-redux";
import compose from 'recompose/compose';
import {EmergencyButton} from "../prepared-components/MultiEmergencyPanel";
import {get, find} from 'lodash'
const styles = {
    layoutRow: {
        display: "flex",
        flexDirection: "row",
    },
    alertCard: {
        width: "100%",
        marginBottom: "2em",
    },
    hr: {
        alignSelf: "center",
        flex: "1"
    },
    dotsBefore: {
        color: "#888",
        marginLeft: "5px",
        marginRight: "5px",
        display: "block",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "clip",
    },
    redHeader: {
        backgroundColor: "red",
        color:"white",
    },
    //ugh, cp/paste
    emergencyButtonStart: {
        backgroundColor: "rgb(244, 67, 54)",
        color: "rgb(255, 255, 255)",
        width: "100%",
        marginBottom: "2em"
    },
    emergencyButtonStop: {
        backgroundColor: "rgb(128,0,128)",
        color: "rgb(255, 255, 255)",
        width: "100%",
        marginBottom: "2em"
    },
};

//btn to refactor -> used with main emergency view
class AlertV2View extends React.Component {

    render() {

        const {classes, inspectData, mySelf, atcZoneAlerts} = this.props;
        console.log('====alert view', inspectData, mySelf);
        const { alert } = inspectData;
        const zoneName = get(alert, 'etc.zoneName', false);
        const namedAlert = !!(zoneName);
        const alertTxt = `${(namedAlert)?alert.etc.zoneName+' ':''}${alert.etc.alertTxt || ''}`;

        const atcZone = find(atcZoneAlerts, ['name', zoneName]);

        console.log('atcZone', atcZone);

        return (
            <div>
                <Card className={classes.alertCard}>
                    <CardContent className={classes.redHeader}>
                        <Typography variant="inherit">
                            {alertTxt}
                        </Typography>
                        <Typography variant="inherit">
                            {`id: ${alert.uid}`}
                        </Typography>
                    </CardContent>
                    <CardContent>
                        <div className={classes.layoutRow}>
                            <Typography variant="inherit">issued by:</Typography>
                                <span className={classes.hr}>
                                    <div className={classes.dotsBefore}>
                                    </div>
                                </span>
                            <Typography variant="inherit">{alert.etc.startedBy}</Typography>
                        </div>
                        <div className={classes.layoutRow}>
                            <Typography variant="inherit">started:</Typography>
                                <span className={classes.hr}>
                                    <div className={classes.dotsBefore}>
                                    </div>
                                </span>
                            <Typography variant="inherit">{dateTimeFormatter(alert.start, {useUtc:true})}</Typography>
                        </div>
                        {(alert.stop)
                            ?
                            <div className={classes.layoutRow}>
                                <Typography variant="inherit">until:</Typography>
                                    <span className={classes.hr}>
                                        <div className={classes.dotsBefore}>
                                        </div>
                                    </span>
                                <Typography variant="inherit">{dateTimeFormatter(alert.stop, {useUtc:true})}</Typography>
                            </div>
                            :null
                            }
                    </CardContent>
                </Card>
                {
                    (atcZone && atcZone.alertData) //hotfix, saga should clean OR better update inspect element
                        ? <EmergencyButton alertData={alert} zoneName={alert.etc.zoneName} classes={classes} />
                        :null
                }
            </div>
        )
    }
}


const mapStateToProps = (state, props) => ({
    mySelf : state.mySelf,
    atcZoneAlerts : state.alertsV2.atcZoneAlerts
});

const enhance = compose(
    connect(mapStateToProps),
    withStyles(styles)
);

export default enhance(AlertV2View);
