//old atc functionality
//ugh, rewritten roughly from angular (v1.0)

import { serializePrimitive } from "../../gl_map/glSetups/deckPrimitives/DeckPrimitiveSerialization";
import {
    EMIT_SIGNALS,
    RADIO_VALUES_SUBTYPES,
    RADIO_VALUE_TEXTS,
    ALERT_TYPES,
    ALERT_STATUSES
} from "../constants/AlertsV2Enums";
import { durationMM2until, unixTime2Iso, unixTimeNow } from "./TimeConverters";
import {THREE_LIGHTS} from "../../modules2lib/threeLights";

export function transformCancelAlert({ userData, alertData }) {
    console.log("cancel alert here", alertData);
    const alertType = alertData.type;
    const emitSignal = EMIT_SIGNALS[alertType];
    //should be cleaned up , but since we are going to switch to new db.. later
    //MySocketIoV1Client.emit(emitSignal, {user:userData, status:0, alert:alertData});
    console.error("==== emit here fixme", { user: userData, emitSignal, status: 0, alert: alertData });
}

//CUSTOM_ALERT - fire and forget, custom geometry,
//OWNED_ALERT - named zone , cancel call required
//default Status LAND_NOW
export function transformCreateAlertForm({ payload, zoneTypesV2, zonesV2 }) {

    console.log('transformCreateAlertForm,', payload)

    const { alertData, timeout, radioValue, status = ALERT_STATUSES.LAND_NOW } = payload;
    const alertType = alertData.type;

    const emitSignal = EMIT_SIGNALS.CREATE_AREA_ALERT;

    //to review after fixing table
    const etc = {
        emitSignal,
        alertType,
        alertSubtype:radioValue,
    };

    const ret = {
        etc,
        status,
    };

    let start, stop;
    start = payload.start || unixTimeNow();

    if (alertType === ALERT_TYPES.ZONE_ALERT) {
        //etc.aglMax = 1000; //m
        //getting zone geometry probably should be on backend
        console.log('find zone in ;', zonesV2);

        const foundZone = zonesV2.rawData.find(el => el.inspectData.name === alertData.zoneName);

        if (foundZone) {
            console.log('found', foundZone);
            ret.etc.zoneName = alertData.zoneName;
            ret.geometry = foundZone.inspectData.zoneRecord.geometry;
            ret.zoneid = foundZone.inspectData.zoneRecord.uid;

        } else {
            throw Error('cannot find named zone data');
        }

    } else if (alertType === ALERT_TYPES.CUSTOM_ALERT) {
        const primitive = alertData.primitive;
        console.log("primitive", primitive);
        const serializedPrimitive = serializePrimitive(primitive);
        console.warn("serializedPrimitive", serializedPrimitive);
        ret.geometry = serializedPrimitive.wkb;
        stop = payload.stop || durationMM2until(timeout, start);
    }

    ret.start = unixTime2Iso(start);

    if (stop) {
        ret.stop = unixTime2Iso(stop);
    }

    console.log('ret', ret);

    return ret;

}
