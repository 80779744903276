import React, {Component} from "react";
import * as PropTypes from "prop-types";
import Button from "@material-ui/core-v3/Button/Button";
import withStyles from "@material-ui/core-v3/styles/withStyles";
import compose from 'recompose/compose';
import {connect} from "react-redux";

import {cancelAlertV2Rq} from "../../../LogicV1Redux/actions/AlertsV2Actions";
import {whitelistedUserTypes} from "./EmergencyPanel";
import {get} from "lodash";
import MyCollapse from './MyCollapse';
import {timeFormatter} from "../../../dr_ra2/utils/momentHelpers";
import PubSub from "pubsub-js";
import {PubSubSignals} from "../../../LogicV1Redux/constants/PubSubSignals";
import Typography from "@material-ui/core-v3/Typography/Typography";
import ExpandMoreIcon from "@material-ui/icons-v3/ExpandMore";
import classnames from "classnames";
import Collapse from "@material-ui/core-v3/Collapse/Collapse";

const styles = theme => ({
    //MyCollapse
    collapseDetails: {
        willChange: "content"
    },
    expand: {
        transform: 'rotate(0deg)',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
        [theme.breakpoints.up('sm')]: {
            marginRight: -8,
        },
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    collapseButton: {
        padding: "unset",
        paddingRight: "8px",
        willChange: "transform",
        marginBottom: "2em"
    },
    //EmergencyPanel
    root: {
    },
    emergencyButtonStart: {
        backgroundColor: "rgb(244, 67, 54)",
        color: "rgb(255, 255, 255)",
        width: "100%",
        marginBottom: "2em"
    },
    emergencyButtonStop: {
        backgroundColor: "rgb(128,0,128)",
        color: "rgb(255, 255, 255)",
        width: "100%",
        marginBottom: "2em"
    },
    layoutRow: {
        display: "flex",
        flexDirection: "row",
    },
    hr: {
        alignSelf: "center",
        flex: "1"
    }
});

export const EmergencyButton = ({classes, alertData, zoneName }) => {
    const emergencyButtonClass = (alertData)
        ? classes.emergencyButtonStop
        : classes.emergencyButtonStart;
    const msg = (alertData)
        ? `CANCEL ${zoneName} EMERGENCY BAN`
        : `${zoneName} EMERGENCY`;
    return (
        <Button className={emergencyButtonClass}
                onClick={() => {
                    if (alertData) {
                        PubSub.publish(PubSubSignals.CANCEL_OWNED_ALERT_MODAL, alertData)
                    } else {
                        PubSub.publish(PubSubSignals.CREATE_OWNED_ALERT_DIALOG, {zoneName})
                    }
                }}
        >
            {msg}
        </Button>
    )
};

class MultiEmergencyPanel extends Component {

    state = {
        isExpanded: false
    };

    _handleExpandClick = () => {
        this.setState({
            isExpanded: !this.state.isExpanded
        });
    };

    render() {

        const {isExpanded} = this.state;
        const {children, title, style} = this.props;

        const {atcZoneAlerts, classes, btnOnly, mySelf, visible} = this.props;

        if (visible === false) return null;

        const userType = get(mySelf, 'pandora.type', false);

        //whitelist!
        if (whitelistedUserTypes.indexOf(userType) === -1 ) {
            return null;
        }
        console.log('multi emergency panel', this.props);

        const emergencyBtns = [];
        for (let i = 0; i < atcZoneAlerts.length; i++) {
            const alertData = atcZoneAlerts[i].alertData;
            const zoneName = atcZoneAlerts[i].name;
            emergencyBtns.push(<EmergencyButton key={`ebtn-${zoneName}`} classes={classes} zoneName={zoneName} alertData={alertData}/>)
        }

        console.log('atcZoneAlerts', atcZoneAlerts);

        return (
            <div className={classes.collapseDetails} style={style}>
                <span className={classes.layoutRow} style={{width:'100%'}}>

                <Button
                    onClick={this._handleExpandClick}
                    aria-expanded={isExpanded}
                    aria-label="Show more"
                    size="small"
                    fullWidth = {true}
                    className={classes.collapseButton}
                >
                        <hr className={classes.hr}/>
                    &emsp;CTR / RPA ALARM&emsp;
                        <hr className={classes.hr}/>
                    <ExpandMoreIcon
                        className={classnames(classes.expand, {
                            [classes.expandOpen]: isExpanded,
                        })}/>
                </Button>
                </span>

                <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                    {emergencyBtns}
                </Collapse>
            </div>

        )
    };
}


MultiEmergencyPanel.propTypes = {
    visible: PropTypes.bool
};

MultiEmergencyPanel.defaultProps = {
    visible: true
};

const mapStateToProps = state => {
    //console.log('EmergencyPanel mapStateToProps', state);
    return {
        //myAuth : state.myAuth,
        mySelf : state.mySelf,
        //myAppExtras : state.myAppExtras,
        atcZoneAlerts: state.alertsV2.atcZoneAlerts
    }
};

const enhance = compose(
    //translate,
    withStyles(styles),
    connect(mapStateToProps, {
        cancelAlertV2Rq,
    })
);

export default enhance(MultiEmergencyPanel);
