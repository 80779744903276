import React, {createElement} from "react";
import PubSub from "pubsub-js";
//import {Notification} from "ra-ui-materialui";
import sizeMe from "react-sizeme";

import {  userLogin} from 'ra-core';

import {connect} from 'react-redux';
import { Notification, defaultTheme } from 'react-admin';
import {
    showNotification,
    hideNotification,
} from 'react-admin';

//import { translate } from 'ra-core';
import compose from 'recompose/compose';
import { Authenticated, WithPermissions } from 'react-admin';
import PandoraWindow from './PandoraWindow'

import {getV2AuthParams} from "../../LogicV1Redux/oldLogic/dirtyAuth";
import {getAuthFromUrl, getAuthHeaderV1, requestPandoraUser} from "../../LogicV1Redux/oldLogic/dirtyAuth";
import StoreProvider from "../../dr_ra2/MyStoreProvider";
import {myAuthStoreSelf} from "../../LogicV2/auth/MyExtraAuthActions";

//console.warn('fixme auth flow!');



const AuthTest = (props) => {
    console.log('AuthTest', props);

    return (<div>
        user:{props.user}
        pass:{props.pass}
    </div>)
};


const PandoraWithPermissions = ({ location, match }) => {
    //getting permisssions also resolves myself data from loopback!
    console.log('===with permissions. ', location, match)
    return (
    <WithPermissions
        //authParams={{ key: match.path,}}
        // location is not required but it will trigger a new permissions check if specified when it changes
        //location={location}
        render={({ permissions }) => {
                if (!permissions) return null;
                return <PandoraWindow permissions={permissions}/>
            }
        }
    />
)};

// was fast cleaned to single login (only lb), due to failure on tsrtm, should be reviewed
class Main extends React.Component {

    state = {
        processingAuth: true,
        //authOk:false
    };

    componentDidMount() {

        console.warn('sprint -> merging missions in CheckInRowRenderer shouldnt be onrender!');
        //in multiple places connectedMission
        //but missions chaange frequently!

        //huh string query works, so pandora should be ok,
        // TODO? add persisted myauth and logout (roles!) button just for dev? for now not needed.
        //const urlAuth = {user: "test.civ.atc", pass: "zab-zupa"};
        const urlAuth = getAuthFromUrl();

        const storeAuth = this.props.mySelf.pandoraAuth;
        console.log('urlAuth', urlAuth);
        console.log('storeAuth', storeAuth);
        if (urlAuth || storeAuth) {
            console.log("processingAuth setting true");

            this.setState({
                processingAuth: true,
                urlAuth, storeAuth
                //authOk:false,
            });

            const usedAuth = urlAuth || storeAuth;

            if (!this.props.myAuth.token) {
                console.log('trying to lb login', this.props.myAuth);
                //fromQueryString (meaning pandora console string login) may be useful distinction soon
                this.props.dispatchLoginAction({username:usedAuth.user, password:usedAuth.pass, fromQueryString:!!(urlAuth)});
            }

        } else {
            console.log("processingAuth setting false");

            this.setState({
                processingAuth: false,
                //authOk:false,
            })
        }

    }

    render() {

        //ugh.
        //this component tries to get login data for pandora window.. (old users)
        //<Authenticated> redirects to lb2 login.
        //which sets login data and redirects back...

        console.log("rtmap main", this.props);
        //const { width, height } = this.props.size;
        const { location, myAuth, mySelf } = this.props;

        const { token } = myAuth;
        const {processingAuth} = this.state;
        const pandoraAuthOk = !!(token);

        let processing = processingAuth;

        processing = (pandoraAuthOk) ? false : processing;

        console.log("processingAuth val", processingAuth);
        console.log("pandoraAuthOk val", pandoraAuthOk);

        // return (
        //     <code><pre>
        //         {JSON.stringify({
        //             processingAuth,
        //             pandoraAuthOk,
        //             myAuth,
        //             state:this.state
        //         },  null, 2)}
        //     </pre></code>
        //
        // )

        return (!processing)
            ? ( <Authenticated authParams={{ isRtMap: true , pandoraAuthOk}} location={location}>
                    <PandoraWithPermissions location={location}/>
                </Authenticated>)
            : <div>processing auth</div>
    }
}

const mapDispatchToProps = dispatch =>
    (
        {
            dispatchLoginAction : (e) => {
                console.log('dispatchin dispatchLoginAction', e);
                dispatch(userLogin(e));
            },
            showNotification : (msg, type) => {
                dispatch(showNotification(msg, type));
            }
        }
    );

const mapStateToProps = state => {
    return {
        //isLoading: state.admin.loading > 0,
        myAuth: state.myAuth,
        mySelf: state.mySelf,
    }
};
//
const enhance = compose(
    //translate,
    //muiThemeable(), // force redraw on theme change
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
);

//export default sizeMe({ monitorHeight: true })(enhance(Main));
export default enhance(Main);
