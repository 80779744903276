import React from 'react';
import Button from '@material-ui/core-v3/Button';
import Dialog from '@material-ui/core-v3/Dialog';
import DialogActions from '@material-ui/core-v3/DialogActions';
import DialogContent from '@material-ui/core-v3/DialogContent';
import DialogContentText from '@material-ui/core-v3/DialogContentText';
import DialogTitle from '@material-ui/core-v3/DialogTitle';
import PropTypes from "prop-types";
import { withStyles } from '@material-ui/core-v3/styles';
import AlertSubtypesRadioSelection from "./AlertSubtypesRadioSelection";
import MyCustomSlider from "./MyCustomSlider";
import IconButton from "@material-ui/core-v3/IconButton/IconButton";
import CloseIcon from "@material-ui/icons/Close"
import { clearAllEditablePrimitives} from "../../../gl_map/glSetups/deckDraw/deckDrawStoreBridge";
import PubSub from "pubsub-js";
import {AlertTypes, emitAlert} from "../../../LogicV1Redux/oldLogic/AlertsV1Sending";
import {PubSubSignals} from '../../../LogicV1Redux/constants/PubSubSignals'
import compose from 'recompose/compose';
import {connect} from "react-redux";
import {bindActionCreators} from 'redux'
import {postAlertV2Rq} from '../../../LogicV1Redux/actions/AlertsV2Actions'
import {setOwnedAlert} from '../../../LogicV1Redux/actions/AlertsV1Actions'
import {ALERT_SUBTYPES, ALERT_TYPES} from "../../../LogicV1Redux/constants/AlertsV2Enums";

const styles = {
    dialogLabel: {
        backgroundColor: 'red',
        color: 'white',
        fontColor: 'white',
        fontStyle: 'bold'
    },
    landButton: {
        backgroundColor: 'red',
        color: 'white'
    },
    dialogContent: {
        margin: "15px"
    },
    dialog: {

    },
    closeButton: {
        position: 'absolute',
        right: '0px',
        top: '0px',
    }
};

const defaultMinutes = 5;

//huh


//dirty alert types flow
class LandNowDialogBase extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            open: false,
            radioValue: ALERT_SUBTYPES.LPR,
            sliderMinutes: props.sliderMinutes
        };
    };

    componentWillMount() {

        this.pubsubCustomAlert = PubSub.subscribe(PubSubSignals.CREATE_CUSTOM_ALERT_DIALOG, (msg, data) => {
            console.log('custom alert data', data);
            this.setState({
                open: true,
                sliderMinutes: defaultMinutes, //reset on reopen
                alertData: {type:ALERT_TYPES.CUSTOM_ALERT, ...data}
            });
        });

        this.pubsubOwnedAlert = PubSub.subscribe(PubSubSignals.CREATE_OWNED_ALERT_DIALOG, (msg, data) => {
            console.log('owned alert data', data);
            this.setState({
                open: true,
                sliderMinutes: undefined, //reset on reopen
                alertData: {type:ALERT_TYPES.ZONE_ALERT, ...data}
            });
        });
        this.pubsubCloseDialog = PubSub.subscribe(PubSubSignals.CLOSE_CREATE_ALERT_DIALOG, (msg, data) => {
            console.log('closing create alert dialog');
            clearAllEditablePrimitives();
            this.setState({
                open: false,
            });
        });
    }

    componentWillUnmount() {
        PubSub.unsubscribe(this.pubsubCustomAlert);
        PubSub.unsubscribe(this.pubsubOwnedAlert);
        PubSub.unsubscribe(this.pubsubCloseDialog);
    }

    _handleSliderChange = (value) => {
        this.setState({sliderMinutes: value});
    };


    _handleRadioButtonChange = (value) => {
        this.setState({radioValue: value}) ;
    };


    _handleClose = () => {
        this.setState({ open: false });
        clearAllEditablePrimitives();
    };


    _handleLandButton = () => {
        console.log('landnow', this.state);
        //sending to server

        const data = {
            alertData: this.state.alertData,
            timeout: this.state.sliderMinutes, //currently not supported in case of named alert!
            radioValue: this.state.radioValue,
        };

        if (process.env.REACT_APP_USE_ALERTS_V1) {

            //temporarily emiting BOTH types (Not tested)
            //this.props.postAlertV2Rq(data);


            emitAlert(data);
            //assuming success (v1 bug here)
            this.setState({ open: false });
            clearAllEditablePrimitives();
        } else {
            //console.debug('using v2 alerts')
            this.props.postAlertV2Rq(data);
        }
    };

    render() {
        const { classes } = this.props;

        const { alertData = {} } = this.state;

        const zoneName = (alertData.zoneName)
            ? alertData.zoneName
            :  'custom';

        //const showTimeoutSlider = (alertData.type === AlertTypes.CUSTOM_ALERT); //only for custom alert

        const showTimeoutSlider = !(alertData.zoneName);

        return (
            <div>
                <Dialog
                    open={this.state.open}
                    onClose={this._handleClose}
                    aria-labelledby="form-dialog-title"
                    fullWidth
                    maxWidth="md"
                    className={classes.dialog}
                >
                    <DialogTitle id="form-dialog-title" className={classes.dialogLabel} disableTypography>Issue land-now order
                        <IconButton
                            key="close"
                            aria-label="Close"
                            color="inherit"
                            className={classes.closeButton}
                            onClick={this._handleClose}
                        >
                            <CloseIcon/>
                        </IconButton>
                    </DialogTitle>
                    <DialogContent className={classes.dialogContent}>
                        <DialogContentText>
                            Checked-in users in {zoneName} area will be notified, with following notification type:
                        </DialogContentText>
                        <AlertSubtypesRadioSelection
                            radioValue = {this.state.radioValue}
                            onRadioButtonChanged={this._handleRadioButtonChange}
                        />
                        {(showTimeoutSlider)
                            ?
                            <div>
                                <DialogContentText>
                                    for {this.state.sliderMinutes} minutes
                                </DialogContentText>
                                <MyCustomSlider value={this.state.sliderMinutes}
                                                onSliderChanged={this._handleSliderChange}
                                                max={120}
                                                min={1}
                                                step={1}/>
                            </div>
                            : null
                        }
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this._handleClose} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={this._handleLandButton} className={classes.landButton}>
                            Land Now
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}


LandNowDialogBase.propTypes = {
    classes: PropTypes.object.isRequired,
    visible: PropTypes.bool
};

LandNowDialogBase.defaultProps = {
    visible: false,
    sliderMinutes: defaultMinutes,
};

const mapDispatchToProps = (dispatch) => ({
    postAlertV2Rq: bindActionCreators(postAlertV2Rq, dispatch),
    postAlertV1Rq: bindActionCreators(setOwnedAlert, dispatch),
});


const mapStateToProps = state => {
    //console.log('EmergencyPanel mapStateToProps', state);
    return null
};

const enhance = compose(
    //translate,
    withStyles(styles),
    connect(null, mapDispatchToProps)
);

export default enhance(LandNowDialogBase);
