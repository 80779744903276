import React, {Component} from "react";
import Typography from "@material-ui/core-v3/Typography/Typography";
import withStyles from "@material-ui/core-v3/styles/withStyles";

import ZoneV2View from "./context-tab-views/ZoneV2View";

import LegInfoFixer from "./context-tab-views/LegInfoFixer"

import MissionV2View from "./context-tab-views/MissionV2View";
import AlertV2View from "./context-tab-views/AlertV2View";

import CheckinV2View from "./context-tab-views/CheckinV2View";
import ThreeLightsView from "./context-tab-views/ThreeLightsView"

import LiveAdsbView from "./context-tab-views/LiveAdsbView";
import RtdmExchangeAircraftView from './context-tab-views/RtdmExchangeAircraftView';
import RtdmSoapLprUnitView from './context-tab-views/RtdmSoapLprUnitView'
import RtdmCreotechTick from './context-tab-views/RtdmCreotechTick'
import RtdmAerobitsTick from './context-tab-views/RtdmAerobitsTick'

import {InspectTypes} from "../../modules2lib/common/";
import RtdmExchangeTickView from "./context-tab-views/RtdmExchangeTick";
import ErrorBoundary from "../../dr_ra2/components/ErrorBoundary";


const styles = {
    root: {
        display: "block",
        paddingLeft: 8*3,
        paddingRight: 8*3,
    }
};

const ContextContainer = ({ children, dir, className}) => {

    return (
        <Typography className={className} component="div" dir={dir}>
            {children}
        </Typography>
    );
};

const ZoneV2MultiView = ({inspectData}) => {
    console.log(inspectData);
    let views = [];
    let tempLightsData = [];
    const timeNow = new Date().getTime();

    //disabling for now...
    if (process.env.REACT_APP_ENABLE_LIGHTS) {

        const threeLightsView = <ThreeLightsView
            key="tlview"
            inspectData={inspectData}
        />;

        views.push(threeLightsView);
    }

    for (let i = 0; i < inspectData.pickedInfos.length; i++) {
        const info = inspectData.pickedInfos[i];
        let wrappedInspect = (info && info.object && info.object.inspectData)?info.object.inspectData:null;
        if (wrappedInspect && wrappedInspect.inspectType === InspectTypes.ZONEV2) {
            let v = <ZoneV2View key={wrappedInspect.name} inspectData={wrappedInspect}/>;

            //UI hack for duplicate pickedInfos key-render problems
            if(views.some(el=>el.key === v.key)) {
                console.warn("ZoneV2MultiView skipping duplicate zones, pickedInfos -> fixme!!!");
                continue;
            }

            views.push(v);
        }
    }

    return views;
};


const switchView = (inspectData) => {
    if (!inspectData) return null;
    switch (inspectData.inspectType) {

        case InspectTypes.ZONEV2:
            //return <ZoneV2View inspectData={inspectData}/>;
            return <ZoneV2MultiView inspectData={inspectData}/>;

        case InspectTypes.CHECKINV2:
            // fixme!
            // return <MissionCheckinTabs inspectData={inspectData}/>;
            //do not pass inspect data, have to have own connection.
            return <CheckinV2View/>;
        case InspectTypes.MISSIONV2:
            // fixme!
            // return <MissionCheckinTabs inspectData={inspectData}/>;

            return <MissionV2View inspectData={inspectData}/>;
        case InspectTypes.LIVE_ADSB:
            return <LiveAdsbView inspectData={inspectData}/>;
        case InspectTypes.RTDM_EXCHANGE_AIRCRAFT:
            return <RtdmExchangeAircraftView inspectData={inspectData}/>;
        case InspectTypes.RTDM_EXCHANGE_TICK:
            return <RtdmExchangeTickView inspectData={inspectData}/>;
        case InspectTypes.RTDM_SOAP_LPR_UNITS_TICK:
            return <RtdmSoapLprUnitView inspectData={inspectData}/>;
        case InspectTypes.RTDM_AEROBITS_TICK:
            return <RtdmAerobitsTick inspectData={inspectData}/>;
        case InspectTypes.RTDM_CREOTECH_TICK:
        case InspectTypes.RTDM_CREOTECH_AIRCRAFT:
            return <RtdmCreotechTick inspectData={inspectData}/>;
        case InspectTypes.MISSIONLEGFIXME:
            return <LegInfoFixer inspectData={inspectData} />;
        case InspectTypes.CUSTOM_ALERT_V2:
            return <AlertV2View inspectData={inspectData} />;
        default:
            return null //fixme?
    }
} ;

class ContextTab extends Component {

    render() {
        const {classes, inspectData} = this.props;

        return (
            <ContextContainer className={classes.root}>
                <ErrorBoundary>
                    {switchView(inspectData)}
                </ErrorBoundary>
            </ContextContainer>
        )
    }
}


//not connecting inspectData with redux here, as it is anyway required just one level higher (AtcTabs)...
export default withStyles(styles)(ContextTab)
