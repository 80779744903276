import React from "react";
import compose from 'recompose/compose';
import {RowList} from "./RowList";
import {PlannedBarsContext} from "./BarsController";



class PlannedListView extends React.Component {

    render() {
        return (
            <PlannedBarsContext.Consumer>
                {
                    plannedBars=>
                        <RowList visibleItems={plannedBars}/>
                }
            </PlannedBarsContext.Consumer>
        )
    }
}

export default compose(
    // connect(
    //     mapStateToProps,
    //     null
    // )
)(PlannedListView);