import React from "react";
import withStyles from "@material-ui/core-v3/styles/withStyles";
import Card from "@material-ui/core-v3/Card/Card";
import CardContent from "@material-ui/core-v3/CardContent/CardContent";
import Typography from "@material-ui/core-v3/Typography/Typography";
import {LatLng2Dms, m2ftCeiling, ft2m} from "../../../LogicV1Redux/oldLogic/GeoConverters";
import {dateTimeFormatter, timeFormatter, utcOffset} from "../../../dr_ra2/utils/momentHelpers";
import Button from "@material-ui/core-v3/Button/Button";
import ModifyDialogV2 from "../prepared-components/ModifyDialogV2";
import PubSub from "pubsub-js";
import {PubSubSignals} from '../../../LogicV1Redux/constants/PubSubSignals'
import {connect} from "react-redux";
import compose from 'recompose/compose';
import MissionV2View from "./MissionV2View";
import {InspectTypes} from "../../../modules2lib/common/";
import get from "lodash.get";
import {FlightStatusesV2} from "../../../LogicV1Redux/constants/CheckinsV2Enums";
import classnames from 'classnames'

import {postAtcReviewedCheckinV2} from '../../../LogicV1Redux/actions/CheckinsV2Actions'
import {OperatorPrivileges} from "../../../dr_ra2/utils/operatorHelpers";
import {AppConfig} from "../../AppRtMap";

const styles = {
    layoutRow: {
        display: "flex",
        flexDirection: "row",
    },
    hr: {
        alignSelf: "center",
        flex: "1"
    },
    droneCard: {
        width: "100%",
        marginBottom: "2em",
    },
    dots: {
        flex: 1,
        minWidth: 0,
        '&:before': {
            color: "#888",
            marginLeft: "5px",
            marginRight: "5px",
            display: "block",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "clip",
            content: "'. . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .'"
        }


    },
    greyCard: {
        backgroundColor: "rgb(245, 245, 245)"
    },
    acceptButton: {
        backgroundColor: "rgb(0, 200, 83)",
        color: "rgb(255, 255, 255)",
        boxSizing: "content-box",
        width: "100%",
        marginBottom: "2em"
    },
    modifyButton: {
        backgroundColor: "rgb(41, 98, 255)",
        color: "rgb(255, 255, 255)",
        boxSizing: "content-box",
        width: "100%",
        marginBottom: "2em"
    },
    rejectButton: {
        backgroundColor: "rgb(255, 152, 0)",
        color: "rgb(255, 255, 255)",
        boxSizing: "content-box",
        width: "100%",
        marginBottom: "2em"
    },
    holdButton: {
        backgroundColor: "rgb(110,106,106)",
        color: "rgb(255, 255, 255)",
        boxSizing: "content-box",
        width: "100%",
        marginBottom: "2em"
    },
    disabledButton: {
        backgroundColor: "#D3D3D3",
    }
};

//hmm, to rewrite probably
const JurisdictionActions = ({hasJurisdiction, isFis, handlers, isInFlight, status, classes}) => {

    const isAccepted = status === FlightStatusesV2.ACCEPTED;
    const isSeen = status === FlightStatusesV2.SEEN;
    const isModified = status === FlightStatusesV2.ATC_MODIFIED;
    const isRejected = status === FlightStatusesV2.REJECTED;
    const isLostControl = status === FlightStatusesV2.LOST_CONTROL;

    //what about non-ctr prohibited?
    const fisCanDoAction = (status === FlightStatusesV2.CREATED);

    if (!hasJurisdiction) {
        return <Button disabled={true} className={classes.droneCard}>OUTSIDE JURISDICTION</Button>
    }

    if (isFis) {
        return (
            <Button className={
                        classnames(classes.acceptButton,
                            {
                                [classes.disabledButton]: !fisCanDoAction,
                            })
                    }
                    onClick={handlers['seenButton']}
                    disabled={(!fisCanDoAction)}
            >
                CHECK-IN SEEN
            </Button>);
    } else {
        //experimental hold support
        const holdSupport = !!(process.env.REACT_APP_HOLD_SUPPORT);
        return (
            <>
                <Button className={
                            classnames(classes.acceptButton,
                                {
                                    [classes.disabledButton]: isAccepted || isSeen || isLostControl,
                                })
                        }
                        onClick={handlers['acceptButton']}
                        disabled={(isAccepted || isSeen || isLostControl)}
                >
                    ACCEPT
                </Button>
                <Button className={
                            classnames(classes.modifyButton,
                                {
                                    [classes.disabledButton]: isLostControl,
                                })
                        }
                        onClick={handlers['modifyButton']}
                        disabled={isLostControl}
                >
                    MODIFY
                </Button>
                <Button className={
                            classnames(classes.rejectButton,
                                {
                                    [classes.disabledButton]: isRejected || isLostControl,
                                })
                        }
                        onClick={handlers['rejectButton']}
                        disabled={isRejected || isLostControl}
                >
                    {/* err? */}
                    {isInFlight && (isAccepted || isModified || isSeen || isRejected) ? 'LAND NOW' : 'REJECT'}
                </Button>
                {(holdSupport)
                    ?
                    <Button className={classes.holdButton} onClick={handlers['holdButton']}>HOLD</Button>
                    : null
                }
            </>
        )
    }
};

class CheckinV2View extends React.Component {

    _handleModifyButton = () => {
        PubSub.publish(PubSubSignals.MODIFY_PERMISSION_DIALOG, {});
    };

    _handleAcceptButton = () => {
        const {selectedCheckinV2, postAtcReviewedCheckinV2} = this.props;
        postAtcReviewedCheckinV2({selectedCheckinV2, status:FlightStatusesV2.ACCEPTED});
        //fixme blur on processing

    };

    _handleSeenButton = () => {
        const {selectedCheckinV2, postAtcReviewedCheckinV2} = this.props;
        postAtcReviewedCheckinV2({selectedCheckinV2, status:FlightStatusesV2.SEEN});
        //fixme blur on processing

    };

    _handleRejectButton = () => {
        const {selectedCheckinV2, postAtcReviewedCheckinV2} = this.props;
        postAtcReviewedCheckinV2({selectedCheckinV2, status:FlightStatusesV2.REJECTED});
        //fixme blur on processing

    };

    //experimental support
    _handleHoldButton = () => {
        console.error('implement me')
        const {selectedCheckinV2} = this.props;
    };


    render() {

        const handlers = {
            'acceptButton':this._handleAcceptButton,
            'seenButton':this._handleSeenButton,
            'modifyButton':this._handleModifyButton,
            'rejectButton':this._handleRejectButton,
            'holdButton':this._handleHoldButton
        };

        console.log('==CheckinV2View props', this.props);
        const {classes, selectedCheckinV2, mySelf, missionsV2} = this.props;

        const inspectData = selectedCheckinV2;

        if (!inspectData) return null;

        const checkinRecord = inspectData.checkinRecord;

        const status = inspectData.__status;

        const checkinLatLng = inspectData.__latLng;
        const llDMS = inspectData.__latLngDMS;

        const lastModAtcUser = inspectData.__checkedBy || 'N/A';
        const weightInfo = inspectData.__weightClass || 'N/A';
        const missionApproval = checkinRecord.missionapproval || 'N/A';
        const transponderIcao = checkinRecord.icao|| 'N/A';
        const registry = checkinRecord.registry|| 'N/A';

        const isCheckinChecked =  inspectData.__checkedBy;

        const isInFlight = (inspectData.__isBeforeStart || inspectData.__isAfterStop);

        const isCheckinRejected =  status === FlightStatusesV2.REJECTED;
        const isCheckinLostControl=  status === FlightStatusesV2.LOST_CONTROL;
        const isSeen = status === FlightStatusesV2.SEEN;

        const checkinNoLongerValid = inspectData.__outdated;

        const droneLostBattery = checkinRecord.etc.droneLostBattery  || 'N/A'; //can be dynamic from modified date.
        const droneLostSpeed = checkinRecord.etc.droneLostSpeed || 'N/A';

        const extraInfo = checkinRecord.etc.extraInfo;

        console.log ('missionsV2', missionsV2);

        const connectedMission = missionsV2.rawData.find(el => checkinRecord.missionid !== null && el.missionRecord.uid === checkinRecord.missionid);

        console.warn('connected mission' , connectedMission);

        const fakeMissionInspect = (connectedMission)?{mission:connectedMission, inspectType:InspectTypes.MISSIONV2}:null;

        //TODO unify with _isOutsideJurisdictionCheckinConnected in BarsController.
        // refactoring usage (moving to logic) should ensure correct mission/checkin cross-reference update (connected mission).

        const hasJurisdiction = (inspectData.__inJurisdiction || (connectedMission && connectedMission.__inJurisdiction));

        //console.warn('==has jurisdiciton', inspectData.checkin, hasJurisdiction);
        const userType = get(mySelf, 'pandora.type', false);

        //const privileges = get(checkinRecord, 'operatorHack.priv');
        const privileges = checkinRecord.priv;
        const checkinOwnerPrivileges = new OperatorPrivileges(privileges);
        const isSuavo = checkinOwnerPrivileges.isSuavo();

        //aargh
        const checkedStatusByString = (isCheckinRejected)
            ?'rejected by'
            : (isSeen)?'seen by':'accepted by';

        //hmm
        const isFis = (userType && userType === 'FIS');

        const callsign = get(checkinRecord, 'callsign', '');

        return (
            <div>

                <Card className={classes.droneCard}>
                    <CardContent>
                        <Typography variant="title">Checkin v2 details</Typography>
                    </CardContent>
                    <CardContent className={classes.greyCard}>
                        <Typography variant="inherit">
                            <b>{checkinRecord.operator}</b>
                        </Typography>
                        {
                            isSuavo &&
                            <Typography variant="button">
                                SUAVO
                            </Typography>
                        }
                        <div>
                            <Typography variant="inherit">
                                {checkinRecord.phone}
                            </Typography>
                            <Typography variant="inherit">
                                {`[${llDMS[1]}, ${llDMS[0]}]`}
                            </Typography>
                            <Typography variant="inherit">
                                {`[${checkinLatLng[1]}, ${checkinLatLng[0]}]`}
                            </Typography>
                            {(extraInfo) ?
                                <Typography variant="inherit">
                                    <i>{extraInfo}</i>
                                </Typography>
                                :null
                            }
                            <Typography variant="inherit">
                                {status}
                            </Typography>
                        </div>
                    </CardContent>

                    <CardContent>
                        {
                            AppConfig.callsignEngineStatic &&
                            <div className={classes.layoutRow}>
                                <Typography variant="inherit">callsign</Typography>
                                <span className={classes.dots}/>
                                <Typography variant="inherit" >{callsign}</Typography>
                            </div>
                        }
                        <div className={classes.layoutRow}>
                            <Typography variant="inherit">weight</Typography>
                            <span className={classes.dots}/>
                            <Typography variant="inherit" >{weightInfo}</Typography>
                        </div>
                        <div className={classes.layoutRow}>
                            <Typography variant="inherit">registry</Typography>
                            <span className={classes.dots}/>
                            <Typography variant="inherit">{registry}</Typography>
                        </div>
                        <div className={classes.layoutRow}>
                            <Typography variant="inherit">mission approval</Typography>
                            <span className={classes.dots}/>
                            <Typography variant="inherit">{missionApproval}</Typography>
                        </div>
                        <div className={classes.layoutRow}>
                            <Typography variant="inherit">transponder ICAO</Typography>
                            <span className={classes.dots}/>
                            <Typography variant="inherit">{transponderIcao}</Typography>
                        </div>
                        <div className={classes.layoutRow}>
                            <Typography variant="inherit">max AGL height current</Typography>
                            <span className={classes.dots}/>
                            <Typography variant="inherit">{m2ftCeiling(checkinRecord.aglmax)} ft / {checkinRecord.aglmax} m</Typography>
                        </div>
                        <div className={classes.layoutRow}>
                            <Typography variant="inherit">max AMSL* height current</Typography>
                            <span className={classes.dots}/>
                            <Typography variant="inherit">{m2ftCeiling(checkinRecord.aglmax + checkinRecord.etc.__srtmV1Gnd)} ft / {checkinRecord.aglmax + checkinRecord.etc.__srtmV1Gnd} m</Typography>
                        </div>
                        <div className={classes.layoutRow}>
                            <Typography variant="inherit">max AMSL* height initial</Typography>
                            <span className={classes.dots}/>
                            <Typography variant="inherit">{inspectData.__max} ft / {Math.round(ft2m(inspectData.__max))} m</Typography>
                        </div>
                        <div className={classes.layoutRow}>
                            {/*<Typography variant="inherit">started (UTC {utcOffset()})</Typography>*/}
                            <Typography variant="inherit">start (UTC)</Typography>
                            <span className={classes.dots}/>
                            <Typography variant="inherit">{dateTimeFormatter(checkinRecord.start, {useUtc:true})}</Typography>
                        </div>
                        <div className={classes.layoutRow}>
                            <Typography variant="inherit">stop (UTC)</Typography>
                            <span className={classes.dots}/>
                            <Typography variant="inherit">{dateTimeFormatter(checkinRecord.stop, {useUtc:true})}</Typography>
                        </div>

                        {
                            (isCheckinChecked)
                                ? <div className={classes.layoutRow}>
                                    <Typography variant="inherit">
                                        <span>{checkedStatusByString}: </span>
                                    </Typography>
                                    <span className={classes.dots}/>
                                    <Typography variant="inherit">{lastModAtcUser}</Typography>
                                </div>
                                : null
                        }

                        {
                            (isCheckinLostControl)
                                ?<>
                                    <div className={classes.layoutRow}>
                                        <Typography variant="inherit">remaining battery declared</Typography>

                                        <span className={classes.dots}/>
                                        <Typography variant="inherit">{droneLostBattery} min from {dateTimeFormatter(checkinRecord.modified, {useUtc:true})}</Typography>
                                    </div>
                                    <div className={classes.layoutRow}>
                                        <Typography variant="inherit">escape velocity</Typography>

                                        <span className={classes.dots}/>
                                        <Typography variant="inherit">{droneLostSpeed} km/h</Typography>
                                    </div>
                                </>
                                : null
                        }

                        <br/>
                        <Typography variant="caption">* NOTICE: AMSL calculated height, can contain error</Typography>
                        <br/>

                    </CardContent>
                    { (false)?
                        <CardContent className={classes.greyCard}>
                            <div>
                            _now: {this.props.tick.unixTime}
                            </div>
                            <div>
                            _notStartedYet: {String(inspectData.__isBeforeStart)}
                            </div>
                            <div>
                            _endedAlready: {String(inspectData.__isAfterStop)}
                            </div>
                            <div>
                            _noLongerValid: {String(!!(inspectData.__outdated))}
                            </div>
                            <div>
                            _status: {String(inspectData.checkinRecord.status)}
                            </div>
                        </CardContent>
                        :null
                    }
                </Card>

                <div>
                    {(connectedMission)?
                        <MissionV2View inspectData = {fakeMissionInspect} />
                        :null}
                </div>
                {   (!checkinNoLongerValid)?
                    <>
                    <JurisdictionActions
                        hasJurisdiction={hasJurisdiction}
                        isFis={isFis}
                        classes={classes}
                        handlers={handlers}
                        isInflight={isInFlight}
                        status={status}
                    />

                    <ModifyDialogV2
                        selectedCheckinV2={selectedCheckinV2}
                        postAtcReviewedCheckinV2={this.props.postAtcReviewedCheckinV2}
                    />
                    </>
                    :null
                }
            </div>
        )
    }
}

//hmm
const mapStateToProps = state => {
    return {
        selectedCheckinV2: state.checkinsV2.selectedCheckinV2,
        mySelf: state.mySelf,
        tick: state.tick,
        missionsV2: state.missionsV2,
    }
};

const enhance = compose(
    withStyles(styles),
    //translate,
    //muiThemeable(), // force redraw on theme change
    connect(
        mapStateToProps,
        {
            postAtcReviewedCheckinV2
        },
    )
);

export default enhance(CheckinV2View);
