import React from 'react';
import { withStyles } from '@material-ui/core-v3/styles';
import PubSub from "pubsub-js";
import {PubSubSignals} from '../../../LogicV1Redux/constants/PubSubSignals'
import compose from 'recompose/compose';
import {connect} from "react-redux";
import {bindActionCreators} from 'redux'
import {cancelAlertV2Rq} from '../../../LogicV1Redux/actions/AlertsV2Actions'
import MyModal from "./MyModal";
import {get} from 'lodash'
const styles = {
    dialogLabel: {
        backgroundColor: 'red',
        color: 'white',
        fontColor: 'white',
        fontStyle: 'bold'
    },
    landButton: {
        backgroundColor: 'red',
        color: 'white'
    },
    dialogContent: {
        margin: "15px"
    },
    dialog: {

    },
    closeButton: {
        position: 'absolute',
        right: '0px',
        top: '0px',
    }
};

const defaultMinutes = 5;

//huh


//dirty alert types flow
class CancelAlertModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {alertData:null};
    }

    componentWillMount() {

        this.pubsubCancelAlert = PubSub.subscribe(PubSubSignals.CANCEL_OWNED_ALERT_MODAL, (msg, data) => {
            console.log('custom alert data', data);
            this.setState({
                alertData: data //{type: AlertTypesV1.OWNED_ALERT, ...data}}
            });
        });

    }

    componentWillUnmount() {
        PubSub.unsubscribe(this.pubsubCancelAlert);
    }

    _handleClose = () => {
        this.setState({ open: false });
    };


    _handleCancelAlertButton = () => {
        console.log('_handleCancelAlertButton', this.state);
        //sending to server
        this.props.cancelAlertV2Rq({alertData: this.state.alertData});

    };

    render() {
        console.log('CancelAlertModal render', this.state)
        const { classes } = this.props;
        const { alertData } = this.state;

        //err. state is driven by signal TWICE. (either better myModal data support, or fix open/close st)
        //if (!alertData) return null;

        const ownedAreaName = get(alertData, 'etc.zoneName', false);
        const modalDescription = `Checked-in users in area ${ownedAreaName} will be notified.`;

        return (
            <MyModal
                title="Cancel area emergency ban now?"
                description={modalDescription}
                pubSubMessageListener={PubSubSignals.CANCEL_OWNED_ALERT_MODAL}
                onAccept={this._handleCancelAlertButton}
            />
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    cancelAlertV2Rq: bindActionCreators(cancelAlertV2Rq, dispatch),
});


const mapStateToProps = state => {
    //console.log('EmergencyPanel mapStateToProps', state);
    return null
};

const enhance = compose(
    //translate,
    withStyles(styles),
    connect(null, mapDispatchToProps)
);

export default enhance(CancelAlertModal);
