import {STORE_ALL_ALERTS_V1, SET_ATC_OWNED_ALERT_V1} from "../constants/AlertsV1Constants";
import {processAlertsV1ToDeck} from "../oldLogic/AlertsV1List";
import {initializeLayersObject} from "../../gl_map/glSetups/newCommon/mapStateConstructors";
import {unixTimeNow} from "../oldLogic/TimeConverters";


const initialState = {rawData: [], deckData: initializeLayersObject(), atcOwnedAlert: null, storeTimestamp: 0};

const alertsV1Reducer = (previousState = initialState, {type, payload}) => {
    switch (type) {
        case STORE_ALL_ALERTS_V1: //data passed WITH atcOwned alert!! (see fetch)

            console.log('----- alertsReducer reducer STORE_ALL_ALERTS_V1:', type, payload);

            if (payload.rawData) {
                payload.deckData = processAlertsV1ToDeck(payload.rawData);
            }

            return {...previousState, ...payload, storeTimestamp:unixTimeNow()};


        case SET_ATC_OWNED_ALERT_V1: //hmm unused?

            console.error('----- alertsReducer FIXME to saga  SET_ATC_OWNED_ALERT_V1:', type, payload);
            return {...previousState, atcOwnedAlert: payload, storeTimestamp:unixTimeNow()};


        default:
            return previousState;
    }
};

export default alertsV1Reducer;
