import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core-v3/styles';
import Slider from "@material-ui/lab-v3/Slider/Slider";
import LensTwoTone from '@material-ui/icons-v3/LensTwoTone';
import CustomizedSvgIcon from "./CustomizedSvgIcon";



const styles = {
    root: {
        width: "100%",
    },
    slider: {
        padding: '30px 0px',
        marginLeft: "10px",
        marginRight: "10px"
    },
    thumbIcon: {
        borderRadius: '50%'
    },
    thumbIconWrapper: {
        backgroundColor: '#fff',
        width: 24,
        height: 24
    },
    thumbIconWrapperLarge: {
        backgroundColor: '#fff',
        width: 48,
        height: 48
    }
};


/**
 * Svg icon dimensions passed into props should be 24x24px large
 */
class MyCustomSlider extends React.Component {


    _handleChange = (event, value) => {
        this.props.onSliderChanged(value);
    };

    _handleDragEnd = (event) => {
        this.props.onDragEnd(event)
    };

    _returnProperIcon = () => {
        if(this.props.path === "") {
            return (
                <LensTwoTone />
            );
        }
        else {
            return (
                <CustomizedSvgIcon path={this.props.path} iconSize={this.props.iconSize}/>
            );
        }
    };

    render() {
        const { classes } = this.props;
        //const { value } = this.state;
        return (
            <div className={classes.root}>
                <Slider
                    value={this.props.value}
                    aria-labelledby="slider-icon"
                    onChange={this._handleChange}
                    onDragEnd={this._handleDragEnd}
                    step={this.props.step}
                    min={this.props.min}
                    max={this.props.max}
                    classes={{
                        container: classes.slider,
                        thumbIconWrapper: (this.props.thumbSize === "large" ? classes.thumbIconWrapperLarge : classes.thumbIconWrapper)
                    }}
                    thumb={
                       this._returnProperIcon()
                    }
                />
            </div>
        );
    }
}

MyCustomSlider.propTypes = {
    classes: PropTypes.object.isRequired,
    path: PropTypes.string,
    thumbSize: PropTypes.string,
    onSliderChanged: PropTypes.func,
    onDragEnd: PropTypes.func,
    initialValue: PropTypes.number,
    iconSize: PropTypes.string,
    step: PropTypes.number,
    min: PropTypes.number,
    max: PropTypes.number,
    value: PropTypes.number
};
MyCustomSlider.defaultProps = {
    path: "",
    thumbSize: "normal",
    //initialValue: 0,
    onSliderChanged: () => {},
    onDragEnd: () => {},
    iconSize: "mini",
    step: 1,
    min: 0,
    max: 100,
    value: 0
};


export default withStyles(styles)(MyCustomSlider);