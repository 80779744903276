import React from 'react';
import Button from '@material-ui/core-v3/Button';
import Dialog from '@material-ui/core-v3/Dialog';
import DialogActions from '@material-ui/core-v3/DialogActions';
import DialogContent from '@material-ui/core-v3/DialogContent';
import DialogContentText from '@material-ui/core-v3/DialogContentText';
import DialogTitle from '@material-ui/core-v3/DialogTitle';
import PropTypes from "prop-types";
import { withStyles } from '@material-ui/core-v3/styles';
import IconButton from "@material-ui/core-v3/IconButton/IconButton";
import CloseIcon from "@material-ui/icons/Close"
import MyCustomSlider from "./MyCustomSlider";
import PubSub from "pubsub-js";
import MyCustomDoubleSlider from "./MyCustomDoubleSlider";
import {PubSubSignals} from '../../../LogicV1Redux/constants/PubSubSignals'
import compose from 'recompose/compose';
import {connect} from "react-redux";
import {m2ftCeiling} from "../../../LogicV1Redux/oldLogic/GeoConverters";
import {unixTimeNow, unixTime2Iso} from "../../../LogicV1Redux/oldLogic/TimeConverters";
import {timeFormatter, dateTimeFormatter} from "../../../dr_ra2/utils/momentHelpers";
import {emitOperation} from "../../../LogicV1Redux/oldLogic/RtMapCheckinsV1";
import {FlightStatuses} from "../../../LogicV1Redux/constants/CheckinsV1Enums";
import {FlightStatusesV2} from "../../../LogicV1Redux/constants/CheckinsV2Enums";
import Typography from "@material-ui/core-v3/Typography";
import CardContent from "@material-ui/core-v3/CardContent";

const styles = {
    dialogLabel: {
        backgroundColor: "rgb(41, 98, 255)",
        color: "rgb(255, 255, 255)",
        fontColor: 'white',
        fontStyle: 'bold'
    },
    acceptButton: {
        backgroundColor: "rgb(0, 200, 83)",
        color: "rgb(255, 255, 255)",
    },
    modifyButton: {
        backgroundColor: "rgb(41, 98, 255)",
        color: "rgb(255, 255, 255)",
    },
    dialogContent: {
        margin: "15px"
    },
    dialog: {

    },
    closeButton: {
        position: 'absolute',
        right: '0px',
        top: '0px',
    }
};


const initialState = {
    open: false,
    lowerSliderValue: null,
    upperSliderValue: null,
    aglSliderValue: null,
    timeStart: null,
    timeStop: null
};

const unixTimeDiff = (unixTimeStart, unixTimeEnd) => {
    return (unixTimeEnd - unixTimeStart);
};

const unixTime2Mins = (v) => {
    return Math.round(v/60);
};


//just a note for edge case when 2 atc would want to change one mission at the same time:
//although redux connection works, casting inspectData to component.state does'nt work yet (at least height)
class ModifyDialog extends React.Component {

    constructor(props) {
        super(props);

        this.state = {...initialState};
    };

    componentDidMount() {
        //keeping for now as dialog launcher
        this.pubsubModifyPermission = PubSub.subscribe(PubSubSignals.MODIFY_PERMISSION_DIALOG, (msg, data) => {
            this.setState({
                open: true,
            });
        });
    }

    componentWillUnmount() {
        PubSub.unsubscribe(this.pubsubModifyPermission);
    }


    //yikes mixing props and local state.
    static getDerivedStateFromProps(nextProps, prevState) {

        //if (!state.open) return null; //fck

        const {selectedCheckinV2} = nextProps;
        if (!selectedCheckinV2) return null;

        console.log('ModifyDialog getDerivedStateFromProps', selectedCheckinV2);

        const selectedCheckinData = selectedCheckinV2.checkinRecord;

        //buu
        const originalStartTimeDiff = unixTime2Mins(unixTimeDiff(unixTimeNow(), selectedCheckinV2.__start));
        const originalEndTimeDiff = unixTime2Mins(unixTimeDiff(unixTimeNow(), selectedCheckinV2.__stop));

        const startedDiff = (Number.isFinite(prevState.upperSliderValue)) ? prevState.upperSliderValue : originalStartTimeDiff;
        const untilDiff = (Number.isFinite(prevState.lowerSliderValue)) ? prevState.lowerSliderValue : originalEndTimeDiff;

        const correctedStartUnix = selectedCheckinV2.__start + (startedDiff - originalStartTimeDiff)*60;
        const correctedEndUnix = selectedCheckinV2.__stop + (untilDiff - originalEndTimeDiff)*60;

        return ({
            open: prevState.open,
            upperSliderValue: startedDiff,
            lowerSliderValue: untilDiff,
            aglSliderValue: (prevState.aglSliderValue !== null) ? prevState.aglSliderValue : selectedCheckinData.aglmax,
            timeStart:correctedStartUnix,
            timeStop: correctedEndUnix,
            correctedMinValue: originalStartTimeDiff
        });


        //return null;
    }



    _handleUpperTimeSlider = (value) => {
        this.setState({upperSliderValue: value});
    };

    _handleLowerTimeSlider = (value) => {
        this.setState({lowerSliderValue: value});
    };

    _handleAGLSliderChange = (value) => {
        this.setState({aglSliderValue: value});
    };

    _handleClose = () => {
        this.setState({ ...initialState });
    };


    _handleAccept = () => {
        this.setState({ open: false });

        let modData = {};

        const selectedCheckinV2 = this.props.selectedCheckinV2;

        const newIsoStart = unixTime2Iso(this.state.timeStart);
        const newIsoStop = unixTime2Iso(this.state.timeStop);

        let dirty = false;

        if (this.state.aglSliderValue !== selectedCheckinV2.checkinRecord.aglmax) {
            dirty = true;
            modData.aglmax = this.state.aglSliderValue;
        }

        if (newIsoStart !== selectedCheckinV2.checkinRecord.start) {
            dirty = true;
            modData.start = newIsoStart;
        }

        if (newIsoStop !== selectedCheckinV2.checkinRecord.stop) {
            dirty = true;
            modData.stop = newIsoStop;
        }

        //fallback on accept without modification
        const status = (!dirty)?FlightStatusesV2.ACCEPTED:FlightStatusesV2.ATC_MODIFIED;

        console.log('_handleAccept', this.props.selectedCheckinV2, modData);

        this.props.postAtcReviewedCheckinV2({selectedCheckinV2, status, modData});

        //fixme blur on processing (or even better, close and blur in selected checkin on processing)
    };


    render() {
        console.log('ModifyDialogV2 render', this.state, this.props);
        const { classes, selectedCheckinV2 } = this.props;
        const {checkinRecord} = selectedCheckinV2;
        return (
            <div>
                <Dialog
                    open={this.state.open}
                    onClose={this._handleClose}
                    aria-labelledby="form-dialog-title"
                    fullWidth
                    maxWidth="md"
                    className={classes.dialog}
                >
                    <DialogTitle id="form-dialog-title" className={classes.dialogLabel} disableTypography>Modify V2
                        <IconButton
                            key="close"
                            aria-label="Close"
                            color="inherit"
                            className={classes.closeButton}
                            onClick={this._handleClose}
                        >
                            <CloseIcon/>
                        </IconButton>
                    </DialogTitle>
                    <DialogContent className={classes.dialogContent}>
                        <DialogContentText>
                            {`Flight duration ${this.state.lowerSliderValue-this.state.upperSliderValue} min`}
                        </DialogContentText>
                        {/*<DialogContentText>*/}
                            {/*{this.state.lowerSliderValue} -> {this.state.upperSliderValue}*/}
                        {/*</DialogContentText>*/}
                        <DialogContentText>
                            {timeFormatter(this.state.timeStart*1000, {useUtc:true})} (UTC) until {timeFormatter(this.state.timeStop*1000, {useUtc:true})} (UTC)
                        </DialogContentText>
                        <MyCustomDoubleSlider
                            lowerSliderValue = {this.state.lowerSliderValue}
                            upperSliderValue = {this.state.upperSliderValue}
                            //initialLowerSliderValue = {this.state.lowerSliderValue}
                            //initialUpperSliderValue = {this.state.upperSliderValue}
                            onLowerSliderChanged={this._handleLowerTimeSlider}
                            onUpperSliderChanged={this._handleUpperTimeSlider}
                            min={this.state.correctedMinValue}
                            max={240}
                            step={1}
                        />
                        <DialogContentText>

                            Altitude AGL {m2ftCeiling(this.state.aglSliderValue)} ft / {this.state.aglSliderValue} m
                            <br/>
                            Altitude AMSL* {m2ftCeiling(this.state.aglSliderValue + checkinRecord.etc.__srtmV1Gnd)} ft / {this.state.aglSliderValue + checkinRecord.etc.__srtmV1Gnd} m
                        </DialogContentText>
                        <MyCustomSlider //initialValue = {this.state.aglSliderValue}
                                        value={this.state.aglSliderValue}
                                        onSliderChanged={this._handleAGLSliderChange}
                                        max={1000}
                                        step={10}/>
                        <br/>
                        <Typography variant="caption">* NOTICE: AMSL calculated height, can contain error</Typography>
                        <br/>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this._handleClose} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={this._handleAccept} className={classes.modifyButton}>
                            Modify
                        </Button>
                        {/*<Button >*/}
                           {/*{this.props.selectedCheckin.properties.id}*/}
                        {/*</Button>*/}
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}


ModifyDialog.propTypes = {
    classes: PropTypes.object.isRequired,
};

const enhance = compose(
    //translate,
    withStyles(styles),
    //connect(mapStateToProps)
);

export default enhance(ModifyDialog);
