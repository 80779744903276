
import {STORE_ALL_LPR_HELIPADS_V1} from "../constants/LprHelipadsV1Constants";
import {initializeLayersObject} from "../../gl_map/glSetups/newCommon/mapStateConstructors";
import {unixTimeNow} from "../oldLogic/TimeConverters";
import {processLprHelipadsV1ToDeck} from "../oldLogic/LprHelipadsV1";

const initialState = {rawData: [], deckData: initializeLayersObject(), selectedHelipad: null, storeTimestamp: 0};

const lprHelipadsReducer = (previousState = initialState, { type, payload }) => {

    switch (type) {
        case STORE_ALL_LPR_HELIPADS_V1:
            //console.log('----- lprHelipadsReducer reducer STORE_ALL_LPR_HELIPADS_V1:', type, payload);

            if (payload.rawData) {
                payload.deckData = processLprHelipadsV1ToDeck(payload);
            }

            return {...previousState, ...payload, storeTimestamp:unixTimeNow()};


        default:
            return previousState;
    }
};

export default lprHelipadsReducer;
