//old atc functionality
//ugh, rewritten roughly from angular (v1.0)

import MySocketIoV1Client from './MySocketIoV1Client'
import {getPandoraUser} from "./dirtyAuth";

export function emitOperation({operation, status, modData = null}) {
    const userData = getPandoraUser();
    console.log('changeOpStatus', operation.properties, status, modData);

    const emitData = {
        user:userData,
        status,
        mod:modData
    };
    console.log('emitData', emitData );


    MySocketIoV1Client.emit('changeOperation', {op: operation.properties, user:userData, status:status, mod:modData});

    // setOpStatus : function(operation, status, modData) {
    //     //redraw with postgres response!..
    //     // ugh {
    //     //console.log($rootScope.userData);
    //     let userData = $rootScope.userData;
    //     if (global.debug())
    //         console.log('changeOpStatus', operation.properties, status, modData);
    //     mySocket.emit('changeOperation', {op: operation.properties, user:userData, status:status, mod:modData});
    // },
}

export default {
    emitOperation
}
