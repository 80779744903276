import myAuth from '../../LogicV2/auth/MyAuthReducer';
import mySelf from '../../LogicV2/auth/MySelfReducer';
import alertsV1 from '../../LogicV1Redux/reducers/AlertsV1Reducer';
import alertsV2 from '../../LogicV1Redux/reducers/AlertsV2Reducer';

import checkinsV1 from '../../LogicV1Redux/reducers/CheckinsV1Reducer';
import checkinsV2 from '../../LogicV1Redux/reducers/CheckinsV2Reducer';

import lprHelipadsV1 from '../../LogicV1Redux/reducers/LprHelipadsV1Reducer';
import rtMapZonesV1 from '../../LogicV1Redux/reducers/RtMapZonesV1Reducer';
import missionsV2 from '../../LogicV1Redux/reducers/MissionsV2Reducer';
import zonesV2 from '../../LogicV1Redux/reducers/ZonesV2Reducer';
import zoneTypesV2 from '../../LogicV1Redux/reducers/ZoneTypesV2Reducer';
import appState from '../../LogicV1Redux/reducers/AppStateReducer';

import tick from '../../LogicV1Redux/reducers/TickReducer';
import inspectData from '../../LogicV1Redux/reducers/InspectDataReducer';
import drawEditorData from '../../LogicV1Redux/reducers/DrawEditorReducer';

import mapUxConfig from '../../LogicV1Redux/reducers/MapUxConfigReducer';
import myAppExtras from "../../LogicV1Redux/reducers/MyAppExtrasReducer";

import liveAdsbExchange from '../../LogicV1Redux/reducers/LiveAdsbExchangeReducer';

import rtdmCreotechTicks from '../../LogicV1Redux/reducers/RtdmCreotechTicksReducer';
import rtdmExchangeTicks from '../../LogicV1Redux/reducers/RtdmExchangeTicksReducer';
import rtdmExchangeAircrafts from '../../LogicV1Redux/reducers/RtdmExchangeAircraftsReducer';
import rtdmSoapLprUnitsTicks from '../../LogicV1Redux/reducers/RtdmSoapLprUnitsTicksReducer';
import rtdmAerobitsTicks from '../../LogicV1Redux/reducers/RtdmAerobitsTicksReducer';

import customLayer from '../../LogicV1Redux/reducers/CustomLayerReducer';

import windows from '../logic/window/WindowsReducer'
import socket from "../../LogicV1Redux/reducers/SocketReducer";

export default {
    myAuth,
    mySelf,
    alertsV1,
    alertsV2,
    checkinsV1,
    checkinsV2,
    lprHelipadsV1,
    rtMapZonesV1,
    missionsV2,
    zonesV2,
    zoneTypesV2,
    inspectData,
    drawEditorData,
    tick,
    myAppExtras,
    appState,
    mapUxConfig,
    liveAdsbExchange,

    rtdmCreotechTicks,
    rtdmExchangeTicks,
    rtdmExchangeAircrafts,
    rtdmSoapLprUnitsTicks,
    rtdmAerobitsTicks,

    customLayer,
    windows,
    socket,

}
