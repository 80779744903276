
import Switch from '@material-ui/core-v3/Switch';

import React, {Component} from "react";
import withStyles from "@material-ui/core-v3/styles/withStyles";
import {connect} from "react-redux";
import {compose} from 'recompose';
import {get} from 'lodash';

import {
    //storePandoraTrafficEnabled,
    storePandoraLprTrafficEnabled,
    storePandoraCreotechTrafficEnabled,
    storePandoraTrafficHeightFilter,
} from "../../LogicV1Redux/actions/MapUxConfigActions";
import MyCustomSlider from "./prepared-components/MyCustomSlider";


const l = console;

const styles = theme => ({
    root: {

    },
    masterToggleContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexWrap: 'wrap',
    },
    chipContainer: {
        display: 'flex',
        justifyContent: 'left',
        flexWrap: 'wrap',
    },
    chip: {
        //margin: theme.spacing.unit,
        margin: '5px',
    },
});


class TrafficVisibility extends Component {

    state = {
        topCutoffValue: get(this.props, 'trafficHeightFilter.topCutoff', 0),
    };

    //hmm, maybe onEnd? is onChange.
    _handleTopCutoffSliderChange = value => {
        this.setState({
            topCutoffValue: value
        })
    };

    _handleTopCutoffSliderDragEnd = event => {
        console.log('_handleTopCutoffSliderDragEnd', event);

        this.props.storePandoraTrafficHeightFilter({
            bottomCutoff: this.props.trafficHeightFilter.bottomCutoff,
            topCutoff: this.state.topCutoffValue
        });
    };

    componentDidUpdate(prevProps) {
        console.log('==============did update')

        if (!this.props.trafficHeightFilter) {
            return null;
        }

        if (this.props.trafficHeightFilter.topCutoff !== prevProps.trafficHeightFilter.topCutoff) {
            this.setState({topCutoffValue:this.props.trafficHeightFilter.topCutoff});
        }
    }

    render() {
        const { classes,
            pandoraLprTrafficEnabled,
            pandoraCreotechTrafficEnabled,
            trafficHeightFilter,
            storePandoraLprTrafficEnabled,
            storePandoraCreotechTrafficEnabled,
        } = this.props;

        if (!trafficHeightFilter) return null;

        return (
            <>
                <div className={classes.masterToggleContainer}>
                    <b>HEMS Traffic Visibility</b>
                    <Switch
                        checked={pandoraLprTrafficEnabled}
                        onChange={() => storePandoraLprTrafficEnabled(!pandoraLprTrafficEnabled)}
                        value="lprTrafficChecked"
                        color="primary"
                    />
                </div>
                <div className={classes.masterToggleContainer}>
                    <b>{/*Creotech */}Traffic Visibility</b>
                    <Switch
                        checked={pandoraCreotechTrafficEnabled}
                        onChange={() => storePandoraCreotechTrafficEnabled(!pandoraCreotechTrafficEnabled)}
                        value="creotechTrafficChecked"
                        color="primary"
                    />
                </div>
                {(pandoraLprTrafficEnabled || pandoraCreotechTrafficEnabled) ?
                    <div>
                        Traffic Height Filter: {trafficHeightFilter.topCutoff} ft AMSL
                        <MyCustomSlider onDragEnd={this._handleTopCutoffSliderDragEnd}
                                        onSliderChanged={this._handleTopCutoffSliderChange}
                                        value={this.state.topCutoffValue}
                                        max={16000}
                                        step={500}
                        />
                    </div>
                    : null
                }
            </>
        );
    }
}

const mapStateToProps = (state, props) => {
    //console.log('ZoneTypesVisibility mapStateToProps', state, props);

    return {
        //pandoraTrafficEnabled: state.mapUxConfig.pandoraTrafficEnabled,
        pandoraLprTrafficEnabled: state.mapUxConfig.pandoraLprTrafficEnabled,
        pandoraCreotechTrafficEnabled: state.mapUxConfig.pandoraCreotechTrafficEnabled,
        trafficHeightFilter:state.mapUxConfig.trafficHeightFilter
    }
};


const enhance = compose(
    //translate,
    withStyles(styles),
    connect(
        mapStateToProps,
        {
            //storePandoraTrafficEnabled,
            storePandoraLprTrafficEnabled,
            storePandoraCreotechTrafficEnabled,
            storePandoraTrafficHeightFilter
        },
        // null,
        // {
        //     areStatesEqual: (prev, next) => {
        //         console.log('comparing states', prev, next)
        //         console.log('shallowEqual1', shallowEqual(prev, next))
        //         console.log('shallowEqual2', shallowEqual(prev.mapUxConfig, next.mapUxConfig))
        //         return (
        //             shallowEqual(prev.mapUxConfig, next.mapUxConfig)
        //         );
        //     }
        // }
    )
);

export default enhance(TrafficVisibility);
