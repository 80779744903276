import Button from "@material-ui/core-v3/Button/Button";
import React from "react";
import withStyles from "@material-ui/core-v3/styles/withStyles";
import compose from 'recompose/compose';
import connect from "react-redux/es/connect/connect";
import {togglePlannedWindow} from "../../logic/window/WindowActions";
import {bindActionCreators} from "redux";
import classnames from 'classnames'
import Badge from "@material-ui/core-v3/Badge";
import {PlannedBarsContext} from "./BarsController";

const styles = theme => ({
    root: {
        display: "flex",
        flexDirection: "row"
    },
    button: {
        margin: theme.spacing.unit,

    },
    buttonSelected: {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.primary.contrastText
    },
    badge: {
        top: "28px",
        right: "0px",
    }
});

const OperationControlPanel = ({classes, togglePlannedWindow, plannedWindowOpen}) => {

    const button =
        <Button variant="outlined" color="primary" component="span"
                className={
                    classnames(classes.button,
                        {
                            [classes.buttonSelected]: plannedWindowOpen
                        }
                    )
                }
                onClick={togglePlannedWindow}
        >
            PLANNED
        </Button>;

    return (
        <div className={classes.root}>
            <PlannedBarsContext.Consumer>
                {
                    plannedBars =>
                        plannedBars.length > 0 ?
                            <Badge color="primary" badgeContent={plannedBars.length} classes={{badge: classes.badge}}>
                                {button}
                            </Badge>
                            :
                            <>
                                {button}
                            </>
                }
            </PlannedBarsContext.Consumer>
        </div>
    )
};


const mapDispatchToProps = dispatch =>
    (
        {
            togglePlannedWindow: bindActionCreators(togglePlannedWindow, dispatch),
        }
    );


export default compose(
    withStyles(styles),
    connect(
        state => ({plannedWindowOpen: state.windows.plannedWindow.visible}),
        mapDispatchToProps
    )
)(OperationControlPanel);