import React from "react";
import withStyles from "@material-ui/core-v3/styles/withStyles";
import {getHighestRPAcssColor} from "../utils/rpaColors";
import pure from "recompose/pure";
import classnames from 'classnames';
import Polymer from "@material-ui/icons-v3/Polymer";
import {compose} from 'recompose';
import {Callsign} from "./Callsign";

const styles = {
    tuple: {
        whiteSpace: "normal",
        overflow: "hidden",
        fontFamily: `'Roboto Mono', monospace`,
        "&:not(:last-child)": {
            borderRightStyle: "solid",
            borderRightWidth: "1px"
        },
        "& td": {
            fontSize: "1.4rem"
        }
    },
    connectedCheckin: {
        background: `repeating-linear-gradient(
                    45deg,
                    #0000,
                    #0000 10px,
                    #0001 10px,
                    #0001 20px)`
    },
    timeOverdueAlertRed: {
        fontWeight: '900',
        color: 'red',
        textDecoration: 'underline'
    },
    timeOverdueAlert: {
        fontWeight: '900',
        textDecoration: 'underline'
    },
    waitingForAck: {
        color: "#00FFFF",
        opacity: 0,
        animation: "blink 2s cubic-bezier(1, -1, 0, 1) infinite",
    },
    acked: {
        fontWeight: '900',
    },
    '@keyframes blink': {
        '100%': {
           opacity: 1,
        },
    },
    '@keyframes blink2': {
        '0%': {
            opacity: 1,
        },
        '49.99%': {
            opacity: 1,
        },
        '50%': {
            opacity: 0,
        },
        '100%': {
            opacity: 0,
        },
    },
    '@keyframes loading': {
        '0%': {
            left: "88%"
        },
        '100%': {

            left: "98%"
        }
    },
    square: {
        backgroundColor: "red",
        width: "12px",
        height: "30px",
        position: "absolute",
        // animation: "loading 2s cubic-bezier(1, 0.5, 0.5, 1) infinite",
        animation: "loading 2s linear infinite",
    },
    lostControlAlert: {
        opacity: 0,
        animation: "blink 0.8s cubic-bezier(1, -1, 0, 1) infinite",
    },
    acmTouchedNotOnly: {
        color: "#AAA"
    },
    overdued0: {
        opacity: 0,
        animation: "blink2 2s linear infinite",
        position: "absolute",
        left: 0,
        willChange: "opacity"
    },
    overdued1: {
        opacity: 0,
        animation: "blink2 2s linear 1s infinite",
        willChange: "opacity"
    },
    overduedRoot: {
        position: "relative"
    }
};

/**
 * So called bars...
 */
const PureRowListItemTable =
    ({
         approvalNo,
         callsign = '',
         missionType,
         //displayWarnings,
         warnings = [],
         displayMaxHeight,
         start,
         stop,
         rmk,
         status,
         phone,
         mcmt,
         missionStart,
         missionStop,
         classes,
         isConnectedMission,
         beforeMissionStartAlert,
         afterMissionStopAlert,
         droneLostBattery,
         waitingForAck,
         acked,
         lostControlAlert,
         isAcmTouched,
         isAcmTouchedOnly,
         isOverduedCheckin,
         isOverduedMission,
         isSuavo,
         is112,
     }) => {



        return  (
            <table style={{width: "100%", tableLayout: "fixed"}}>
                <tbody>
                <tr>
                    <td className={
                        classnames(classes.tuple,
                            {
                                [classes.connectedCheckin]: isConnectedMission,
                            }
                        )}
                        style={{width: "12%"}}>
                        <table>
                            <tbody>
                            <tr>
                                <td className={
                                    classnames(
                                        {
                                            [classes.timeOverdueAlertRed]: beforeMissionStartAlert
                                        }
                                    )}>
                                    {missionStart}
                                </td>

                            </tr>
                            <tr>
                                <td className={
                                    classnames(
                                        {
                                            [classes.timeOverdueAlertRed]: afterMissionStopAlert,
                                            [classes.timeOverdueAlert]: isOverduedMission
                                        }
                                    )}>
                                    {missionStop}
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </td>
                    <td className={classes.tuple} style={{width: "35%"}}>
                        <table
                            className={
                                classnames(
                                    {
                                        [classes.lostControlAlert]: lostControlAlert,
                                    }
                                )}
                        >
                            <tbody>
                            <tr>
                                <td style={{fontSize: "2.3rem"}}>
                                    <Callsign callsign={callsign}/>
                                    {isSuavo && <span style={{fontSize: "1.2rem", fontWeight: "900"}}>S</span>}
                                    {is112 && <span style={{fontSize: "1.2rem", fontWeight: "900"}}>R</span>}
                                </td>

                            </tr>
                            <tr>
                                <td>
                                    {phone}
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </td>
                    <td className={classes.tuple}
                        style={{backgroundColor: `${getHighestRPAcssColor(warnings, displayMaxHeight)}`, width: "7%"}}>

                    </td>
                    <td className={classes.tuple} style={{width: "7%"}}>
                        <table>
                            <tbody>
                            <tr>
                                <td>
                                    {missionType}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <br/>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </td>
                    <td className={classes.tuple} style={{width: "15%"}}>
                        <table>
                            <tbody>
                            <tr>
                                <td>
                                    {`${displayMaxHeight}ft AMSL*`}
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </td>
                    <td className={classes.tuple} style={{width: "12%"}}>
                        <table>
                            <tbody>
                            <tr>
                                <td className={
                                    classnames(
                                        {
                                            [classes.timeOverdueAlertRed]: beforeMissionStartAlert
                                        }
                                    )}>
                                    {start}
                                </td>

                            </tr>
                            <tr>
                                <td className={
                                    classnames(
                                        {
                                            [classes.timeOverdueAlertRed]: afterMissionStopAlert,
                                        }
                                    )}>
                                    {
                                        isOverduedCheckin ?
                                            (
                                                <div className={classes.overduedRoot}>
                                                    <span className={classnames(classes.overdued0, classes.timeOverdueAlert)}>{stop}</span>
                                                    <span className={classnames(classes.overdued1, classes.acked)}>OVDUE</span>
                                                </div>
                                            )
                                            :
                                            stop
                                    }
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </td>
                    <td className={classes.tuple} style={{width: "12%"}}>
                        <table style={{width: "100%"}}>
                            <tbody
                                className={
                                    classnames(
                                        {
                                            [classes.lostControlAlert]: lostControlAlert,
                                        }
                                    )}
                            >
                            <tr>
                                <td style={{textAlign: "center"}}
                                    className={
                                        classnames(
                                            {
                                                [classes.waitingForAck]: waitingForAck,
                                                [classes.acked]: acked
                                            }
                                        )}
                                >
                                    {/*<div className={classes.square}/>*/}
                                    {status}
                                </td>
                            </tr>
                            <tr>
                                <td style={{textAlign: "center"}}>
                                    {
                                        droneLostBattery ?
                                            droneLostBattery
                                            :
                                            isAcmTouched ?
                                                <Polymer className={
                                                    classnames(
                                                        {[classes.acmTouchedNotOnly]: !isAcmTouchedOnly}
                                                    )
                                                }/>
                                                :
                                                null
                                    }
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
                </tbody>
            </table>
        )
    };



export const RowListItemTable = compose(
    withStyles(styles),
    pure,
)(PureRowListItemTable);

