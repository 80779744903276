import React from "react";
import withStyles from "@material-ui/core-v3/styles/withStyles";
import Card from "@material-ui/core-v3/Card/Card";
import CardContent from "@material-ui/core-v3/CardContent/CardContent";
import PropTypes from "prop-types";
import get from 'lodash.get';
import moment from 'moment';
import {showZonesExtras} from "../../../Configs";

const styles = {
    dirtyTable: {
        width: "100%",
        tableLayout: "fixed",
        borderCollapse: "collapse",
        '& th, td': {
            border: "1px solid gray",
        },
    },
    zoneV2CardActive: {
        backgroundColor: "rgb(21, 101, 192)",
        color: "rgba(255, 255, 255, 0.87)",
        width: "100%",
        marginBottom: "2em"
    },
    zoneV2CardInactive: {
        backgroundColor: "rgb(128, 128, 128)",
        color: "rgba(255, 255, 255, 0.87)",
        width: "100%",
        marginBottom: "2em"
    }
};

//dirty stub now
//time formatting here?

const RowHeader = () => (
        <thead>
        <tr style={{textAlign: "left"}}>
            <th style={{ overflow: "hidden"}}>start</th>
            <th style={{ overflow: "hidden"}}>stop</th>
            <th style={{ overflow: "hidden"}}>min</th>
            <th style={{ overflow: "hidden"}}>max</th>
            {showZonesExtras?
                <th style={{overflow: "hidden"}}>extra</th>
                :null
            }
        </tr>
        </thead>
);

const RowItem = ({styles = null, key, start, stop, min=null, max=null, extras=null, active = false}) => (
    <tr style={{color:(active)?"black":"gray"}} key={key}>
        <td>
            {moment(start).utc().format('DD/MM/YY, HH:mm')}
        </td>
        <td>
            {moment(stop).utc().format('DD/MM/YY, HH:mm')}
        </td>
        <td>
            {min}
        </td>
        <td>
            {max}
        </td>
        {showZonesExtras ?
            <td>
                {extras}
            </td>
            :null
        }
    </tr>
);

const DirtyTableBase = ({classes, rows = []}) => (
    <table className={classes.dirtyTable}>
        <RowHeader/>
        <tbody>
        {rows}
        </tbody>
    </table>
);
const DirtyTable = withStyles(styles)(DirtyTableBase);

class ZoneV2View extends React.Component {

    render() {
        const {classes, inspectData, status} = this.props;
        console.log('ZoneV2View props', this.props);

        let aipsV1 = [];
        let aupsV1 = [];
        let webcatActivations = [];
        let olAixmTimeslices = [];
        let aixmTimeslices = [];
        let notamActivations = [];


        if (inspectData.aipsV1) {
            for (let i=0; i < inspectData.aipsV1.length; i++) {
                let aip = inspectData.aipsV1[i];
                aipsV1.push(<div key={i}>{aip.name}, dow:{aip.dow}, from:{aip.active_from}, until:{aip.active_until}</div>)
            }
        }

        if (inspectData.aupsV1) {
            for (let i=0; i < inspectData.aupsV1.length; i++) {
                let aup = inspectData.aupsV1[i];
                console.log(`==== aup`, aup);
                aupsV1.push(<div key={i}>{aup.name}, from:{aup.dt_from}, to:{aup.dt_to}, fl_from:{aup.fl_from}, fl_to:{aup.fl_to}</div>)
            }
        }

        if (inspectData.webcatActivations) {
            for (let i=0; i < inspectData.webcatActivations.length; i++) {
                let wbAct = inspectData.webcatActivations[i];
                //console.log(`==== webcatActivations extras`, JSON.stringify(wbAct.etc));
                const {upType, reservationStatus, fuaStatus} = wbAct.etc;
                const extras = `${upType}:${reservationStatus}, fua:${fuaStatus}`;
                webcatActivations.push(RowItem({
                    key: i,
                    start: wbAct.start,
                    stop: wbAct.stop,
                    min: wbAct.min,
                    max: wbAct.max,
                    extras,
                    active: wbAct.__active

                }));
                // olWebcatActivations.push(<div key={i}>
                //     {wbAct.etc.upType} start:{wbAct.start} stop:{wbAct.stop}
                //     min: {wbAct.min} max: {wbAct.max} status:{wbAct.etc.reservationStatus}
                // </div>)
            }
        }

        if (inspectData.aixmTimeslices) {

            for (let i=0; i < inspectData.aixmTimeslices.length; i++) {
                let axTSlice = inspectData.aixmTimeslices[i];

                const extrasTS = `->TS`;

                aixmTimeslices.push(RowItem({
                    key: i,
                    start: axTSlice.start,
                    stop: axTSlice.stop,
                    min: axTSlice.min,
                    max: axTSlice.max,
                    extras: extrasTS,
                    active: axTSlice.__active
                }));


                //console.log(`==== aixmTimeslices`, axAct);
                let aixmActivations = axTSlice.aixmActivations;
                let activations = [<div key={`Act${i} `}>->Activations</div>];
                for (let j=0; j < aixmActivations.length; j++) {
                    const axAct = aixmActivations[j];
                    const extrasAct = `-->ACT, dabNote:${axAct.note}`;

                    aixmTimeslices.push(RowItem({
                        key: `${i}-${j}`,
                        start: axAct.start,
                        stop: axAct.stop,
                        min: axAct.min,
                        max: axAct.max,
                        extras: extrasAct,
                        active: axAct.__active
                    }));

                    activations.push(
                        <div key={`${i}-${j}`}>
                            --start:{axAct.start} stop:{axAct.stop}
                            min: {axAct.min} max: {axAct.max}
                        </div>
                    )
                }

                // olAixmTimeslices.push(<div key={i}>
                //     #start:{axTSlice.start} stop:{axTSlice.stop}
                //     min: {axTSlice.min} max: {axTSlice.max}
                //     {activations}
                // </div>)
            }
        }

        if (inspectData.notams) {
            for (let i=0; i < inspectData.notams.length; i++) {
                const currNotam = inspectData.notams[i];
                const extrasNotam = `-->Notam`;

                console.log('currNotam', currNotam);

                notamActivations.push(RowItem({
                    key: i,
                    start: currNotam.b,
                    stop: currNotam.c,
                    min: currNotam.__min,
                    max: currNotam.__max,
                    extras: extrasNotam
                    //active: wbAct.__active

                }));

                for (let j = 0; j < currNotam.notamActivations.length; j++) {
                    let notamAct = currNotam.notamActivations[j];
                    const extrasNotamAct = `-->NotamAct`;

                    notamActivations.push(RowItem({
                        key: `${i}-${j}`,
                        start: notamAct.start,
                        stop: notamAct.stop,
                        min: notamAct.__min,
                        max: notamAct.__max,
                        extras: extrasNotamAct
                        //active: wbAct.__active

                    }));
                    // olWebcatActivations.push(<div key={i}>
                    //     {wbAct.etc.upType} start:{wbAct.start} stop:{wbAct.stop}
                    //     min: {wbAct.min} max: {wbAct.max} status:{wbAct.etc.reservationStatus}
                    // </div>)
                }
            }
        }


        if (!inspectData.zoneRecord) {
            console.error('no record.. somehow');
            return (<div>no record data!</div>);
        }
        const zoneRecord = inspectData.zoneRecord;

        const className = (inspectData.__inactive)?classes.zoneV2CardInactive:classes.zoneV2CardActive;

        const statusTxt = (status)? " STATUS:"+status:null;


        return (
            <div>
                <Card className={className}>
                    <CardContent>
                        {zoneRecord.name}: {statusTxt}
                    </CardContent>
                    {/*<div>start:{zoneRecord.start} stop:{zoneRecord.stop}</div>*/}
                    {/*<div>min:{zoneRecord.min} max:{zoneRecord.max}</div>*/}
                    <div>min:{inspectData.__min} max:{inspectData.__max}</div>

                </Card>
                {/*<EmergencyPanel/>*/}
                {(aipsV1.length > 0)
                    ?
                    <CardContent>
                        <div>aipsV1:</div>
                        {aipsV1}
                    </CardContent>
                    :null
                }
                {(aupsV1.length > 0)
                    ?
                    <CardContent>
                        <div>aupsV1:</div>
                        {aupsV1}
                    </CardContent>
                    : null
                }
                {(aixmTimeslices.length > 0)
                    ?
                    <CardContent>
                        <div>aixmTimeslices:</div>
                        <DirtyTable rows={aixmTimeslices}/>
                    </CardContent>
                    :null
                }
                {(webcatActivations.length > 0)
                    ?
                    <CardContent>
                        <div>webcatActivations:</div>
                        <DirtyTable rows={webcatActivations}/>
                    </CardContent>
                    :null
                }
                {(notamActivations.length > 0)
                    ?
                    <CardContent>
                        <div>notamActivations:</div>
                        <DirtyTable rows={notamActivations}/>
                    </CardContent>
                    :null
                }
            </div>
        )
    }
}

export default withStyles(styles)(ZoneV2View);
