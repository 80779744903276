import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Card from '@material-ui/core/Card';
import Avatar from '@material-ui/core/Avatar';
import { withStyles } from '@material-ui/core/styles';
import LockIcon from '@material-ui/icons/Lock';

import {Notification, defaultTheme, LoginForm as DefaultLoginForm} from 'react-admin';
import {connect} from "react-redux";
import compose from 'recompose/compose';
import CardActions from '@material-ui/core/CardActions';
import MyNotification from './MyNotification';

const styles = theme => ({
    main: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        height: '1px',
        alignItems: 'center',
        justifyContent: 'flex-start',
        //background: 'url(https://source.unsplash.com/random/1600x900)',
        background: 'white',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
    },
    card: {
        minWidth: 300,
        marginTop: '6em',
    },
    avatar: {
        margin: '1em',
        display: 'flex',
        justifyContent: 'center',
    },
    icon: {
        backgroundColor: theme.palette.secondary[500],
    },
});

const sanitizeRestProps = ({
                               classes,
                               className,
                               location,
                               title,
                               array,
                               theme,
                               staticContext,
                               ...rest
                           }) => rest;


//cp/paste from react admin src 2.4.2, with added dev data
/**
 * A standalone login page, to serve as authentication gate to the admin
 *
 * Expects the user to enter a login and a password, which will be checked
 * by the `authProvider` using the AUTH_LOGIN verb. Redirects to the root page
 * (/) upon success, otherwise displays an authentication error message.
 *
 * Copy and adapt this component to implement your own login logic
 * (e.g. to authenticate via email or facebook or anything else).
 *
 * @example
 *     import MyLoginPage from './MyLoginPage';
 *     const App = () => (
 *         <Admin loginPage={MyLoginPage} authProvider={authProvider}>
 *             ...
 *        </Admin>
 *     );
 */
const Login = ({ classes, className, loginForm,
                   extras, mySelf, dispatch, ...rest }) => {
    console.log('login page my extras, mySelf', {extras, mySelf});
    const {appMode, builtOn, devBuild} = extras;
    const devInfo = `rt-map ${builtOn}`;

    return (
        <div
            className={classnames(classes.main, className)}
            {...sanitizeRestProps(rest)}
        >
            <Card className={classes.card}>
                <div className={classes.avatar}>
                    <Avatar className={classes.icon}>
                        <LockIcon/>
                    </Avatar>
                </div>
                {devBuild
                    ? <CardActions>
                        <pre>{devInfo}</pre>
                    </CardActions>
                    : null
                }
                {loginForm}
            </Card>
            <MyNotification/>
        </div>
    )
};


Login.propTypes = {
    className: PropTypes.string,
    authProvider: PropTypes.func,
    classes: PropTypes.object,
    input: PropTypes.object,
    meta: PropTypes.object,
    previousRoute: PropTypes.string,
    loginForm: PropTypes.element,
};

Login.defaultProps = {
    theme: defaultTheme,
    loginForm: <DefaultLoginForm />,
};


const mapStateToProps = state => {
    //lastDroneModels = state.admin.resources.droneModels;
    console.log('login mapStateToProps', state);
    return {
        extras: state.myAppExtras,
        mySelf: state.mySelf,
    }
};

//export default withStyles(styles)(Login);

export default compose(
    connect(mapStateToProps, null),
    withStyles(styles),
)(Login);
